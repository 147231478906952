import React, { useState } from "react";
import {
  JobDetailsTable,
  JobDetailsTableHeader,
  StatusSquare,
  TaskNameCell,
  TaskOccurrenceCell,
} from "../../styles/Yedaflow.Styled";
import moment from "moment";

const JobRunsTable = ({ runs, handleTaskClick, job }) => {
  // Every element in the map represents a row of the table.
  // The first element of every row is the name of the task.
  // The second element of every row is an array containing
  // all the runs of the specific task.
  const taskMap = new Map();
  const theadData = runs.length ? runs.map((run) => run.execution_date) : [];

  if (runs.length) {
    const emptyColumns = runs[0].task.reduce((acc, cur) => {
      acc[cur.task_name] = true;
      return acc;
    }, {}); // Getting the task name of all tasks in the first run
    runs.forEach((execution, column) => {
      execution.task.forEach((task) => {
        if (!taskMap.has(task.task_name)) {
          taskMap.set(task.task_name, Array(column).fill(null)); // We do this to deal with tasks
          emptyColumns[task.task_name] = true; // added after the first run
        }
        taskMap
          .get(task.task_name)
          .push({ ...task, jobRunId: execution.execution_id });
        emptyColumns[task.task_name] = false; // If the task is present, it means the column won't be empty
      });

      // If the task was removed after the first run we deal with the case here
      // adding a null value to the array containing all the runs of the task
      Object.keys(emptyColumns).forEach((task) => {
        if (emptyColumns[task]) {
          taskMap.get(task).push(null);
        }
        emptyColumns[task] = true;
      });
    });
  } else {
    job.tasks.forEach((task) => taskMap.set(task.task_name, []));
  }

  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const handleHover = (rowIndex, columnIndex) => {
    setHoveredRow(rowIndex);
    setHoveredColumn(columnIndex);
  };
  return (
    <div
      style={{
        overflow: "auto",
      }}
    >
      <JobDetailsTable>
        <colgroup>
          <col width={200} />
        </colgroup>
        <thead>
          <tr>
            <th
              style={{
                border: "0 solid black",
                position: "sticky",
                left: 0,
                zIndex: 2,
                backgroundColor: "#fcfcff",
                height: 120,
                borderBottom: "1px solid #ddd",
              }}
            >
              <p style={{ width: "200px" }}> Task Name</p>
            </th>
            {theadData.map((value, columnIndex) => (
              <JobDetailsTableHeader
                key={value}
                hoveredColumn={hoveredColumn}
                columnIndex={columnIndex}
                onMouseEnter={() => setHoveredColumn(columnIndex)}
                onMouseLeave={() => setHoveredColumn(null)}
              >
                <p
                  style={{
                    whiteSpace: "nowrap",
                    margin: "0",
                    transform: "rotate(-60deg)",
                    textAlign: "center",
                  }}
                >
                  {moment(value).format("MMM D hh:mm")}
                </p>
              </JobDetailsTableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...taskMap.entries()].map(
            ([taskName, taskOccurrences], rowIndex) => (
              <tr key={rowIndex} style={{ height: "100%" }}>
                <TaskNameCell
                  style={{
                    backgroundColor:
                      hoveredRow === rowIndex ? "lightblue" : "#fcfcff",
                  }}
                  onMouseEnter={() => handleHover(rowIndex, null)}
                  onMouseLeave={() => handleHover(null, null)}
                >
                  {taskName}
                </TaskNameCell>
                {taskOccurrences.map((task, columnIndex) => (
                  <TaskOccurrenceCell
                    key={columnIndex}
                    style={{
                      backgroundColor:
                        hoveredRow === rowIndex || hoveredColumn === columnIndex
                          ? "lightblue"
                          : "#fcfcff",
                    }}
                    onMouseEnter={() => handleHover(rowIndex, columnIndex)}
                    onMouseLeave={() => handleHover(null, null)}
                    onClick={() => task && handleTaskClick(task)}
                  >
                    {
                      <StatusSquare
                        style={{
                          backgroundColor: "pink",
                          ...(!!task && {
                            backgroundColor:
                              task.task_status === "Succeeded" ||
                              task.task_status === "OK"
                                ? "#377E22"
                                : task.task_status === "Running"
                                ? "#75FB4C"
                                : task.task_status === "Pending"
                                ? "grey"
                                : task.task_status === "Failed"
                                ? "red"
                                : task.task_status === null
                                ? "#ED72B2"
                                : "#ED72B2",
                          }),
                        }}
                      />
                    }
                  </TaskOccurrenceCell>
                ))}
              </tr>
            ),
          )}
        </tbody>
      </JobDetailsTable>
    </div>
  );
};

export default JobRunsTable;
