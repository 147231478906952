import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Box from "./Box";
import Button from "./Button";

const SelectModuleManagement = (props) => {
  const [checkedModules, setCheckedModules] = useState([]);
  const [modules, setModules] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checkedModules.indexOf(value);
    const newChecked = [...checkedModules];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedModules(newChecked);
  };

  useEffect(() => {
    let modulesArray = [];
    props.data.map((e) => {
      modulesArray.push(e.replace("_", ""));
      return modulesArray;
    });
    setModules(modulesArray);
  }, [props.data]);

  useEffect(() => {
    setCheckedModules(props.customer?.modules);
  }, [props.customer]);

  const handlePostLacas = () => {
    // eslint-disable-next-line no-unused-vars
    const payload = {
      name: props.userEmail,
      account: props.selectedAccount,
    };
  };

  return (
    <Box fullWidth fullHeight>
      <Grid container>
        <h3>Module Management</h3>
        {props.isLoading && props.isLoadingCustomer ? (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px",
            }}
          >
            <ReactLoading type={"spin"} color={"#222"} height={20} width={30} />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <List style={{ padding: 0 }}>
                {modules.map((value) => {
                  return (
                    <ListItem key={value} disablePadding style={{ padding: 0 }}>
                      <ListItemButton onClick={handleToggle(value)} dense>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedModules?.indexOf(value) !== -1}
                          />
                        </ListItemIcon>
                        <ListItemText id={value} primary={value} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{ margin: 0 }}
                text="Deploy"
                className="next_btn save_btn"
                onClick={handlePostLacas}
                disabled={!props.selectedAccount}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default SelectModuleManagement;
