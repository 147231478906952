import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import Button from "../../reusableComponents/Button";
import { StyledDashboardContentFlex } from "../../styles/Login.Styled";

const UserGroupDeleteForm = (props) => {
  const [userToRemove, setUserToRemove] = useState(null);

  const handleSave = () => {
    const user = props.userGroupData.find(
      (e) => e.id === parseInt(userToRemove),
    );
    let group = props.userGroupData.find((e) => e.id === user.parentGroupId);
    let payload = props.userGroupData.filter(
      (e) => e.id !== parseInt(userToRemove) && e.id !== group?.id,
    );

    if (group) {
      group.children = group.children.filter(
        (c) => c.id !== parseInt(userToRemove),
      );
      payload.push(group);
    }
  };

  return (
    <Grid item>
      <Dialog open={true}>
        <DialogTitle>
          <StyledDashboardContentFlex justifyContent>
            <h3>Remove user</h3>
            <AiOutlineClose
              onClick={() => props.setOpenDeleteModal(false)}
              style={{ float: "right", cursor: "pointer" }}
            />
          </StyledDashboardContentFlex>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <select
              value={userToRemove}
              onChange={(e) => setUserToRemove(e.target.value)}
              className={"selectClass"}
              style={{ width: "100%" }}
            >
              {[
                <option key="default" value="">
                  ---select
                </option>,
                ...props.users?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                )),
              ]}
            </select>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type={"submit"}
            text="Save"
            className={"next_btn"}
            onClick={handleSave}
            disabled={!userToRemove}
          />
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UserGroupDeleteForm;
