import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { StyledDashboardContentFlex } from "../styles/Login.Styled";
import Button from "./Button";

const ConfirmationModal = (props) => {
  return (
    <Grid item>
      <Dialog open={true}>
        <DialogTitle>
          <StyledDashboardContentFlex justifyContent>
            <h3>Warning</h3>
            <AiOutlineClose
              onClick={() => props.setOpenModal(false)}
              style={{ float: "right", cursor: "pointer" }}
            />
          </StyledDashboardContentFlex>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            {props.message}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type={"submit"}
            text="Save"
            className={"next_btn"}
            onClick={props.handleClick}
          />
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ConfirmationModal;
