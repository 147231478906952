import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import Button from "../../reusableComponents/Button";
import { StyledGridLabel } from "../../styles/Styled";
import { StyledDashboardContentFlex } from "../../styles/Login.Styled";

const UserGroupForm = (props) => {
  const [dataForm, setDataForm] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  const [passwordType, setPasswordType] = useState("setPwd");
  const [showPwd, setShowPwd] = useState(false);

  const handleSave = () => {
    if (passwordType !== "setPwd") {
      setDataForm({ ...dataForm, password: "" });
    }
  };

  return (
    <Grid item>
      <Dialog open={true}>
        <DialogTitle>
          <StyledDashboardContentFlex justifyContent>
            <h3>Add new user</h3>
            <AiOutlineClose
              onClick={() => props.setOpenModal(false)}
              style={{ float: "right", cursor: "pointer" }}
            />
          </StyledDashboardContentFlex>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <StyledDashboardContentFlex column>
                <StyledGridLabel>
                  User name
                  <input
                    id="name"
                    type={"text"}
                    value={dataForm.name}
                    onChange={(e) =>
                      setDataForm({ ...dataForm, name: e.target.value })
                    }
                    className={"selectClass margin"}
                  />
                </StyledGridLabel>
                <StyledGridLabel>
                  Email address
                  <input
                    id="email"
                    type={"text"}
                    value={dataForm.email}
                    onChange={(e) =>
                      setDataForm({ ...dataForm, email: e.target.value })
                    }
                    className={"selectClass margin"}
                  />
                </StyledGridLabel>
                <StyledGridLabel>
                  Phone number
                  <input
                    id="phone"
                    type={"text"}
                    value={dataForm.phone}
                    onChange={(e) =>
                      setDataForm({ ...dataForm, phone: e.target.value })
                    }
                    className={"selectClass margin"}
                  />
                </StyledGridLabel>
              </StyledDashboardContentFlex>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <StyledGridLabel>Temporary Password</StyledGridLabel>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    defaultValue="setPwd"
                    value={passwordType}
                    onChange={(e) => setPasswordType(e.target.value)}
                  >
                    <FormControlLabel
                      value="setPwd"
                      control={<Radio size="small" color="default" />}
                      label="Set a password"
                    />
                    <FormControlLabel
                      value="generatePwd"
                      control={<Radio size="small" color="default" />}
                      label="Generate a password"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  {passwordType === "setPwd" && (
                    <input
                      id="password"
                      type={showPwd ? "text" : "password"}
                      value={dataForm.password}
                      onChange={(e) =>
                        setDataForm({ ...dataForm, password: e.target.value })
                      }
                      className={"selectClass"}
                      autocomplete="new-password"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={passwordType !== "setPwd"}
                        checked={showPwd}
                        onChange={(e) => setShowPwd(e.target.checked)}
                      />
                    }
                    label="Show password"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type={"submit"}
            text="Save"
            className={"next_btn"}
            onClick={handleSave}
            disabled={
              !dataForm.name ||
              !dataForm.name ||
              !dataForm.phone ||
              !dataForm.password
            }
          />
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UserGroupForm;
