import React, { useState } from "react";
import CatalogPresenter from "./CatalogPresenter";

const CatalogContainer = () => {
  const [databaseSearchValue, setDatabaseSearchValue] = useState("");
  const [databaseSelected, setDatabaseSelected] = useState(null);

  const handleDatabaseSearch = (event) => {
    setDatabaseSearchValue(event.target.value);
  };

  const handleSortByDatabase = (value) => {
    setDatabaseSelected(value);
  };

  return (
    <CatalogPresenter
      databaseSearchValue={databaseSearchValue}
      handleDatabaseSearch={handleDatabaseSearch}
      databaseSelected={databaseSelected}
      handleSortByDatabase={handleSortByDatabase}
    />
  );
};

export default CatalogContainer;
