import React from "react";
import { StyledModal, Centered, StyledDashboardContentFlex } from "./index";

const ExpiredSessionModal = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <StyledModal style={{ zIndex: 100 }}>
      <Centered style={{ width: "20%" }}>
        <StyledDashboardContentFlex column>
          <p className="user_name">User session expired</p>
          <p>You have been logged out</p>
          <div>
            <button onClick={onClose} className={"next_btn save_btn"}>
              Log in
            </button>
          </div>
        </StyledDashboardContentFlex>
      </Centered>
    </StyledModal>
  );
};

export default ExpiredSessionModal;
