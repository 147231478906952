/* eslint-disable no-unused-vars */
import { useState } from "react";

import { axiosGetTable } from "../../../../clientRequest/axiosRequest";
import { fetchDatabaseName } from "../../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";

const useSQL = (token, taskData, setTaskData) => {
  const dispatch = useDispatch();
  const { environmentVariables } = useSelector((state) => state.users);
  const baseURL = `${environmentVariables.REACT_APP_TABLE_API}/yeda/database-metadata/mssql`;
  const [selectedOption, setSelectedOption] = useState("");
  const [queries, setQueries] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState([false]);
  const group = localStorage.getItem("group");
  const [dbParams, setDBParamsValue] = useState([
    {
      host: "",
      port: "",
      user: "",
      password: "",
      id: 1,
    },
  ]);
  const dbDataValues = [
    {
      id: 1,
      name: "host",
      title: "Host",
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 2,
      name: "port",
      title: "Port",
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 3,
      name: "user",
      title: "User",
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 4,
      name: "password",
      title: "Password",
      CheckBox: false,
      password: true,
      defaultValue: "",
      required: true,
    },
  ];

  const optionsArray = [
    {
      id: 1,
      options: "",
      title: "Select",
    },
    {
      id: 2,
      options: "query",
      title: "Query",
    },
    {
      id: 3,
      options: "tables",
      title: "Table(s)",
    },
  ];
  const handleSQLServerValuesChange = (event, id) => {
    const { name, value } = event.target;
    dbParams.map((data) => {
      if (data.id === id) {
        switch (name) {
          case "host":
            data.host = value;
            break;
          case "port":
            data.port = value;
            break;
          case "user":
            data.user = value;
            break;
          case "password":
            data.password = value;
            break;
          default:
            console.log("error");
        }
      }
      return data;
    });
    const currentSQLServerInput = dbParams.find((input) => input.id === id);
    taskData.map((data) => {
      if (data.id === id) {
        data.params.input_db_host = currentSQLServerInput.host;
        data.params.input_db_port = currentSQLServerInput.port;
        data.params.input_db_user = currentSQLServerInput.user;
        data.params.input_db_pass = currentSQLServerInput.password;
        data.params.destination_bucket = `databoat-raw-${group}`;
        data.source_type = "sqlserver";
      }
      return data;
    });
  };
  const handlequeryDataBaseName = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        data.params.query_db_name = selected;
      }
      return data;
    });
    setTaskData(updatedData);
  };
  const [sqlError, setSQLError] = useState([]);
  const handleSelectChange = (event, taskId) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.selected = selected;
      }
      return data;
    });

    setTaskData(updatedData);
    setSelectedOption(selected);

    const currentDbParam = dbParams.find((data) => data.id === taskId);
    const validateForm = () => {
      const errors = [];
      if (!currentDbParam.host.trim()) {
        errors.host = "Host is required";
      }
      if (!currentDbParam.user.trim()) {
        errors.user = "User is required";
      }
      if (!currentDbParam.password.trim()) {
        errors.password = "Password is required";
      }

      setSQLError(errors);
      return Object.keys(errors).length === 0;
    };
    if (validateForm()) {
      setSQLError("");
      if (selected === "tables") {
        const formData = {
          user: currentDbParam.user,
          password: currentDbParam.password,
          server: currentDbParam.host,
        };
        dispatch(fetchDatabaseName(formData, baseURL, token));
      }
    } else {
      setSQLError("Please fill all required fields");
    }
  };

  const [sqlServerdataQueries, setSQLServerDataQueries] = useState(() => [
    {
      id: 1,
      query: null,
      table_name: null,
      partitioning: false,
      partition_columns: null,
    },
  ]);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  // Function to handle adding more rows
  const addMoreSQLServerQueries = (id) => {
    setSQLServerDataQueries((previousQueryData) => {
      return [
        ...previousQueryData,
        {
          id: previousQueryData.length + 1,
          query: null,
          table_name: null,
          partitioning: false,
          partition_columns: null,
        },
      ];
    });
  };
  const handleQueriesTableNameChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = sqlServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          table_name: selected,
        };
      }
      return data;
    });

    setSQLServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          item.table_name = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handleQueriesChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = sqlServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          query: selected,
        };
      }
      return data;
    });

    setSQLServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          item.query = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handleCheckboxChange = (event, id, taskId) => {
    let selected = event.target.value;
    if (selected === "on") {
      selected = true;
    } else {
      selected = false;
    }
    const updatedData = sqlServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          partitioning: selected,
        };
      }
      return data;
    });
    setSQLServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          item.partitioning = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handleQueryPartitionChange = (event, id, taskId) => {
    const selected = event.target.value.split(",");
    const updatedQuery = sqlServerdataQueries.map((data) => {
      if (data.id === id) {
        data.partition_columns = selected;
      }
      return data;
    });
    setSQLServerDataQueries(updatedQuery);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          item.partition_columns = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  //-----------------------------------------SQLSERVER TABLES
  const [isChecked, setIsChecked] = useState();
  const [loadType, setLoadType] = useState("");
  const database = useSelector((state) => state.users.databaseName);
  const databaseNameLoader = useSelector(
    (state) => state.users.databaseNameLoader,
  );
  let database_name = [];
  const tableHead = [
    { id: 1, title: "Database Name" },
    { id: 2, title: "Schema" },
    { id: 3, title: "Table" },
    { id: 4, title: "Ingestion Mode" },
    loadType === "delta" && { id: 5, title: "Delta_Column" },
    { id: 6, title: "Partition" },
    isChecked && { id: 7, title: "Partition_Column" },
  ].filter(Boolean);
  const [tableData, setTableData] = useState(() => [
    {
      id: 1,
      input_db_name: null,
      input_db_schema: null,
      input_table_name: null,
      load_type: null,
      partitioning: false,
      isDelta: false,
      delta_column: null,
      partition_columns: null,
      tableSchemasLoader: false,
      listTablesLoader: false,
      deltaColumnDataLoader: false,
      partitionColumnDataLoader: false,
    },
  ]);

  // Function to handle adding more rows
  const addRow = () => {
    setTableData((previousTableData) => {
      return [
        ...previousTableData,
        {
          id: previousTableData.length + 1,
          input_db_name: null,
          input_db_schema: null,
          input_table_name: null,
          load_type: null,
          partitioning: false,
          isDelta: false,
          delta_column: null,
          partition_columns: null,
          tableSchemasLoader: false,
          listTablesLoader: false,
          deltaColumnDataLoader: false,
          partitionColumnDataLoader: false,
        },
      ];
    });
    setSchemas((previousSchemaData) => {
      return [
        ...previousSchemaData,
        {
          id: previousSchemaData.length + 1,
          schema_names: [],
        },
      ];
    });
    setTables((previousTableNameData) => {
      return [
        ...previousTableNameData,
        {
          id: previousTableNameData.length + 1,
          table_names: [],
        },
      ];
    });
    setDeltaColumn((previousdeltaData) => {
      return [
        ...previousdeltaData,
        {
          id: previousdeltaData.length + 1,
          COLUMN_NAMES: [],
        },
      ];
    });
    setPartitionColumn((previousPartitioneData) => {
      return [
        ...previousPartitioneData,
        {
          id: previousPartitioneData.length + 1,
          COLUMN_NAMES: [],
        },
      ];
    });
  };
  const tableOptions = [
    { id: 1, option: "", title: "Select" },
    { id: 2, option: "full", title: "Full" },
    { id: 3, option: "delta", title: "Delta" },
  ];
  const [schemas, setSchemas] = useState(() => [
    {
      id: 1,
      schema_names: [],
    },
  ]);
  const [tables, setTables] = useState(() => [
    {
      id: 1,
      table_names: [],
    },
  ]);
  const [deltaColumn, setDeltaColumn] = useState(() => [
    {
      id: 1,
      COLUMN_NAMES: [],
    },
  ]);
  const [partitionColumn, setPartitionColumn] = useState(() => [
    {
      id: 1,
      COLUMN_NAMES: [],
    },
  ]);

  if (database.length > 0 && database[0].length > 0) {
    const data = database[0].map((item) => {
      return item;
    });
    database_name = data;
  }
  const databaseName = [
    { value: "", label: "Select" },
    ...database_name.map((item) => {
      return {
        value: item.database_name,
        label: item.database_name,
      };
    }),
  ];

  const handleDatabaseNameChange = async (event, id, taskId) => {
    const selected = event.target.value;
    if (selected) {
      const currentDbParam = dbParams.find((data) => data.id === taskId);
      const formData = {
        user: currentDbParam.user,
        password: currentDbParam.password,
        server: currentDbParam.host,
        database: selected,
      };
      await fetchSchemas(selected, id, formData);
    }
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.input_db_name = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handleSchemaNameChange = async (event, id, taskId) => {
    const selected = event.target.value;
    if (selected) {
      const currentDbParam = dbParams.find((data) => data.id === taskId);
      await fetchTableName(
        selected,
        id,
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
      );
    }
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.input_db_schema = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };

  const handleTableNameChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = tableData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          input_table_name: selected,
        };
      }
      return data;
    });
    setTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.input_table_name = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handleTableIngestModeOption = async (event, id, taskId) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      const currentDbParam = dbParams.find((data) => data.id === taskId);
      await fetchDeltaColumn(
        selectedValue,
        id,
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
      );
    }
    setLoadType(selectedValue);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.load_type = selectedValue;
          item.isDelta = selectedValue === "delta";
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handleDeltaColumnChange = (event, id, taskId) => {
    const updatedData = tableData.map((data) => {
      if (data.id === id) {
        return { ...data, delta_column: event.target.value };
      }
      return data;
    });
    setTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.delta_column = event.target.value;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handlePartionChange = async (event, id, taskId) => {
    let selected = event.target.value;
    if (selected === "on") {
      selected = true;
    }
    if (selected) {
      const currentDbParam = dbParams.find((data) => data.id === taskId);
      await fetchPartitionColumn(
        selected,
        id,
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
      );
    }
    setIsChecked(selected);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.partitioning = selected;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };

  const handlePartitioningChange = (options, id, taskId) => {
    const partion_column = options.map((value) => value.value);
    const updatedData = tableData.map((data) => {
      if (data.id === id) {
        return { ...data, partition_columns: partion_column };
      }
      return data;
    });
    setTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.partition_columns = partion_column;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };

  const fetchSchemas = async (name, id, formData) => {
    const response = await axiosGetTable(baseURL).post(
      "/list-schemas",
      formData,
      { headers },
    );

    const updatedData = tableData.map((data) => {
      if (data.id === id)
        return { ...data, input_db_name: name, tableSchemasLoader: true };
      return data;
    });
    setTableData(updatedData);

    const updatedSchema = schemas.map((schemas) => {
      if (schemas.id === id) return { ...schemas, schema_names: response.data };
      return schemas;
    });
    setSchemas(updatedSchema);
  };
  const fetchTableName = async (
    selectedSchemaName,
    id,
    user,
    password,
    host,
  ) => {
    let selectedDbName = "";

    tableData.map((data) => {
      if (data.id === id) {
        selectedDbName = data.input_db_name;
      }
      return data;
    });
    const formData = {
      user: user,
      password: password,
      server: host,
      database: selectedDbName,
      schema: selectedSchemaName,
    };
    const response = await axiosGetTable(baseURL).post(
      "/list-tables",
      formData,
      { headers },
    );
    const updatedData = tableData.map((data) => {
      if (data.id === id)
        return {
          ...data,
          input_db_schema: selectedSchemaName,
          listTablesLoader: true,
        };
      return data;
    });

    setTableData(updatedData);
    const updatedTable = tables.map((tables) => {
      if (tables.id === id) return { ...tables, table_names: response.data };
      return tables;
    });

    setTables(updatedTable);
  };
  const fetchDeltaColumn = async (selectedValue, id, user, password, host) => {
    let selectedDbName = "";
    let selectedSchemaName = "";
    let selectedTableName = "";

    tableData.map((data) => {
      if (data.id === id) {
        selectedDbName = data.input_db_name;
        selectedSchemaName = data.input_db_schema;
        selectedTableName = data.input_table_name;
      }
      return data;
    });
    const formData = {
      user: user,
      password: password,
      server: host,
      database: selectedDbName,
      schema: selectedSchemaName,
      table: selectedTableName,
    };
    const response = await axiosGetTable(baseURL).post(
      "/list-columns-date",
      formData,
      { headers },
    );
    const updatedData = tableData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          load_type: selectedValue,
          isDelta: selectedValue === "delta",
          deltaColumnDataLoader: true,
        };
      }
      return data;
    });
    setTableData(updatedData);
    const updatedDeltaColumn = deltaColumn.map((columns) => {
      if (columns.id === id) return { ...columns, COLUMN_NAMES: response.data };
      return columns;
    });
    setDeltaColumn(updatedDeltaColumn);
  };
  const fetchPartitionColumn = async (selected, id, user, password, host) => {
    let selectedDbName = "";
    let selectedSchemaName = "";
    let selectedTableName = "";

    tableData.map((data) => {
      if (data.id === id) {
        selectedDbName = data.input_db_name;
        selectedSchemaName = data.input_db_schema;
        selectedTableName = data.input_table_name;
      }
      return data;
    });
    const formData = {
      user: user,
      password: password,
      server: host,
      database: selectedDbName,
      schema: selectedSchemaName,
      table: selectedTableName,
    };
    const response = await axiosGetTable(baseURL).post(
      "/list-columns",
      formData,
      { headers },
    );
    const updatedData = tableData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          partitioning: selected,
          partitionColumnDataLoader: true,
        };
      }
      return data;
    });
    setTableData(updatedData);

    const updatedPartitonColumn = partitionColumn.map((columns) => {
      if (columns.id === id) return { ...columns, COLUMN_NAMES: response.data };
      return columns;
    });
    setPartitionColumn(updatedPartitonColumn);
  };

  const [partitonColValue, setpartitonColValue] = useState("");
  //  ------------------------ends
  return [
    dbDataValues,
    setDBParamsValue,
    handleSQLServerValuesChange,
    sqlError,
    handlequeryDataBaseName,
    selectedOption,
    handleSelectChange,
    optionsArray,
    sqlServerdataQueries,
    handleQueriesTableNameChange,
    queries,
    handleQueriesChange,
    checkboxValues,
    handleCheckboxChange,
    partitonColValue,
    handleQueryPartitionChange,
    addMoreSQLServerQueries,
    tableHead,
    tableData,
    handleDatabaseNameChange,
    databaseNameLoader,
    databaseName,
    handleSchemaNameChange,
    schemas,
    handleTableNameChange,
    tables,
    handleTableIngestModeOption,
    tableOptions,
    handleDeltaColumnChange,
    deltaColumn,
    handlePartionChange,
    handlePartitioningChange,
    partitionColumn,
    addRow,
  ];
};

export default useSQL;
