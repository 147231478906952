/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import {
  setJWTToken,
  setRefreshToken,
  setSession,
  setUserGroup,
  setUserEmail,
  fetchCustomerData,
} from "../redux/actions/usersActions";
import { axiosClientCustomerApi } from "../../clientRequest/axiosRequest";
import { useGetPoolData } from "../../clientRequest/env-variable";

const useCognitoHook = () => {
  const getPoolData = useGetPoolData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [users, setUsers] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [userAttr, setUserAttr] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loginError, setLoginError] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [passwordUpdateErrorMessage, setPasswordUpdateErrorMessage] =
    useState("");
  const { environmentVariables } = useSelector((state) => state.users);

  const baseURL = environmentVariables.REACT_APP_BASE_URL_API_BACKEND;

  const handleLogin = (event) => {
    event.preventDefault();
    const UserPool = new CognitoUserPool(getPoolData);

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    setUsers(cognitoUser);
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (session) => {
        dispatch(setSession(session));
        // get user email
        const email = session.getIdToken().payload.email.split("@")[1];
        const companyName = email.split(".")[0];
        //get user group
        const groups = session.getIdToken().payload["cognito:groups"];
        const usersEmail = session.getIdToken().payload.email;
        localStorage.setItem("group", groups);
        localStorage.setItem("created", usersEmail);
        dispatch(setUserGroup(groups));
        dispatch(setUserEmail(usersEmail));
        //get jwtToken and expirationTime
        const jwtToken = session.getIdToken().getJwtToken();
        //set data in reducer
        dispatch(setJWTToken(jwtToken));
        dispatch(setRefreshToken(session.getRefreshToken().getToken()));
        dispatch(
          fetchCustomerData(
            jwtToken,
            environmentVariables.REACT_APP_BASE_URL_API_BACKEND,
          ),
        );
        //log user in and redirect
        if (groups) {
          localStorage.setItem("isLoggedIn", "true");
          navigate("/dataManagement");
        } else {
          setLoginError("Login Failed");
        }
      },
      onFailure: (err) => {
        setErrorMessage(err.message);
      },
      newPasswordRequired: (userAttributes) => {
        setIsFirstLogin(true);
        setUserAttr(userAttributes);
      },
    });
  };
  const changePasswordListener = (event) => {
    event.preventDefault();
    users.completeNewPasswordChallenge(
      newPassword,
      {},
      {
        onSuccess: () => {
          setIsFirstLogin(false);
        },
        onFailure: (err) => {
          setPasswordUpdateErrorMessage(err.message);
        },
      },
    );
  };

  const getCustomerNameAndRedirect = async (token, existingUser) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axiosClientCustomerApi(baseURL).get("/customer", {
        headers,
      });
      const customerNames = response.data.map((item) => item.customerName);
      if (customerNames.includes(existingUser)) {
        navigate("/dataManagement");
      } else {
        navigate("/controlPlane");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return [
    email,
    setEmail,
    password,
    setPassword,
    errorMessage,
    loginError,
    newPassword,
    setNewPassword,
    handleLogin,
    changePasswordListener,
    isFirstLogin,
    passwordUpdateErrorMessage,
  ];
};

export default useCognitoHook;
