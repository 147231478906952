import React, { useState } from "react";
import { Flex } from "../index";
import { ReusableFieldSet, FieldSet, SelectFieldSet } from "./FieldSet";

const Frequency = ({
  frequencyOption,
  handleFrequencyOption,
  frequencydata,
  startHourMinutes,
  handleStartHourMinutesChange,
  dailyTime,
  handleDailyTimeChange,
  selectedDays,
  handleDayToggle,
  startWeeklyTime,
  handleWeeklyStartTimeChange,
}) => {
  const daysOfTheWeek = [
    { id: 1, title: "Monday", value: "Mon" },
    { id: 2, title: "Tuesday", value: "Tue" },
    { id: 3, title: "Wednesday", value: "Wed" },
    { id: 4, title: "Thursday", value: "Thu" },
    { id: 5, title: "Friday", value: "Fri" },
    { id: 6, title: "Saturday", value: "Sat" },
    { id: 7, title: "Sunday", value: "Sun" },
  ];
  const [isFrequencyFocused, setIsFrequencyFocused] = useState(false);
  const handleFrequencyFocus = () => {
    setIsFrequencyFocused(true);
  };

  const handleFrequencyBlur = () => {
    setIsFrequencyFocused(false);
  };
  const [isStartMinuteFocused, setIsStartMinuteFocused] = useState(false);
  const handleStartMinuteFocus = () => {
    setIsStartMinuteFocused(true);
  };

  const handleStartMinuteBlur = () => {
    setIsStartMinuteFocused(false);
  };
  const [isStartTimeFocused, setIsStartTimeFocused] = useState(false);
  const handleStartTimeFocus = () => {
    setIsStartTimeFocused(true);
  };

  const handleStartTimeBlur = () => {
    setIsStartTimeFocused(false);
  };
  const [isStartWeeklyTimFocused, setIsStartWeeklyTimFocused] = useState(false);
  const handleStartWeeklyTimFocus = () => {
    setIsStartWeeklyTimFocused(true);
  };

  const handleStartWeeklyTimBlur = () => {
    setIsStartWeeklyTimFocused(false);
  };
  const [isSelectDaysFocused, setIsSelectDaysFocused] = useState(false);
  const handleSelectDaysFocus = () => {
    setIsSelectDaysFocused(true);
  };

  const handleSelectDaysBlur = () => {
    setIsSelectDaysFocused(false);
  };
  return (
    <Flex column>
      <SelectFieldSet
        isSelectFocus={isFrequencyFocused}
        selectTitle={"Frequency"}
        onBlur={handleFrequencyBlur}
        onFocus={handleFrequencyFocus}
        selectValue={frequencyOption}
        handleSelectValueChange={handleFrequencyOption}
        selectValuedata={frequencydata}
      />
      {frequencyOption === "Hourly" && (
        <FieldSet
          legendTitle={"Start Minute"}
          name="Start Minute"
          type="number"
          min="0"
          max="59"
          value={startHourMinutes}
          placeholder="Minute can only be in number min:0, max:59"
          onChange={handleStartHourMinutesChange}
          isInputFocused={isStartMinuteFocused}
          onFocus={handleStartMinuteFocus}
          onBlur={handleStartMinuteBlur}
        />
      )}
      {frequencyOption === "Daily" && (
        <FieldSet
          legendTitle={"Start Time"}
          name="Start Time"
          type="time"
          value={dailyTime}
          onChange={handleDailyTimeChange}
          isInputFocused={isStartTimeFocused}
          onFocus={handleStartTimeFocus}
          onBlur={handleStartTimeBlur}
        />
      )}
      {frequencyOption === "Weekly" && (
        <Flex column>
          <ReusableFieldSet
            title={"Pick the days"}
            isFocus={isSelectDaysFocused}
            children={
              <Flex className={"signin_input_focus, paddingClass"}>
                {daysOfTheWeek.map((days) => (
                  <label
                    key={days.id}
                    style={{ display: "flex", gap: "0.5em" }}
                  >
                    <input
                      type="checkbox"
                      checked={
                        selectedDays.includes(days.title) ||
                        selectedDays.includes(days.value)
                      }
                      onChange={() => handleDayToggle(days.title)}
                      onFocus={handleSelectDaysFocus}
                      onBlur={handleSelectDaysBlur}
                      className={"signin_input_focus, paddingClass"}
                    />
                    {days.value}
                  </label>
                ))}
              </Flex>
            }
          />
          <FieldSet
            legendTitle={"Start Time"}
            name="startWeeklyTime"
            type="time"
            value={startWeeklyTime}
            onChange={handleWeeklyStartTimeChange}
            isInputFocused={isStartWeeklyTimFocused}
            onFocus={handleStartWeeklyTimFocus}
            onBlur={handleStartWeeklyTimBlur}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default Frequency;
