import { StyledHeader } from "../../index";

const Header = () => {
  const styles = {
    display: "flex",
    gap: "2.5em",
    flexDirection: "column",
  };
  return (
    <StyledHeader>
      <div style={styles}>
        <h4>Sign In</h4>
        <h2>Welcome to Data Boat</h2>
      </div>
    </StyledHeader>
  );
};

export default Header;
