import React from "react";
import styled from "styled-components";
import Theme from "../../Theme";

export const StyledButton = styled.button`
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
  background-color: ${Theme.colors.blueColor};
  color: ${Theme.colors.whiteColor};
  text-align: center;
  letter-spacing: ${Theme.colors.primaryLetterSpacing};
  font: ${Theme.fonts.fontPrimary};
  width: auto;
  padding: 10px 24px 10px 16px;
  border-radius: 100px;
  border: none;
  &:hover {
    transition: 0.2s;
    box-shadow:
      0px 1px 3px 1px rgba(0, 0, 0, 0.15),
      0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  }
  &:disabled {
    background-color: ${Theme.colors.stateLayersOnSurfaceOpacity012};
    color: ${Theme.colors.sysSurfaceTextColor};
  }
  cursor: pointer;
`;

const ButtonFilledIcon = ({ label, handleOnClick, disabled = false, icon }) => {
  return (
    <StyledButton onClick={handleOnClick} disabled={disabled}>
      {icon}
      {label}
    </StyledButton>
  );
};

export default ButtonFilledIcon;
