import { useState } from "react";
const useS3 = (taskData, setTaskData) => {
  const group = localStorage.getItem("group");
  //s3 starts
  const [inputValue, setInputValue] = useState([
    {
      sourceBucket: "",
      header: false,
      fileFormat: "",
      seperator: "",
      charset: "",
      id: 1,
    },
  ]);
  const S3Data = [
    {
      id: 1,
      name: "fileFormat",
      title: "File Format",
      checkbox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 2,
      name: "header",
      title: "Header",
      checkbox: true,
      defaultValue: "",
      required: true,
    },
    {
      id: 3,
      name: "seperator",
      title: "Seperator",
      checkbox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 4,
      name: "charset",
      title: "Charset",
      checkbox: false,
      defaultValue: "",
      required: false,
    },
  ];
  const handleChange = (event, id) => {
    const { name, value, checked } = event.target;

    inputValue.map((data) => {
      if (data.id === id) {
        switch (name) {
          case "header":
            data.header = checked;
            break;
          case "fileFormat":
            data.fileFormat = value;
            break;
          case "seperator":
            data.seperator = value;
            break;
          case "charset":
            data.charset = value;
            break;
          default:
            console.log("error");
        }
      }
      return data;
    });

    const currentInput = inputValue.find((input) => input.id === id);
    taskData.map((data) => {
      if (data.id === id) {
        data.params.file_format = currentInput.fileFormat;
        data.params.header = currentInput.header.toString();
        data.params.separator = currentInput.seperator;
        data.params.charset = currentInput.charset;
        data.params.destination_bucket = `databoat-raw-${group}/${s3fileValue}`;
        data.source_type = "csv";
      }
      return data;
    });
  };
  const [s3fileName, setS3FileName] = useState("");
  const [s3fileValue, setS3FileValue] = useState("");
  const [s3fileNameError, setS3FileNameError] = useState("");

  const handleS3SourceBucketChange = (e, id) => {
    const s3Value = e.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        data.params.source_bucket = `s3://${s3Value}`;
      }
      return data;
    });
    setS3FileName(s3Value);
    setS3FileNameError("");
    setTaskData(updatedData);
  };
  function isValidGCSFormat(input) {
    const regex = /^[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/;
    return regex.test(input);
  }
  const handleS3SourceBucketBlur = (e, id) => {
    if (s3fileName === "") {
      setS3FileNameError("Source Bucket cannot be empty");
    } else {
      const isValid = isValidGCSFormat(s3fileName);
      if (isValid) {
        const getfileValue = s3fileName.split("/");
        const extractedName = getfileValue[1].replace(/\.[^.]+$/, "");
        setS3FileValue(extractedName);
      } else {
        setS3FileNameError("Required Format: bucket-name/file");
      }
    }
  };
  const handleS3TaskPartitionColumn = (event, taskId) => {
    const selected = event.target.value.split(",");
    const updatedData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.partition_columns = selected;
      }
      return data;
    });
    setTaskData(updatedData);
  };
  //ends here

  return [
    setInputValue,
    S3Data,
    handleChange,
    handleS3SourceBucketChange,
    handleS3SourceBucketBlur,
    s3fileNameError,
    handleS3TaskPartitionColumn,
  ];
};

export default useS3;
