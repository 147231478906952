import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { StyledPortalContent, H3, CustomerLogo } from "../index";
import SelectModuleManagement from "../reusableComponents/SelectModuleManagement";
import CustomerDetails from "./customerDetails/CustomerDetails";
import UserGroupManagement from "./userGroup/UserGroupManagement";
import { useGetPoolData } from "../../clientRequest/env-variable";

const ControlOverview = () => {
  const userGroupData = [
    // mock for treeview
    {
      id: 1,
      name: "Random user One",
      isUser: true,
      parentGroupId: null,
      children: [],
    },
    {
      id: 2,
      name: "User@123",
      isUser: true,
      parentGroupId: null,
      children: [],
    },
    {
      id: 3,
      name: "Test Random",
      isUser: true,
      parentGroupId: 8,
      children: [],
    },
    {
      id: 4,
      name: "DataOps@employee",
      isUser: true,
      parentGroupId: null,
      children: [],
    },
    {
      id: 6,
      name: "BI Analyst",
      isUser: false,
      parentGroupId: null,
      children: [],
    },
    {
      id: 7,
      name: "Data Analyst",
      isUser: false,
      parentGroupId: null,
      children: [],
    },
    {
      id: 8,
      name: "Data Engineer",
      isUser: false,
      parentGroupId: null,
      children: [{ id: 3, name: "Test Random", parentGroupId: 8 }],
    },
  ];

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [usersData, setUsersData] = useState(
    userGroupData.filter((e) => e.isUser),
  );
  const [groupsData, setGroupsData] = useState(
    userGroupData.filter((e) => !e.isUser),
  );
  const [token, setToken] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const getPoolData = useGetPoolData();

  const {
    getBundles,
    bundleLoader,
    getCustomers,
    loading,
    environmentVariables,
  } = useSelector((state) => state.users);

  const userEmail = localStorage.getItem("created");
  const group = localStorage.getItem("group");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCurrentCustomer = () => {
    if (!getCustomers) {
      throw new Error("Error getting customers");
    }
    setCurrentCustomer(getCustomers.find((e) => e.customerName === group));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const checkSessionExpiration = () => {
      const UserPool = new CognitoUserPool(getPoolData);
      const cognitoUser = UserPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            throw err;
          } else {
            if (session.isValid()) {
              setToken(session.getIdToken().jwtToken);
            } else {
              cognitoUser.signOut();
            }
          }
        });
      }
    };

    if (getPoolData.ClientId) {
      checkSessionExpiration();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getPoolData.ClientId]);

  useEffect(() => {
    try {
      getCurrentCustomer();
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, getCustomers, getCurrentCustomer]);

  return (
    <StyledPortalContent>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <H3>Control Plane</H3>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={1} justifyContent={"center"}>
                <CustomerLogo />
              </Grid>
              <Grid item sx={12} md={7}>
                <CustomerDetails
                  isLoading={!loading}
                  data={currentCustomer}
                  token={token}
                  selectedAccount={selectedAccount}
                  setSelectedAccount={setSelectedAccount}
                  environmentVariables={environmentVariables}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectModuleManagement
                  isLoading={!bundleLoader}
                  isLoadingCustomer={!loading}
                  data={getBundles}
                  userEmail={userEmail}
                  customer={currentCustomer}
                  selectedAccount={selectedAccount}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <UserGroupManagement
              usersData={usersData}
              setUsersData={setUsersData}
              groupsData={groupsData}
              setGroupsData={setGroupsData}
              userGroupData={userGroupData}
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledPortalContent>
  );
};

export default ControlOverview;
