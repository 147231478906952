import { DashboardHome, StyledPortalContent, PreLoader } from "../index";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetPoolData } from "../../clientRequest/env-variable";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { fetchJobsAnalytics } from "../redux/actions/usersActions";

const DashboardOverview = () => {
  const [greeting, setGreeting] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const getPoolData = useGetPoolData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { environmentVariables, getJobAnalyticsLoader } = useSelector(
    (state) => state.users,
  );
  const baseURL = environmentVariables.REACT_APP_BASE_URL_API_CORE;
  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();

    let newGreeting = "";

    if (hours >= 5 && hours < 12) {
      newGreeting = "Good morning";
    } else if (hours >= 12 && hours < 17) {
      newGreeting = "Good afternoon";
    } else {
      newGreeting = "Good evening";
    }

    setGreeting(newGreeting);
    const checkSessionExpirationAndGetUser = () => {
      const UserPool = new CognitoUserPool(getPoolData);
      const cognitoUser = UserPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            throw err;
          } else {
            if (session.isValid()) {
              const user = cognitoUser.username;
              const capitalized = user.charAt(0).toUpperCase() + user.slice(1);
              setCurrentUser(capitalized);
              const group = session.getIdToken().payload["cognito:groups"];
              const token = session.getIdToken().jwtToken;
              dispatch(fetchJobsAnalytics(token, group, baseURL));
            } else {
              cognitoUser.signOut();
              navigate("/");
            }
          }
        });
      }
    };

    if (getPoolData.ClientId) {
      checkSessionExpirationAndGetUser();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPoolData.ClientId]);
  return (
    <StyledPortalContent>
      {getJobAnalyticsLoader ? (
        <DashboardHome greeting={greeting} currentUser={currentUser} />
      ) : (
        <PreLoader />
      )}
    </StyledPortalContent>
  );
};

export default DashboardOverview;
