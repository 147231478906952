import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { axiosGetJobs } from "../../../clientRequest/axiosRequest";
import { useGetPoolData } from "../../../clientRequest/env-variable";
import PreLoader from "../../reusableComponents/PreLoader";
import { StyledDashboardContentFlex, Flex } from "../../styles/Login.Styled";
import { StyledModal } from "../../styles/Modal.Styled";
import {
  JobDetailsButton,
  CreateJobButton,
  JobDetailsCheckbox,
  JobDetailsCheckboxContainer,
  JobDetailsCheckmark,
  JobDetailsCustomCheckbox,
  JobDetailsTopHeader,
  JobNameContainer,
  StyledYedaflowContainer,
  TaskModal,
} from "../../styles/Yedaflow.Styled";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { setIsJobEditing } from "../../redux/actions/usersActions";
import Breadcrumbs from "./Breadcrumbs";
import JobRunsTable from "./JobRunsTable";
import axios from "axios";
import { toast } from "react-toastify";

function deepEqual(x, y) {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === "object" && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
}

const JobDetailsOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobName } = useParams();
  const jobNameRef = useRef(jobName);
  const [runs, setRuns] = useState([]);
  const [job, setJob] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [lastRuns, setLastRuns] = useState(null);
  const [isLoadingExecutions, setIsLoadingExecutions] = useState(true);
  const [isLoadingJob, setIsLoadingJob] = useState(true);
  const firstRender = useRef(true);
  const getPoolData = useGetPoolData();
  const { environmentVariables, getCustomers } = useSelector(
    (state) => state.users,
  );
  const [token, setToken] = useState("");
  const checkSessionExpiration = () => {
    const UserPool = new CognitoUserPool(getPoolData);
    const cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          throw err;
        } else {
          if (session.isValid()) {
            const token = session.getIdToken().jwtToken;
            setToken(token);
            getExecution(token);
            if (firstRender.current) {
              getJobs(token);
              firstRender.current = false;
            }
          } else {
            cognitoUser.signOut();
            navigate("/");
          }
        }
      });
    }
  };
  useEffect(() => {
    if (getPoolData.ClientId) {
      checkSessionExpiration();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPoolData.ClientId]);
  const getExecution = async (token) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const executionsResponse = await axiosGetJobs(
      environmentVariables.REACT_APP_BASE_URL_API_CORE,
    ).get(`/yeda/execution?jobName=${jobNameRef.current}`, { headers });
    setRuns(executionsResponse.data);

    setLastRuns(executionsResponse.data);

    setIsLoadingExecutions(false);
  };

  const getJobs = async (token) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const jobsResponse = await axiosGetJobs(
      environmentVariables.REACT_APP_BASE_URL_API_CORE,
    ).get(`/yeda/jobs`, { headers });
    const job = jobsResponse.data.find((job) => job.job_name === jobName);
    setJob(job);
    setIsLoadingJob(false);
  };

  useEffect(() => {
    if (lastRuns && !deepEqual(runs, lastRuns)) {
      setRuns(lastRuns);
      setLastRuns(null);
    }
  }, [lastRuns, runs]);

  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(() => {
        checkSessionExpiration();
      }, 3000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRefresh]);

  function onTaskClick(task) {
    setCurrentTask(task);
    setModalOpen((prev) => !prev);
  }

  async function rerunTask() {
    alert(`POST method to ${jobName}/${currentTask.task_id}`);
    try {
      // await axiosRerunTask.post(`${jobName}/${currentTask.task_id}`);
      if (autoRefresh) {
        return;
      } else {
        window.location.reload(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function triggerJob() {
    try {
      const toastId = toast.loading("Waiting for job to start running", {
        autoClose: true,
        closeButton: true,
      });
      const group = localStorage.getItem("group");
      const currentCustomer = getCustomers.find(
        (e) => e.customerName === group,
      );
      axios
        .post(
          "https://api-core.databoat.nl/yeda/manual-handler",
          {
            cloudCredential: [
              {
                cloudProvider: currentCustomer.cloudCredential[0].cloudProvider,
                ssmPathParam: currentCustomer.cloudCredential[0].ssmPathParam,
                region: currentCustomer.cloudCredential[0].region,
              },
            ],
            job_name: jobName,
            is_new: true,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(() => {
          toast.dismiss(toastId);
          toast.success("Job started running", {
            pauseOnFocusLoss: false,
            closeOnClick: true,
            closeButton: true,
            autoClose: 5000,
          });
        })
        .catch(() => {
          toast.dismiss(toastId);
          toast.error("Unable to run job right now", {
            pauseOnFocusLoss: false,
            closeOnClick: true,
            closeButton: true,
            autoClose: 5000,
          });
        });
    } catch (error) {
      console.log(error);
    }
  }
  const handleEditJob = () => {
    dispatch(setIsJobEditing(true));
    navigate(`/edit-job/${jobName}`);
  };
  return (
    <>
      <StyledYedaflowContainer>
        {isLoadingExecutions || isLoadingJob ? (
          <PreLoader />
        ) : (
          <>
            <JobDetailsTopHeader>
              <Breadcrumbs
                values={[
                  { name: "Jobs", path: "/yedaflow" },
                  { name: jobName, path: null },
                ]}
              ></Breadcrumbs>
              <JobDetailsCheckboxContainer>
                <JobDetailsCheckbox
                  type="checkbox"
                  value={autoRefresh}
                  checked={autoRefresh}
                  onChange={() => setAutoRefresh((prev) => !prev)}
                />
                <JobDetailsCustomCheckbox autoRefresh={autoRefresh}>
                  <JobDetailsCheckmark autoRefresh={autoRefresh} />
                </JobDetailsCustomCheckbox>
                Auto-refresh
              </JobDetailsCheckboxContainer>
            </JobDetailsTopHeader>
            <JobNameContainer>
              <h1>{jobName}</h1>
              <Flex>
                <CreateJobButton
                  onClick={() => triggerJob()}
                  text={"Trigger"}
                />
                <CreateJobButton text={"Edit"} onClick={handleEditJob} />
              </Flex>
            </JobNameContainer>
            <JobRunsTable job={job} runs={runs} handleTaskClick={onTaskClick} />
          </>
        )}
      </StyledYedaflowContainer>
      {modalOpen && (
        <StyledModal style={{ zIndex: 2 }}>
          <TaskModal>
            <StyledDashboardContentFlex column gap>
              <StyledDashboardContentFlex justifyContent>
                <p style={{ fontWeight: "bold", fontSize: "1.5em" }}>
                  {currentTask.task_name}
                </p>
                <MdClose
                  style={{ cursor: "pointer" }}
                  onClick={() => setModalOpen((prev) => !prev)}
                ></MdClose>
              </StyledDashboardContentFlex>
              <StyledDashboardContentFlex column>
                <p>Would you like to rerun this task?</p>
              </StyledDashboardContentFlex>
              <div>
                <JobDetailsButton text={"Rerun"} onClick={() => rerunTask()} />
              </div>
            </StyledDashboardContentFlex>
          </TaskModal>
        </StyledModal>
      )}
    </>
  );
};

export default JobDetailsOverview;
