import React from "react";
import { Table, Thead, TableHeaderCell } from "../../styles/Yedaflow.Styled";

const TableOverview = ({ headers, rows, renderRow, width }) => {
  const lastIndex = headers.length - 1;
  return (
    <Table>
      <Thead>
        <tr>
          {headers.map((header, index) => (
            <TableHeaderCell
              key={index}
              style={{ width: index === lastIndex && width }}
            >
              {header}
            </TableHeaderCell>
          ))}
        </tr>
      </Thead>
      <tbody>
        {rows.map((item, index) => (
          <React.Fragment key={index}>{renderRow(item)}</React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

export default TableOverview;
