export const EditDatabaseData = [
  {
    id: "1",
    ColumnName: "store_id",
    DataType: "char_4",
    Description: "Description 1",
  },
  {
    id: "2",
    ColumnName: "store_id",
    DataType: "char_4",
    Description: "",
  },
  {
    id: "3",
    ColumnName: "store_id",
    DataType: "char_4",
    Description: "Description 3",
  },
  {
    id: "4",
    ColumnName: "store_id",
    DataType: "char_4",
    Description: "",
  },
  {
    id: "5",
    ColumnName: "store_id",
    DataType: "char_4",
    Description: "",
  },
  {
    id: "6",
    ColumnName: "store_id",
    DataType: "char_4",
    Description: "",
  },
];
