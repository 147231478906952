import axios from "axios";

export const axiosClientLacasApi = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return instance;
};

export const axiosGetJobs = (baseURL) => {
  const instance = axios.create({
    baseURL,
  });

  return instance;
};

export const axiosClientAWSRegionApi = (baseURL) => {
  const instance = axios.create({
    baseURL,
  });

  return instance;
};

export const axiosClientBundlesApi = (baseURL) => {
  const instance = axios.create({
    baseURL,
  });

  return instance;
};

export const axiosClientCustomerApi = (baseURL) => {
  const instance = axios.create({
    baseURL,
  });

  return instance;
};

export const axiosGetTable = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return instance;
};
export const axiosTransformData = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return instance;
};

export const axiosExecuteQuery = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return instance;
};

export const axiosGlueMetadata = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return instance;
};

export const axiosCatalogApi = (baseURL, token) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  });

  return instance;
};
