import React, { useState } from "react";
import {
  StyledDashboardContentFlex,
  ModalHeader,
  Button,
  StyledModal,
  Flex,
  StyledConnectorInput,
} from "../../index";
import CloseSvg from "../../reusableComponents/svg/CloseSvg";
import { useGetPoolData } from "../../../clientRequest/env-variable";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import ButtonOutlineIcon from "../../reusableComponents/UIComponents/ButtonOutlineIcon";
import { useDispatch, useSelector } from "react-redux";
import { postTransformationScript } from "../../redux/actions/usersActions";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SaveModal = ({
  scriptName,
  handleScriptNameChange,
  handleClose,
  open,
  SQLTab,
  setSQLTab,
  selectedSQLTab,
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const getPoolData = useGetPoolData();
  const { environmentVariables } = useSelector((state) => state.users);
  const postSQLTabScripts = () => {
    const user = localStorage.getItem("created");
    const formData = {
      scriptName: scriptName,
      script: selectedSQLTab.query,
    };
    if (selectedSQLTab.id === null) {
      setError("Please Input your query");
    } else {
      const userPool = new CognitoUserPool(getPoolData);
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            throw err;
          } else {
            if (session.isValid()) {
              const token = session.getIdToken().jwtToken;
              const groupName = session.getIdToken().payload["cognito:groups"];
              dispatch(
                postTransformationScript(
                  token,
                  environmentVariables.REACT_APP_BASE_URL_API_BACKEND,
                  user,
                  groupName,
                  formData,
                ),
              );
              setSQLTab((prevSQLTab) => {
                const updatedTab = prevSQLTab.map((item) => {
                  if (item.id === selectedSQLTab.id) {
                    return { ...item, name: scriptName, saved: true };
                  }
                  return item;
                });
                return updatedTab;
              });
              handleClose();
            } else {
              cognitoUser.signOut();
              localStorage.clear();
              navigate("/");
            }
          }
        });
      }
    }
  };

  return (
    <StyledModal>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: "28px" } }}
      >
        <ModalHeader>
          <p>Enter your Script Name</p>
          <div onClick={handleClose}>
            <CloseSvg />
          </div>
        </ModalHeader>
        <StyledDashboardContentFlex column style={{ padding: "2em" }}>
          <Flex>
            <StyledConnectorInput
              name="script-name"
              value={scriptName}
              onChange={handleScriptNameChange}
            />
            <p className="error">{error}</p>
          </Flex>
          <Flex style={{ flexDirection: "row-reverse" }}>
            <Button
              onClick={postSQLTabScripts}
              disabled={SQLTab.saved}
              text={"Save"}
              className={"next_btn save_btn"}
            />
            <div onClick={handleClose}>
              <ButtonOutlineIcon label="Cancel" />
            </div>
          </Flex>
        </StyledDashboardContentFlex>
      </Dialog>
    </StyledModal>
  );
};
export default SaveModal;
