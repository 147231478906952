import React, { useEffect } from "react";
import {
  StyledDashboardContentFlex,
  HomeSvg,
  TransformationSvg,
  Tabs,
  StyledPortalContent,
  UploadContent,
  UploadSvg,
} from "../../index";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { useGetPoolData } from "../../../clientRequest/env-variable";
import { fetchCustomerData } from "../../redux/actions/usersActions";

const UploadOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getPoolData = useGetPoolData();
  const { environmentVariables } = useSelector((state) => state.users);
  useEffect(() => {
    const checkSessionExpiration = () => {
      const UserPool = new CognitoUserPool(getPoolData);
      const cognitoUser = UserPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            throw err;
          } else {
            if (session.isValid()) {
              const token = session.getIdToken().jwtToken;
              dispatch(
                fetchCustomerData(
                  token,
                  environmentVariables.REACT_APP_BASE_URL_API_BACKEND,
                ),
              );
            } else {
              cognitoUser.signOut();
              navigate("/");
            }
          }
        });
      }
    };

    if (getPoolData.ClientId) {
      checkSessionExpiration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getPoolData.ClientId]);
  const fill = "#006399";
  const size = "18";
  const tabData = [
    {
      to: "/dataManagement",
      icon: <HomeSvg fill={fill} width={size} height={size} />,
      label: "Home",
    },
    {
      to: "/transformation",
      icon: <TransformationSvg fill={fill} width={size} height={size} />,
      label: "Transformation",
    },
    {
      to: "/upload",
      icon: <UploadSvg fill={fill} width={size} height={size} />,
      label: "Upload",
    },
  ];
  return (
    <StyledPortalContent>
      <div style={{ padding: "1em" }}>
        <StyledDashboardContentFlex column>
          <Tabs tabs={tabData} />
          <UploadContent />
        </StyledDashboardContentFlex>
      </div>
    </StyledPortalContent>
  );
};

export default UploadOverview;
