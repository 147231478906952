import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import databoatLogo from "../../images/databoat.png";
import { StyledPortalHeader, StyledDashboardContentFlex, Flex } from "../index";
import { useGetPoolData } from "../../clientRequest/env-variable";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import Search from "../reusableComponents/Search";

const PortalHeader = () => {
  // eslint-disable-next-line no-unused-vars
  const [notification, setNotification] = useState(0);
  const [currentUser, setCurrentUser] = useState("");
  const getPoolData = useGetPoolData();

  const getCurrentUsername = () => {
    const UserPool = new CognitoUserPool(getPoolData);
    const cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error("Error getting user session:", err);
        } else {
          const userEmail = cognitoUser.storage.created;
          setCurrentUser(userEmail);
        }
      });
    } else {
      // do something
    }
  };
  useEffect(() => {
    if (getPoolData.ClientId) {
      getCurrentUsername();
    }
  });
  return (
    <StyledPortalHeader>
      <StyledDashboardContentFlex justifyContent>
        <Flex>
          <Link to="/dataManagement">
            <img src={databoatLogo} alt="Data boat logo" />
          </Link>
          <Search />
        </Flex>
        <p className="userEmail">{currentUser}</p>
      </StyledDashboardContentFlex>
    </StyledPortalHeader>
  );
};

export default PortalHeader;
