import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import _ from "lodash";
import styled from "styled-components";
import { Checkbox, Grid, List, ListItem } from "@mui/material";
import { IoMdRemoveCircleOutline, IoMdAddCircle } from "react-icons/io";
import awsImg from "../../../images/aws-img.png";
import azureImg from "../../../images/azure-img.png";
import Gcp from "../../../images/Gcp.png";
import AddCustomerForm from "./AddCustomerForm";
import ConfirmationModal from "../../reusableComponents/ConfirmationModal";
import {
  fetchCustomerData,
  patchCustomerData,
} from "../../redux/actions/usersActions";

const Section = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  min-height: 50px;
  width: 70%;
`;

const Content = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  padding: 10px;
`;

const IconButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 3px;
  cursor: pointer;
`;

const ScrollBar = styled.div`
  overflow-y: ${(props) => (props.dataLength > 1 ? "scroll" : "hidden")};
  overflow-x: hidden;
  max-height: 350px;
  margin: 0 10px;
`;

const CustomerDetails = (props) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(null);
  const [removeAccountId, setRemoveAccountId] = useState(false);
  const [callCustomers, setCallCustomers] = useState(false);

  const dispatch = useDispatch();
  const baseURL = props.environmentVariables.REACT_APP_BASE_URL_API_BACKEND;

  const handleSectionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleSaveAccount = (data) => {
    const payload = {
      ...props.data,
      cloudCredential: [...props.data.cloudCredential, data],
    };

    Promise.resolve(
      dispatch(
        patchCustomerData(
          props.token,
          props.data.customerName,
          payload,
          baseURL,
        ),
      ), // check API
    ).then(setCallCustomers(true), setOpenAddModal(false));
  };

  const handleRemoveAccountDetail = (id) => {
    const filteredCloudCredential = props.data.cloudCredential.filter(
      (d) => d.accountId !== id,
    );

    const payload = {
      ...props.data,
      cloudCredential: [...filteredCloudCredential],
    };

    Promise.resolve(
      dispatch(
        patchCustomerData(
          props.token,
          props.data.customerName,
          payload,
          baseURL,
        ),
      ), // check API
    ).then(setCallCustomers(true), setRemoveAccountId(false));
  };

  const handleAddAccountDetail = () => {
    setOpenAddModal(true);
  };

  const renderCloudImg = (account) => {
    if (account?.cloudProvider === "AZURE") {
      return (
        <img
          src={azureImg}
          alt="Azure Cloud Platform"
          style={{ width: "6em" }}
        />
      );
    } else if (account?.cloudProvider === "GCP") {
      return (
        <img src={Gcp} alt="Google Cloud Platform" style={{ width: "6em" }} />
      );
    }
    return (
      <img src={awsImg} alt="Amazon Cloud Service" style={{ width: "6em" }} />
    );
  };

  useEffect(() => {
    if (callCustomers) {
      Promise.resolve(dispatch(fetchCustomerData(props.token, baseURL))).then(
        setCallCustomers(false),
        setRemoveAccountId(false),
      );
    }
  }, [dispatch, callCustomers, props.token, baseURL]);

  return (
    <>
      <ScrollBar dataLength={props.data?.cloudCredential.length}>
        <Grid container spacing={3} marginTop={"1em"}>
          {!_.isNil(props.data) && !props.isLoading && (
            <>
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems={"center"} wrap="nowrap">
                  <Grid item style={{ width: "9.5em" }}></Grid>
                  <Section onClick={() => handleSectionClick("customer")}>
                    <ListItem>
                      <b>{props.data.customerName}</b>
                    </ListItem>
                    <Content open={activeIndex === "customer"}>
                      <List>
                        <ListItem>Plan: {props.data.plan}</ListItem>
                        <ListItem>
                          {props.data.modules.length > 0 &&
                            `Modules: ${props.data.modules.join(", ")}`}
                        </ListItem>
                      </List>
                    </Content>
                  </Section>
                  <Grid item style={{ width: "1.5em" }}></Grid>
                </Grid>
              </Grid>
              {props.data.cloudCredential?.map((account, index) => (
                <Grid item xs={12} key={index}>
                  <Grid
                    container
                    spacing={1}
                    alignItems={"center"}
                    wrap="nowrap"
                  >
                    <Grid item style={{ width: "7em" }}>
                      {renderCloudImg(account)}
                    </Grid>
                    <Grid item>
                      <Checkbox
                        edge="start"
                        checked={
                          props.selectedAccount?.accountId === account.accountId
                        }
                        onChange={(e) => props.setSelectedAccount(account)}
                      />
                    </Grid>
                    <Section key={index}>
                      <ListItem>
                        <b>Account Id: {account.accountId}</b>
                      </ListItem>
                      <Content open={true}>
                        <List>
                          <ListItem>Aws region: {account.awsRegion}</ListItem>
                          <ListItem>Environment: {account.env}</ListItem>
                          <ListItem>Role: {account.roleArn}</ListItem>
                          <ListItem>
                            Cloud Provider: {account.cloudProvider}
                          </ListItem>
                        </List>
                      </Content>
                    </Section>
                    <Grid item style={{ margin: "auto" }}>
                      <IconButton
                        onClick={() => setRemoveAccountId(account.accountId)}
                      >
                        <IoMdRemoveCircleOutline size={"2em"} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          )}
        </Grid>
        {openAddModal && (
          <AddCustomerForm
            setOpenModal={setOpenAddModal}
            handleClick={handleSaveAccount}
          />
        )}
        {removeAccountId && (
          <ConfirmationModal
            setOpenModal={setRemoveAccountId}
            handleClick={handleRemoveAccountDetail}
            message={"Are you sure you want to remove this customer account?"}
          />
        )}
      </ScrollBar>
      {props.isLoading || !props.data ? (
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactLoading type={"spin"} color={"#222"} height={20} width={30} />
        </Grid>
      ) : (
        <Grid item style={{ margin: "0 2em" }}>
          <IconButton onClick={handleAddAccountDetail}>
            <IoMdAddCircle size={"3em"} />
          </IconButton>
        </Grid>
      )}
    </>
  );
};

export default CustomerDetails;
