import React from "react";
import { ConnectorScreens, StyledDashboardContentFlex } from "../../../index";

const MySQLConnector = ({
  inputData,
  mySQLDataValues,
  handlemySQLServerValuesChange,
  mySQLError,
  setQueryDBName,
  ingestions,
  handlemySQLqueryDataBaseName,
  mySQLselectedOption,
  taskId,
  handlemySQLSelectChange,
  mySQLoptionsArray,
  mySQLServerdataQueries,
  handlemySQLQueriesTableNameChange,
  mySQLqueries,
  handlemySQLQueriesChange,
  mySQLcheckboxValues,
  handlemySQLCheckboxChange,
  mySQLpartitonColValue,
  handlemySQLQueryPartitionChange,
  addMoremySQLServerQueries,
  mySQLtableHead,
  mySQLtableData,
  handlemySQLDatabaseNameChange,
  mySQLdatabaseNameLoader,
  mySQLdatabaseName,
  handlemySQLSchemaNameChange,
  mySQLschemas,
  handlemySQLTableNameChange,
  mySQLtables,
  handlemySQLTableIngestModeOption,
  mySQLtableOptions,
  handlemySQLDeltaColumnChange,
  mySQLdeltaColumn,
  handlemySQLPartionChange,
  handlemySQLPartitioningChange,
  mySQLpartitionColumn,
  mySQLaddRow,
  onClose,
}) => {
  return (
    <StyledDashboardContentFlex column>
      <ConnectorScreens
        HeaderText={"Fill attributes required for MYSSQL Server connection"}
        inputData={inputData}
        data={mySQLDataValues}
        handleChange={handlemySQLServerValuesChange}
        errors={mySQLError}
        setQueryDBName={setQueryDBName}
        handlequeryDataBaseName={handlemySQLqueryDataBaseName}
        ingestions={ingestions}
        htmlFor={"Ingestions"}
        selectTitle={"Ingestions"}
        selectedOption={mySQLselectedOption}
        handleSelected={handlemySQLSelectChange}
        options={mySQLoptionsArray}
        dataQueries={mySQLServerdataQueries}
        handleQueriesTableNameChange={handlemySQLQueriesTableNameChange}
        queryValues={mySQLqueries}
        taskId={taskId}
        handleQueriesChange={handlemySQLQueriesChange}
        checkboxValues={mySQLcheckboxValues}
        handleCheckboxChange={handlemySQLCheckboxChange}
        queryPartitionValue={mySQLpartitonColValue}
        handleQueryPartitionChange={handlemySQLQueryPartitionChange}
        handleAddMore={addMoremySQLServerQueries}
        tableHead={mySQLtableHead}
        tableData={mySQLtableData}
        handleDatabaseNameChange={handlemySQLDatabaseNameChange}
        databaseNameLoader={mySQLdatabaseNameLoader}
        databaseName={mySQLdatabaseName}
        handleSchemaNameChange={handlemySQLSchemaNameChange}
        schemas={mySQLschemas}
        handleTableNameChange={handlemySQLTableNameChange}
        tables={mySQLtables}
        handleTableIngestModeOption={handlemySQLTableIngestModeOption}
        tableOptions={mySQLtableOptions}
        handleDeltaColumnChange={handlemySQLDeltaColumnChange}
        deltaColumn={mySQLdeltaColumn}
        handlePartionChange={handlemySQLPartionChange}
        handlePartitioningChange={handlemySQLPartitioningChange}
        partitionColumn={mySQLpartitionColumn}
        addRow={mySQLaddRow}
        btnOnClick={onClose}
        buttonText={"Save"}
      />
    </StyledDashboardContentFlex>
  );
};

export default MySQLConnector;
