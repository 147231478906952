import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useIdleTimer } from "react-idle-timer";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import ExpiredSessionModal from "./components/ExpiredSessionModal";
import {
  Home,
  MainContainer,
  Theme,
  GlobalStyles,
  DpacManagement,
  Notification,
  Catalog,
  Lineage,
  JobsNestedRoute,
  DataClassification,
  SchemaRegistry,
  DataGovernanceNestedRoute,
  Control,
  ObservabilityNestedRoute,
  DataManagementNestedRoute,
  MLOpsFlow,
  NoteBook,
  Application,
  Metrics,
  DataScienceNestedRoute,
  JobDashBoard,
  EditDataDictionaryModal,
  ErrorPage,
  IsNotLoggedIn,
  DataSetDictionaryModal,
  Yedaflow,
  ForgetPassword,
  DataQuality,
  Logs,
  JobDetails,
  Upload,
  Transformation,
} from "./components";

import {
  getAWSSSMParameters,
  setLogin,
} from "./components/redux/actions/usersActions";

import { CognitoUserPool } from "amazon-cognito-identity-js";
import { useGetPoolData } from "./clientRequest/env-variable";
import CreateJobScreen from "./components/routerComponents/jobs/CreateJobScreen";
import EditJobComponent from "./components/routerComponents/jobs/EditJobComponent";
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const background = location.state && location.state.background;
  const timeout = 3600000;
  // eslint-disable-next-line no-unused-vars
  const [remaining, setRemaining] = useState(timeout);
  const [modalOpen, setModalOpen] = useState(false);
  const getPoolData = useGetPoolData();

  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const handleOnIdle = () => {
    // Show the toast notification
    toast.info("You have been logged out.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      pauseOnFocusLoss: false,
    });
    localStorage.clear();
    navigate("/");
  };

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    dispatch(getAWSSSMParameters());
    setRemaining(getRemainingTime());

    setInterval(() => {
      setRemaining(getRemainingTime());
    }, 600000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    setModalOpen(false);
    dispatch(setLogin(false));
    navigate("/");
    localStorage.clear();
  };
  const handleSignOut = () => {
    const UserPool = new CognitoUserPool(getPoolData);
    const cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      localStorage.clear();
      navigate("/");
    }
  };

  return (
    <MuiThemeProvider theme={Theme}>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <ToastContainer />
        <MainContainer>
          {modalOpen && (
            <ExpiredSessionModal isOpen={modalOpen} onClose={closeModal} />
          )}
          <Routes location={background || location}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/resetPassword" element={<ForgetPassword />} />
            {isLoggedIn && (
              <>
                <Route
                  exact
                  path="/dataManagement"
                  element={<DpacManagement />}
                />
                <Route path="/notification" element={<Notification />} />
                <Route path="/control" element={<Control />} />
                <Route element={<JobsNestedRoute />}>
                  <Route path="/dashboard" element={<JobDashBoard />} />
                  <Route path="/yedaflow" element={<Yedaflow />} />
                  <Route path="/create-job" element={<CreateJobScreen />} />
                  <Route
                    path="/edit-job/:name"
                    element={<EditJobComponent />}
                  />
                  <Route
                    path="/yedaflow/details/:jobName"
                    element={<JobDetails />}
                  />
                </Route>
                <Route element={<DataManagementNestedRoute />}>
                  <Route
                    exact
                    path="/transformation"
                    element={<Transformation />}
                  />
                  <Route path="/catalog" element={<Catalog />} />
                  <Route path="/upload" element={<Upload />} />
                  <Route
                    path="/schema/:Dataset"
                    element={<DataSetDictionaryModal />}
                  />
                  <Route
                    path="/edit/:Dataset"
                    element={<EditDataDictionaryModal />}
                  />
                </Route>
                <Route element={<DataGovernanceNestedRoute />}>
                  <Route
                    path="/dataClassification"
                    element={<DataClassification />}
                  />
                  <Route path="/schemaRegistry" element={<SchemaRegistry />} />
                </Route>
                <Route element={<ObservabilityNestedRoute />}>
                  <Route path="/lineage" element={<Lineage />} />
                  <Route path="/application" element={<Application />} />
                  <Route path="/metrics" element={<Metrics />} />
                  <Route path="/logs" element={<Logs />} />
                  <Route exact path="/dataQuality" element={<DataQuality />} />
                </Route>
                <Route element={<DataScienceNestedRoute />}>
                  <Route path="/notebook" element={<NoteBook />} />
                  <Route path="/mlOps" element={<MLOpsFlow />} />
                </Route>
              </>
            )}
            <Route
              path="*"
              element={<ErrorPage handleSignout={handleSignOut} />}
            />
            {!isLoggedIn && (
              <Route path="/dataManagement" element={<IsNotLoggedIn />} />
            )}
          </Routes>
          {background && (
            <Routes>
              <Route
                path="/schema/:Dataset"
                element={<DataSetDictionaryModal />}
              />
              <Route
                path="/edit/:Dataset"
                element={<EditDataDictionaryModal />}
              />
            </Routes>
          )}
        </MainContainer>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;
