import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { useGetPoolData } from "../../../../clientRequest/env-variable";
import {
  postAllJobs,
  warmUpFetchDatabaseName,
} from "../../../redux/actions/usersActions";
import useS3 from "../../connectors/customHook/useS3";
import useSQL from "../../connectors/customHook/useSQL";
import useAPI from "../../connectors/customHook/useAPI";
import useMYSQL from "../../connectors/customHook/useMYSQL";
import usePostgress from "../../connectors/customHook/usePostgress";
import useGCS from "../../connectors/customHook/useGCS";
import useTransformationScript from "./useTransformationScript";

const useCreateJobHook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getPoolData = useGetPoolData();
  const { transformationScript } = useTransformationScript();
  const { environmentVariables } = useSelector((state) => state.users);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [frequencyOption, setFrequencyOption] = useState("");
  const [startHourMinutes, setStartHourMinutes] = useState("");
  const [dailyTime, setDailyTime] = useState("");
  const [startWeeklyTime, setStartWeeklyTime] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedText, setSelectedText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [jobName, setJobName] = useState("");
  const [isConnectorModalOpen, setIsConnectorModalOpen] = useState(false);
  const [alertValue, setAlertValue] = useState("");
  const [tags, setTags] = useState("");
  const [emailto, setEmailto] = useState("");
  const [emailcc, setEmailcc] = useState("");
  const [emailmessage, setEmailMessage] = useState("");
  const [slackURL, setSlackURL] = useState("");
  const [teamsURL, setTeamsURL] = useState("");
  const [emailsubject, setEmailSubject] = useState("");
  const setQueryDBName = useState(true);
  const inputData = useState(true);
  const ingestions = useState(true);
  const [token, setToken] = useState("");
  const handleJobNameChange = (event) => {
    setJobName(event.target.value);
  };

  //start date
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    const timeChange = event.target.value;
    // Check if the input value matches the time format "HH:mm"
    const timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
    if (timeRegex.test(timeChange) || timeChange === "") {
      setTime(timeChange);
    }
  };
  useEffect(() => {
    const checkSessionExpiration = () => {
      const UserPool = new CognitoUserPool(getPoolData);
      const cognitoUser = UserPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            throw err;
          } else {
            if (session.isValid()) {
              const token = session.getIdToken().jwtToken;
              setToken(token);
            } else {
              cognitoUser.signOut();
              localStorage.clear();
              navigate("/");
            }
          }
        });
      }
    };

    if (getPoolData.ClientId) {
      checkSessionExpiration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getPoolData.ClientId]);
  useEffect(() => {
    if (date && time) {
      const combinedDateTime = new Date(`${date}T${time}`);
      setStartDate(combinedDateTime.toISOString());
    } else {
      setStartDate("");
    }
  }, [date, time]);

  const handleStartHourMinutesChange = (e) => {
    setStartHourMinutes(e.target.value);
  };

  const handleDailyTimeChange = (e) => {
    setDailyTime(e.target.value);
  };

  const handleWeeklyStartTimeChange = (e) => {
    setStartWeeklyTime(e.target.value);
  };

  const handleDayToggle = (day) => {
    const isSelected = selectedDays.includes(day);
    if (isSelected) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day),
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };
  //------- ends here

  //frequency
  const handleFrequencyOption = (e) => {
    const selected = e.target.value;
    setFrequencyOption(selected);
  };
  const frequencydata = [
    { id: 1, option: "", title: "Select" },
    { id: 2, title: "Hourly", option: "Hourly" },
    { id: 3, title: "Daily", option: "Daily" },
    { id: 4, title: "Weekly", option: "Weekly" },
  ];

  let schedulerResult = "";
  if (date) {
    if (frequencyOption === "Hourly") {
      const cronExpression = format(
        new Date(`${date}T00:00`),
        `${startHourMinutes} * * * *`,
      );
      schedulerResult = cronExpression;
    } else if (frequencyOption === "Daily") {
      const [hours, minutes] = dailyTime ? dailyTime.split(":") : ["00", "00"];
      const cronExpression = `${minutes} ${hours} * * *`;
      schedulerResult = cronExpression;
    } else if (frequencyOption === "Weekly") {
      const cronExpression = format(
        new Date(`${date}T${startWeeklyTime ? startWeeklyTime : "00:00"}`),
        "m H * *",
      );
      const selectedDaysExpression = selectedDays
        .map((day) => day.substring(0, 3))
        .join(",");
      schedulerResult = `${cronExpression} ${selectedDaysExpression}`;
    }
  }
  // --------- ends here

  //task
  const handleTaskNameChange = (event, id, data) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          task_name: selected,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };
  const handleTaskTypeChange = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          task_type: selected,
          source_type: selected === "transformation" && "sql",
          ...(selected === "ingestion" && { source: "" }),
          ...(selected === "ingestion" && { code_path: "" }),
        };
      }
      return data;
    });
    setTaskData(updatedData);
    setIsConnectorModalOpen(selected === "ingestion");
  };
  const handleScriptNameChange = (id, newValue) => {
    const scriptValue = transformationScript.find(
      (item) => item.scriptName === newValue.label,
    );
    const newscriptValue = scriptValue ? scriptValue.script : undefined;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          scriptName: newValue ? newValue.label : "",
          script: newscriptValue,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };

  const handleSourceFieldChange = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          source: selected,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };

  const handleCodePathChange = (fileName, id) => {
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          code_path: fileName,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };

  const handleParametersTypeChange = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          parameters_type: selected,
        };
      }
      return data;
    });
    setTaskData(updatedData);
  };

  //UI FORMAT
  const handleKeyChange = (event, id) => {
    const selected = event.target.value;
    const updatedTaskData = taskData.map((data) => {
      if (data.id === id) {
        data.parameters.map((item) => {
          item.key = selected;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handleValueChange = (event, id) => {
    const selected = event.target.value;
    const updatedTaskData = taskData.map((data) => {
      if (data.id === id) {
        data.parameters.map((item) => {
          item.value = selected;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  //--------ENDS HERE
  //JSON FORMAT

  const [jsonData, setJsonData] = useState({
    key: "",
    value: "",
  });

  const handleJsonDataChange = (event, id) => {
    const newText = event.target.value;
    try {
      const parsedData = JSON.parse(newText);
      const updatedTaskData = taskData.map((data) => {
        if (data.id === id) {
          data.parameters.map((item) => {
            item.key = jsonData.key;
            item.value = jsonData.value;
            return item;
          });
        }
        return data;
      });
      setJsonData(parsedData);
      setTaskData(updatedTaskData);
    } catch (error) {
      console.error("Invalid JSON:", error);
    }
  };

  const formattedJson = JSON.stringify(jsonData, undefined, 2);
  //---------ENDS HERE

  const handleTagsChange = (event) => {
    setTags(event.target.value);
  };
  const handleAlertValueChange = (event) => {
    setAlertValue(event.target.value);
  };
  const handleEmailccChange = (event) => {
    setEmailcc(event.target.value);
  };
  const handleEmailToChange = (event) => {
    setEmailto(event.target.value);
  };
  const handleEmailMessageChange = (event) => {
    setEmailMessage(event.target.value);
  };
  const handleEmailSubjectChange = (event) => {
    setEmailSubject(event.target.value);
  };
  const handleSlackURLChange = (event) => {
    setSlackURL(event.target.value);
  };
  const handleTeamsURLChange = (event) => {
    setTeamsURL(event.target.value);
  };
  // --------- ends here

  //open connector modals
  const openModal = (component, text) => {
    setSelectedText(text);
    setSelectedComponent(component);
    if (text === "MySQL" || text === "SQL Server" || text === "Postgres SQL") {
      dispatch(
        warmUpFetchDatabaseName(
          environmentVariables.REACT_APP_TABLE_API,
          token,
        ),
      );
    }
    setModalOpen(true);
  };

  const closeModal = () => {
    // setSelectedText('')
    setSelectedComponent(null);
    setModalOpen(false);
  };

  const onClose = () => {
    setIsConnectorModalOpen(false);
    setModalOpen(false);
    setSelectedComponent(null);
    // setSelectedText('')
  };
  // --------- ends here
  //-----taskData
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const preFilledScriptData = {
    taskName: searchParams.get("taskName"),
    taskType: searchParams.get("taskType"),
    script: searchParams.get("script"),
    sourceType: searchParams.get("sourceType"),
  };
  // eslint-disable-next-line no-unused-vars
  const { taskName, taskType, script, sourceType } = preFilledScriptData;

  const [taskData, setTaskData] = useState(() => [
    {
      id: 1,
      task_name: taskName !== null ? taskName : "",
      task_execution_status: "",
      depends_on: [],
      parallel: "",
      source_type: sourceType !== null ? sourceType : "",
      scriptName: taskName !== null ? taskName : "",
      script: script !== null ? script : "",
      parameters: [
        {
          key: "",
          value: "",
        },
      ],
      parameters_type: "",
      task_type: taskType !== null ? taskType : "",
      engine_type: "",
      params: {
        input_db_host: "",
        input_db_port: "",
        input_db_user: "",
        input_db_pass: "",
        query_db_name: "",
        selected: "",
        queries: [
          {
            table_name: null,
            query: null,
            partitioning: false,
            partition_columns: null,
          },
        ],
        tables: [
          {
            input_db_name: null,
            input_db_schema: null,
            input_table_name: null,
            load_type: null,
            partitioning: false,
            isDelta: false,
            partition_columns: [],
            delta_column: null,
          },
        ],
        file_format: "",
        is_zipped: "",
        header: "",
        separator: "",
        charset: "",
        destination_bucket: "",
        source_bucket: "",
        api_url: "",
        api_client_id: "",
        api_client_secret: "",
        api_header_param: { "": "" },
        api_oauth_flag: "",
        api_oauth_server: "",
        api_http_method: "",
        table_name: "",
        partition_columns: null,
      },
    },
  ]);
  const handleAddMoreTask = () => {
    setTaskData((previousTaskData) => {
      return [
        ...previousTaskData,
        {
          id: previousTaskData.length + 1,
          task_name: "",
          task_execution_status: "",
          depends_on: [],
          parallel: "",
          source_type: "",
          scriptName: "",
          script: "",
          parameters: [
            {
              key: "",
              value: "",
            },
          ],
          parameters_type: "",
          task_type: "",
          engine_type: "",
          params: {
            input_db_host: "",
            input_db_port: "",
            input_db_user: "",
            input_db_pass: "",
            query_db_name: "",
            selectedOption: "",
            queries: [
              {
                table_name: null,
                query: null,
                partitioning: false,
                partition_columns: null,
              },
            ],
            tables: [
              {
                input_db_name: null,
                input_db_schema: null,
                input_table_name: null,
                load_type: null,
                partitioning: false,
                isDelta: false,
                partition_columns: [],
                delta_column: null,
              },
            ],
            file_format: "",
            is_zipped: "",
            header: "",
            separator: "",
            charset: "",
            destination_bucket: "",
            source_bucket: "",
            api_url: "",
            api_client_id: "",
            api_client_secret: "",
            api_header_param: { "": "" },
            api_oauth_flag: "",
            api_oauth_server: "",
            api_http_method: "",
            table_name: "",
            partition_columns: null,
          },
        },
      ];
    });

    setInputValue((previousInputValue) => {
      return [
        ...previousInputValue,
        {
          sourceBucket: "",
          header: false,
          fileFormat: "",
          seperator: "",
          charset: "",
          id: previousInputValue.length + 1,
        },
      ];
    });
    setDBParamsValue((previousSQLServerValue) => {
      return [
        ...previousSQLServerValue,
        {
          host: "",
          port: "",
          user: "",
          password: "",
          id: previousSQLServerValue.length + 1,
        },
      ];
    });
    setmySQLParamsValue((previousmySQLServerValue) => {
      return [
        ...previousmySQLServerValue,
        {
          host: "",
          port: "",
          user: "",
          password: "",
          id: previousmySQLServerValue.length + 1,
        },
      ];
    });
    setAPIInputValue((previousAPIInputValue) => {
      return [
        ...previousAPIInputValue,
        {
          api_url: "",
          api_client_id: "",
          api_client_secret: "",
          api_header_param: { "": "" },
          api_oauth_flag: "",
          id: previousAPIInputValue.length + 1,
        },
      ];
    });
    setpostgresParamsValue((previouspostgresServerValue) => {
      return [
        ...previouspostgresServerValue,
        {
          host: "",
          port: "",
          user: "",
          password: "",
          id: previouspostgresServerValue.length + 1,
        },
      ];
    });
    setGCSValue((previousGCSValue) => {
      return [
        ...previousGCSValue,
        {
          sourceBucket: "",
          header: false,
          fileFormat: "",
          seperator: "",
          charset: "",
          id: previousGCSValue.length + 1,
        },
      ];
    });
  };

  //-----S3
  const [
    setInputValue,
    S3Data,
    handleChange,
    handleS3SourceBucketChange,
    handleS3SourceBucketBlur,
    s3fileNameError,
    handleS3TaskPartitionColumn,
  ] = useS3(taskData, setTaskData);
  //---ENDS HERE ------------------
  //-----DataBase starts-------------------------------------------------------------------

  //---------SQLSERVER
  const [
    dbDataValues,
    setDBParamsValue,
    handleSQLServerValuesChange,
    sqlError,
    handlequeryDataBaseName,
    selectedOption,
    handleSelectChange,
    optionsArray,
    sqlServerdataQueries,
    handleQueriesTableNameChange,
    queries,
    handleQueriesChange,
    checkboxValues,
    handleCheckboxChange,
    partitonColValue,
    handleQueryPartitionChange,
    addMoreSQLServerQueries,
    tableHead,
    tableData,
    handleDatabaseNameChange,
    databaseNameLoader,
    databaseName,
    handleSchemaNameChange,
    schemas,
    handleTableNameChange,
    tables,
    handleTableIngestModeOption,
    tableOptions,
    handleDeltaColumnChange,
    deltaColumn,
    handlePartionChange,
    handlePartitioningChange,
    partitionColumn,
    addRow,
  ] = useSQL(token, taskData, setTaskData);

  ///----------------------------------------------MySQL Server
  const [
    mySQLDataValues,
    setmySQLParamsValue,
    handlemySQLServerValuesChange,
    mySQLError,
    handlemySQLqueryDataBaseName,
    mySQLselectedOption,
    handlemySQLSelectChange,
    mySQLoptionsArray,
    mySQLServerdataQueries,
    handlemySQLQueriesTableNameChange,
    mySQLqueries,
    handlemySQLQueriesChange,
    mySQLcheckboxValues,
    handlemySQLCheckboxChange,
    mySQLpartitonColValue,
    handlemySQLQueryPartitionChange,
    addMoremySQLServerQueries,
    mySQLtableHead,
    mySQLtableData,
    handlemySQLDatabaseNameChange,
    mySQLdatabaseNameLoader,
    mySQLdatabaseName,
    handlemySQLSchemaNameChange,
    mySQLschemas,
    handlemySQLTableNameChange,
    mySQLtables,
    handlemySQLTableIngestModeOption,
    mySQLtableOptions,
    handlemySQLDeltaColumnChange,
    mySQLdeltaColumn,
    handlemySQLPartionChange,
    handlemySQLPartitioningChange,
    mySQLpartitionColumn,
    mySQLaddRow,
  ] = useMYSQL(token, taskData, setTaskData);
  ///----------------------------------------------POSTGRES Server
  const [
    postgresDataValues,
    setpostgresParamsValue,
    handlepostgresServerValuesChange,
    postgresError,
    handlepostgresqueryDataBaseName,
    postgresselectedOption,
    handlepostgresSelectChange,
    postgresoptionsArray,
    postgresServerdataQueries,
    handlepostgresQueriesTableNameChange,
    postgresqueries,
    handlepostgresQueriesChange,
    postgrescheckboxValues,
    handlepostgresCheckboxChange,
    postgrespartitonColValue,
    handlepostgresQueryPartitionChange,
    addMorepostgresServerQueries,
    postgrestableHead,
    postgrestableData,
    handlepostgresDatabaseNameChange,
    postgresdatabaseNameLoader,
    postgresdatabaseName,
    handlepostgresSchemaNameChange,
    postgresschemas,
    handlepostgresTableNameChange,
    postgrestables,
    handlepostgresTableIngestModeOption,
    postgrestableOptions,
    handlepostgresDeltaColumnChange,
    postgresdeltaColumn,
    handlepostgresPartionChange,
    handlepostgresPartitioningChange,
    postgrespartitionColumn,
    postgresaddRow,
  ] = usePostgress(token, taskData, setTaskData);
  //------API
  const [
    ApiData,
    setAPIInputValue,
    handleAPIChange,
    apiHttp,
    httpData,
    httpvalue,
    handleHttpChange,
    renderKeyValue,
    apihandleKeyChange,
    apihandleValueChange,
  ] = useAPI(taskData, setTaskData);
  //-----ENDS HERE-------------------------------------------------------------------------
  //GCS----------------------------------
  const [
    setGCSValue,
    GCSData,
    handleGCSChange,
    handleGCSSourceBucketChange,
    handleGCSSourceBucketBlur,
    gcsfileNameError,
    handleGCSTaskPartitionColumn,
  ] = useGCS(taskData, setTaskData);
  //-----Ends here
  const handleRemoveFromUI = (id) => {
    const updatedWords = taskData.filter((item) => item.id !== id);
    setTaskData(updatedWords);
  };

  const handlePostJobs = () => {
    taskData.forEach((item, index) => {
      if (item.task_type === "ingestion") {
        const { destination_bucket: curatedTaskSourceBucket } = item.params;
        const curatedTask = {
          params: {},
          depends_on: [],
        };
        // const fileName = curatedTaskSourceBucket.split("/")[1]
        // itemClone.params.source_bucket = `${curatedTaskSourceBucket}/${fileName}.${item.params.file_format}`
        curatedTask.params.source_bucket = curatedTaskSourceBucket;
        curatedTask.parallel = "false";
        curatedTask.source_type = "transformation-curated";
        curatedTask.task_type = "ingestion";
        curatedTask.depends_on.push(item.task_name);
        curatedTask.task_name = `${item.task_name}_to_curated`;
        const [rawPath, file] = curatedTaskSourceBucket.split("/");
        const curatedPath = rawPath
          .split("-")
          .map((item) => (item === "raw" ? "curated" : item));
        curatedTask.params.destination_bucket = `${curatedPath.join(
          "-",
        )}/${file}`;
        curatedTask.params.partition_columns = item.params.partition_columns;
        taskData.push(curatedTask);
      }
    });
    const newMySQLTableData = mySQLtableData.map((item) => {
      const {
        id,
        tableSchemasLoader,
        listTablesLoader,
        deltaColumnDataLoader,
        partitionColumnDataLoader,
        ...rest
      } = item;
      return rest;
    });
    const newpostgresSQLTableData = postgrestableData.map((item) => {
      const {
        id,
        tableSchemasLoader,
        listTablesLoader,
        deltaColumnDataLoader,
        partitionColumnDataLoader,
        ...rest
      } = item;
      return rest;
    });
    const newSQLTableData = tableData.map((item) => {
      const {
        id,
        tableSchemasLoader,
        listTablesLoader,
        deltaColumnDataLoader,
        partitionColumnDataLoader,
        ...rest
      } = item;
      return rest;
    });
    const newTaskData = taskData.map((item) => {
      const { parameters_type, selected, engine_type, id, ...rest } = item;
      return {
        ...rest,
        params: {
          ...item.params,
          queries:
            item.params.selected === "query"
              ? selectedText === "MySQL"
                ? mySQLServerdataQueries
                : selectedText === "SQL Server"
                ? sqlServerdataQueries
                : selectedText === "Postgres SQL"
                ? postgresServerdataQueries
                : null
              : null,
          tables:
            item.params.selected === "tables"
              ? selectedText === "MySQL"
                ? newMySQLTableData
                : selectedText === "SQL Server"
                ? newSQLTableData
                : selectedText === "Postgres SQL"
                ? newpostgresSQLTableData
                : null
              : null,
        },
      };
    });
    const group = localStorage.getItem("group");
    const userEmail = localStorage.getItem("created");
    const webhookUrl =
      alertValue === "Slack"
        ? slackURL
        : alertValue === "Teams"
        ? teamsURL
        : "";
    const formData = {
      job_name: jobName,
      group_name: group,
      start_date: startDate,
      schedule_interval: schedulerResult,
      last_execution_date: "",
      last_execution_status: "",
      created_by: userEmail,
      tags: tags,
      tasks: newTaskData,
      alerts: {
        type: alertValue,
        params: {
          to_email_addresses: emailto,
          cc_email_addresses: emailcc,
          subject_email: emailsubject,
          webhook_url: webhookUrl,
        },
      },
    };
    dispatch(
      postAllJobs(
        group,
        formData,
        token,
        environmentVariables.REACT_APP_BASE_URL_API_CORE,
      ),
    );
    navigate("/yedaflow");
  };

  return {
    jobName,
    handleJobNameChange,
    date,
    handleDateChange,
    time,
    handleTimeChange,
    frequencyOption,
    handleFrequencyOption,
    frequencydata,
    startHourMinutes,
    handleStartHourMinutesChange,
    dailyTime,
    handleDailyTimeChange,
    selectedDays,
    handleDayToggle,
    startWeeklyTime,
    handleWeeklyStartTimeChange,
    //task
    taskData,
    handleTaskNameChange,
    handleParametersTypeChange,
    handleKeyChange,
    handleValueChange,
    formattedJson,
    handleJsonDataChange,
    handleTaskTypeChange,
    handleCodePathChange,
    handleSourceFieldChange,
    handleScriptNameChange,

    //CONNECTORS
    isConnectorModalOpen,
    onClose,
    openModal,
    closeModal,
    modalOpen,
    selectedComponent,
    selectedText,
    inputData,
    //s3
    S3Data,
    handleChange,
    handleS3SourceBucketChange,
    handleS3SourceBucketBlur,
    s3fileNameError,
    handleS3TaskPartitionColumn,
    //----------SQL SERVER ----------------------------------------------------
    dbDataValues,
    handleSQLServerValuesChange,
    sqlError,
    setQueryDBName,
    handlequeryDataBaseName,
    ingestions,
    selectedOption,
    handleSelectChange,
    optionsArray,
    sqlServerdataQueries,
    handleQueriesTableNameChange,
    queries,
    handleQueriesChange,
    checkboxValues,
    handleCheckboxChange,
    partitonColValue,
    handleQueryPartitionChange,
    addMoreSQLServerQueries,
    tableHead,
    tableData,
    handleDatabaseNameChange,
    databaseNameLoader,
    databaseName,
    handleSchemaNameChange,
    schemas,
    handleTableNameChange,
    tables,
    handleTableIngestModeOption,
    tableOptions,
    handleDeltaColumnChange,
    deltaColumn,
    handlePartionChange,
    handlePartitioningChange,
    partitionColumn,
    addRow,
    //-----------------MYSQL
    mySQLDataValues,
    handlemySQLServerValuesChange,
    mySQLError,
    handlemySQLqueryDataBaseName,
    mySQLselectedOption,
    handlemySQLSelectChange,
    mySQLoptionsArray,
    mySQLServerdataQueries,
    handlemySQLQueriesTableNameChange,
    mySQLqueries,
    handlemySQLQueriesChange,
    mySQLcheckboxValues,
    handlemySQLCheckboxChange,
    mySQLpartitonColValue,
    handlemySQLQueryPartitionChange,
    addMoremySQLServerQueries,
    mySQLtableHead,
    mySQLtableData,
    handlemySQLDatabaseNameChange,
    mySQLdatabaseNameLoader,
    mySQLdatabaseName,
    handlemySQLSchemaNameChange,
    mySQLschemas,
    handlemySQLTableNameChange,
    mySQLtables,
    handlemySQLTableIngestModeOption,
    mySQLtableOptions,
    handlemySQLDeltaColumnChange,
    mySQLdeltaColumn,
    handlemySQLPartionChange,
    handlemySQLPartitioningChange,
    mySQLpartitionColumn,
    mySQLaddRow,
    //--------postgress
    //------------------POSTGRESS
    postgresDataValues,
    handlepostgresServerValuesChange,
    postgresError,
    handlepostgresqueryDataBaseName,
    postgresselectedOption,
    handlepostgresSelectChange,
    postgresoptionsArray,
    postgresServerdataQueries,
    handlepostgresQueriesTableNameChange,
    postgresqueries,
    handlepostgresQueriesChange,
    postgrescheckboxValues,
    handlepostgresCheckboxChange,
    postgrespartitonColValue,
    handlepostgresQueryPartitionChange,
    addMorepostgresServerQueries,
    postgrestableHead,
    postgrestableData,
    handlepostgresDatabaseNameChange,
    postgresdatabaseNameLoader,
    postgresdatabaseName,
    handlepostgresSchemaNameChange,
    postgresschemas,
    handlepostgresTableNameChange,
    postgrestables,
    handlepostgresTableIngestModeOption,
    postgrestableOptions,
    handlepostgresDeltaColumnChange,
    postgresdeltaColumn,
    handlepostgresPartionChange,
    handlepostgresPartitioningChange,
    postgrespartitionColumn,
    postgresaddRow,
    //csv
    //API
    ApiData,
    handleAPIChange,
    apiHttp,
    httpData,
    httpvalue,
    handleHttpChange,
    renderKeyValue,
    apihandleKeyChange,
    apihandleValueChange,
    //-------GCS
    GCSData,
    handleGCSChange,
    handleGCSSourceBucketChange,
    gcsfileNameError,
    handleGCSSourceBucketBlur,
    handleGCSTaskPartitionColumn,
    //----------------ENDS ---------
    // handleEngineType,
    handleAddMoreTask,
    handleRemoveFromUI,
    tags,
    handleTagsChange,
    alertValue,
    handleAlertValueChange,
    emailto,
    handleEmailToChange,
    emailcc,
    handleEmailccChange,
    emailsubject,
    handleEmailSubjectChange,
    emailmessage,
    handleEmailMessageChange,
    slackURL,
    handleSlackURLChange,
    teamsURL,
    handleTeamsURLChange,
    handlePostJobs,
  };
};

export default useCreateJobHook;
