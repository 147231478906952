import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

import {
  PreLoader,
  StyledPortalContent,
  StyledDashboardContentFlex,
  Flex,
  Search,
  Tabs,
  CreateJobButton,
  JobName,
  TableDataCell,
  FilteredBox,
  StyledFilteredBoxContainer,
  Book,
  DeleteSvg,
  PlaySvg,
  HomeSvg,
  JobsSvg,
  WorkFlow,
  TableOverview,
  DeleteModal,
  UsePaginations,
} from "../../index";
import { useNavigate } from "react-router-dom";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { useGetPoolData } from "../../../clientRequest/env-variable";
import {
  deleteJobs,
  fetchJobs,
  fetchCustomerData,
  setIsJobEditing,
  clearToastError,
  clearToastSuccess,
} from "../../redux/actions/usersActions";
import axios from "axios";

const YedaflowOverview = () => {
  const dispatch = useDispatch();
  const getPoolData = useGetPoolData();
  const [currentPage, setCurrentPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);
  const {
    getJobs,
    getJobLoader,
    environmentVariables,
    jobError,
    patchJobError,
    postJobSuccess,
    patchJobSuccess,
    getCustomers,
    getCustomersLoading,
  } = useSelector((state) => state.users);
  //search
  const [searchTerm, setSearchTerm] = useState("");
  //sort
  let filteredJobs = [];
  const sortByRunDate = () => {
    filteredJobs = getJobs.sort((recentJobs, oldJobs) =>
      moment(recentJobs.last_execution_date)
        .format("lll")
        .localeCompare(moment(oldJobs.last_execution_date).format("lll")),
    );
  };
  const sortByExecutionStatus = () => {
    filteredJobs = getJobs.sort((recentJobs, oldJobs) =>
      recentJobs.last_execution_status.localeCompare(
        oldJobs.last_execution_status,
      ),
    );
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  filteredJobs = getJobs.filter((job) => {
    return job.job_name.toLowerCase().includes(searchTerm.toLowerCase());
  });
  const endOffset = currentPage + pageSize;
  const currentJobs = filteredJobs.slice(currentPage, endOffset);
  const pageCount = Math.ceil(filteredJobs.length / pageSize);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSessionExpiration = () => {
      const UserPool = new CognitoUserPool(getPoolData);
      const cognitoUser = UserPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            throw err;
          } else {
            if (session.isValid()) {
              const token = session.getIdToken().jwtToken;
              setToken(token);
              dispatch(
                fetchJobs(
                  token,
                  environmentVariables.REACT_APP_BASE_URL_API_CORE,
                ),
              );
              dispatch(
                fetchCustomerData(
                  token,
                  environmentVariables.REACT_APP_BASE_URL_API_BACKEND,
                ),
              );
            } else {
              cognitoUser.signOut();
              navigate("/");
            }
          }
        });
      }
    };

    if (getPoolData.ClientId) {
      checkSessionExpiration();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getPoolData.ClientId]);

  const [isModalOpen, setModalOpen] = useState(false);

  const [selectedjobName, setselectedJobName] = useState(false);

  const openModal = (name) => {
    setselectedJobName(name);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const handleDeleteActionClick = (selectedjobName) => {
    const userPool = new CognitoUserPool(getPoolData);
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          throw err;
        } else {
          if (session.isValid()) {
            const token = session.getIdToken().jwtToken;
            dispatch(
              deleteJobs(
                selectedjobName,
                token,
                environmentVariables.REACT_APP_BASE_URL_API_CORE,
              ),
            );
            closeModal();
          } else {
            cognitoUser.signOut();
            localStorage.clear();
            navigate("/");
          }
        }
      });
    }
  };
  function handlePageChange(event) {
    const newOffset = (event.selected * pageSize) % getJobs.length;
    setCurrentPage(newOffset);
  }
  const createdBy = (created) => {
    return created.split("@")[0];
  };
  const fill = "#006399";
  const size = "18";
  const tabData = [
    {
      to: "/dataManagement",
      icon: <HomeSvg fill={fill} width={size} height={size} />,
      label: "Home",
    },
    {
      to: "/create-job",
      icon: <JobsSvg fill={fill} width={size} height={size} />,
      label: "Jobs",
    },
    {
      to: "/yedaflow",
      icon: <WorkFlow fill={fill} width={size} height={size} />,
      label: "WorkFlow Management",
    },
  ];
  useEffect(() => {
    if (postJobSuccess.message) {
      toast.success("New Job Added", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });
      dispatch(clearToastSuccess());
    }
    if (jobError) {
      toast.warning("Job already exist.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });
      dispatch(clearToastError());
    }
    if (patchJobSuccess.message) {
      toast.success("Update Successful", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });
      dispatch(clearToastSuccess());
    }
    if (patchJobError) {
      toast.warning("Update failed, try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });
      dispatch(clearToastError());
    }
  }, [
    dispatch,
    jobError,
    patchJobError,
    patchJobSuccess.message,
    postJobSuccess.message,
  ]);

  async function triggerJob(jobName) {
    try {
      const toastId = toast.loading("Waiting for job to start running", {
        autoClose: true,
        closeButton: true,
      });

      const group = localStorage.getItem("group");
      if (getCustomersLoading) {
        const currentCustomer = getCustomers.find(
          (e) => e.customerName === group,
        );
        axios
          .post(
            "https://api-core.databoat.nl/yeda/manual-handler",
            {
              cloudCredential: [
                {
                  cloudProvider:
                    currentCustomer.cloudCredential[0].cloudProvider,
                  ssmPathParam: currentCustomer.cloudCredential[0].ssmPathParam,
                  region: currentCustomer.cloudCredential[0].region,
                },
              ],
              job_name: jobName,
              is_new: true,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(() => {
            toast.dismiss(toastId);
            toast.success("Job started running", {
              pauseOnFocusLoss: false,
              closeOnClick: true,
              closeButton: true,
              autoClose: 5000,
            });
          })
          .catch(() => {
            toast.dismiss(toastId);
            toast.error("Unable to run job right now", {
              pauseOnFocusLoss: false,
              closeOnClick: true,
              closeButton: true,
              autoClose: 5000,
            });
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <StyledPortalContent>
      <ToastContainer />
      {getJobLoader ? (
        <div style={{ padding: "1em" }}>
          <StyledDashboardContentFlex column>
            <StyledDashboardContentFlex justifyContent>
              <Tabs tabs={tabData} />
              <CreateJobButton
                text={"Create Job"}
                type={"button"}
                onClick={() => navigate("/create-job")}
              />
            </StyledDashboardContentFlex>
            <StyledDashboardContentFlex justifyContent>
              <Flex>
                <Search
                  placeholder="Search Jobs"
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <Flex>
                  <FilteredBox
                    text={"Start time: All"}
                    handleSorting={sortByRunDate}
                  />
                  <FilteredBox
                    text={"Status"}
                    handleSorting={sortByExecutionStatus}
                  />
                  <FilteredBox text={"UI, +1"} />
                  <FilteredBox text={"User"} />
                </Flex>
              </Flex>
              <StyledFilteredBoxContainer>
                <Book />
                <div className="text">Manage columns</div>
              </StyledFilteredBoxContainer>
            </StyledDashboardContentFlex>
            <Flex column style={{ gap: "2em" }}>
              {/* tables  */}
              <TableOverview
                headers={[
                  "Name",
                  "Created by",
                  "Last run status",
                  "Last run date",
                  "Actions",
                ]}
                rows={currentJobs}
                width={"150px"}
                renderRow={(job, index) => (
                  <tr key={index}>
                    <TableDataCell>
                      <JobName
                        onClick={() =>
                          navigate(`/yedaflow/details/${job.job_name}`, {
                            state: job,
                          })
                        }
                      >
                        {job.job_name}
                      </JobName>
                    </TableDataCell>
                    <TableDataCell>
                      {job.created_by ? createdBy(job.created_by) : "Unknown"}
                    </TableDataCell>
                    <TableDataCell>
                      {job.last_execution_status === ""
                        ? "no status"
                        : job.last_execution_status}
                    </TableDataCell>
                    <TableDataCell>
                      {job.last_execution_date === ""
                        ? "no runs"
                        : moment(job.last_execution_date).format("lll")}
                    </TableDataCell>
                    <TableDataCell style={{ display: "flex", gap: "0.75em" }}>
                      <div
                        onClick={() => {
                          dispatch(setIsJobEditing(true));
                          navigate(`/edit-job/${job.job_name}`);
                        }}
                      >
                        <svg
                          fill="none"
                          cursor={"pointer"}
                          viewBox="0 0 24 24"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="#72777F"
                            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                          ></path>
                        </svg>
                      </div>
                      <PlaySvg onClick={() => triggerJob(job.job_name)} />
                      <DeleteSvg onClick={() => openModal(job.job_name)} />
                    </TableDataCell>
                  </tr>
                )}
              />
              {isModalOpen && (
                <DeleteModal
                  isModalOpen={isModalOpen}
                  closeModal={closeModal}
                  handleDeleteActionClick={() =>
                    handleDeleteActionClick(selectedjobName)
                  }
                />
              )}
              <UsePaginations
                pageCount={pageCount}
                endOffset={endOffset}
                databaseLength={getJobs.length}
                handlePageChange={handlePageChange}
              />
            </Flex>
          </StyledDashboardContentFlex>
        </div>
      ) : (
        <PreLoader />
      )}
    </StyledPortalContent>
  );
};

export default YedaflowOverview;
