/* eslint-disable no-unused-vars */
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { Dialog } from "@mui/material";
import CloseSvg from "../../reusableComponents/svg/CloseSvg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetPoolData } from "../../../clientRequest/env-variable";
import { getCatalogDataBases } from "../../redux/actions/glueMetadataActions";
import {
  fetchCustomerData,
  postTransformationQuery,
  getTransformationResult,
  fetchJobs,
} from "../../redux/actions/usersActions";
import ButtonOutlineIcon from "../../reusableComponents/UIComponents/ButtonOutlineIcon";
import { StyledDashboardContentFlex } from "../../styles/Login.Styled";
import { StyledModal } from "../../styles/Modal.Styled";
import TransformationPresenter from "./TransformationPresenter";
import { ModalHeader } from "../../styles/Modal.Styled";
import { axiosCatalogApi } from "../../../clientRequest/axiosRequest";
import { updateCatalogTablesAttributes } from "../../redux/reducers/glueMetadataReducer";
import _ from "lodash";

const TransformationContainer = () => {
  const [token, setToken] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [cloudCredential, setCloudCredential] = useState(null);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [existingJob, setExistingJob] = useState("");
  const [newJob, setNewJob] = useState(true);
  const dispatch = useDispatch();
  const getPoolData = useGetPoolData();
  const navigate = useNavigate();

  const {
    environmentVariables,
    envLoader,
    getCustomers,
    getJobs,
    getJobLoader,
    getCustomersLoading,
    postQuerySuccess,
    getCustomersError,
  } = useSelector((state) => state.users);
  const { catalogDataBases, catalogTablesAttributes } = useSelector(
    (state) => state.glueMetadata,
  );

  const athenaBaseURL =
    environmentVariables.REACT_APP_ATHENA_DATA_TRANSFORMATION;
  const executeQueryBaseURL =
    environmentVariables.REACT_APP_ATHENA_QUERY_EXECUTE;
  const baseURL = environmentVariables.REACT_APP_BASE_URL_API_BACKEND;
  const appEnvironment = environmentVariables.REACT_APP_ENVIRONMENT ?? "dev";

  const group = localStorage.getItem("group");

  const [dbSchemaRepresentation, setDbSchemaRepresentation] = useState(
    catalogTablesAttributes,
  );

  const [SQLTab, setSQLTab] = useState([
    {
      id: 1,
      name: "Query 1",
      query: "",
      saved: false,
    },
  ]);
  const [selectedSQLTab, setSelectedSQLTab] = useState(SQLTab[0]);

  useEffect(() => {
    const updatedTab = SQLTab.find((tab) => tab.id === selectedSQLTab.id);
    setSelectedSQLTab(updatedTab);
  }, [SQLTab, selectedSQLTab, selectedSQLTab.id]);

  const [isOpenResults, setIsOpenResults] = useState(false);

  useEffect(() => {
    const checkSessionExpiration = () => {
      const UserPool = new CognitoUserPool(getPoolData);
      const cognitoUser = UserPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            throw err;
          } else {
            if (session.isValid()) {
              const token = session.getIdToken().jwtToken;
              setToken(token);
              dispatch(
                fetchJobs(
                  token,
                  environmentVariables.REACT_APP_BASE_URL_API_CORE,
                ),
              );
              dispatch(
                fetchCustomerData(
                  token,
                  environmentVariables.REACT_APP_BASE_URL_API_BACKEND,
                ),
              );
            } else {
              cognitoUser.signOut();
            }
          }
        });
      }
    };

    if (getPoolData.ClientId) {
      checkSessionExpiration();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getPoolData.ClientId]);
  useEffect(() => {
    try {
      if (getCustomersLoading) {
        const currentCustomer = getCustomers.find(
          (e) => e.customerName === group,
        );
        setCurrentCustomer(currentCustomer);
        setCloudCredential({
          cloudCredential: [
            {
              cloudProvider: currentCustomer.cloudCredential[0].cloudProvider,
              ssmPathParam: currentCustomer.cloudCredential[0].ssmPathParam,
              region: currentCustomer.cloudCredential[0].region,
            },
          ],
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [getCustomers, getCustomersLoading, group]);
  useEffect(() => {
    if (cloudCredential && token && athenaBaseURL) {
      dispatch(getCatalogDataBases(athenaBaseURL, token, cloudCredential));
    }
  }, [cloudCredential, token, athenaBaseURL, dispatch]);

  useEffect(() => {
    if (cloudCredential && token && athenaBaseURL) {
      async function fetchTables() {
        let updatedCatalogDatabases = {};
        catalogDataBases.forEach((database) => {
          updatedCatalogDatabases[database] = {};
        });

        const cloudCredentialsArray = Object.keys(updatedCatalogDatabases).map(
          (databaseName) => {
            return {
              ...cloudCredential.cloudCredential[0],
              path: `list-tables/${databaseName}`,
            };
          },
        );
        Promise.allSettled([
          ...cloudCredentialsArray.map((cloudCredential) =>
            axiosCatalogApi("https://api-core.databoat.nl", token).post(
              "/catalog/list-tables",
              { cloudCredential: [cloudCredential] },
            ),
          ),
        ])
          .then((results) => {
            results.forEach((response, index) => {
              const databaseName = JSON.parse(
                results[index].value.config.data,
              ).cloudCredential[0].path.split("/")[1];
              response.value.data.forEach((table) => {
                updatedCatalogDatabases[databaseName] = {
                  ...updatedCatalogDatabases[databaseName],
                  [table]: {
                    attributes: [],
                  },
                };
              });
            });

            Promise.allSettled([
              ...Object.keys(updatedCatalogDatabases).flatMap(
                (databaseName) => {
                  return Object.keys(updatedCatalogDatabases[databaseName]).map(
                    (tableName) => {
                      return axiosCatalogApi(
                        "https://api-core.databoat.nl",
                        token,
                      ).post("/catalog/list-table-attributes", {
                        cloudCredential: [
                          {
                            ...cloudCredential.cloudCredential[0],
                            path: `list-table-attributes/${databaseName}/${tableName}`,
                          },
                        ],
                      });
                    },
                  );
                },
              ),
            ]).then((results) => {
              results.forEach((response) => {
                const [_, databaseName, tableName] = JSON.parse(
                  response.value.config.data,
                ).cloudCredential[0].path.split("/");
                updatedCatalogDatabases[databaseName][tableName].attributes =
                  response.value.data;
              });

              if (
                !_.isEqual(updatedCatalogDatabases, catalogTablesAttributes)
              ) {
                setDbSchemaRepresentation(updatedCatalogDatabases);
                dispatch(
                  updateCatalogTablesAttributes(updatedCatalogDatabases),
                );
              }
            });
          })
          .catch(console.log);
      }
      fetchTables();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [athenaBaseURL, catalogDataBases, currentCustomer, token]);

  const handleQueryTabSelect = (tabId) => {
    const newActiveQuery = SQLTab.find(
      (item) => item !== undefined && item.id === tabId,
    );
    setSelectedSQLTab(newActiveQuery);
  };

  const handleAddNewQueryTab = () => {
    const id = SQLTab.length + 1;
    const tabObj = {
      id,
      name: `Query ${id}`,
      query: "",
      saved: false,
    };

    setSQLTab([...SQLTab, tabObj]);
  };

  const handleUpdateQueryValue = (id, value) => {
    const index = SQLTab.findIndex((item) => item.id === id);

    if (index !== -1) {
      const updatedSQLTab = [...SQLTab];
      updatedSQLTab[index].query = value;
      setSQLTab(updatedSQLTab);
    }
    setisRunEnabled(true);
  };

  const [isRunEnabled, setisRunEnabled] = useState(false);
  const handleRunSQL = () => {
    const queryValue = SQLTab[0].query;
    if (currentCustomer !== null) {
      const formData = {
        ssmParameterName: currentCustomer.cloudCredential[0].ssmPathParam,
        query: queryValue,
      };
      if (queryValue) {
        dispatch(
          postTransformationQuery(
            formData,
            token,
            environmentVariables.REACT_APP_BASE_URL_API_CORE,
          ),
        );
      }
    }
    setIsOpenResults(true);
  };

  //handles result logic
  useEffect(() => {
    if (postQuerySuccess.jobId !== undefined) {
      if (currentCustomer !== null) {
        const formData = {
          ssmParameterName: currentCustomer.cloudCredential[0].ssmPathParam,
        };

        dispatch(
          getTransformationResult(
            token,
            environmentVariables.REACT_APP_BASE_URL_API_CORE,
            postQuerySuccess.jobId,
            formData,
          ),
        );
      }
    }
  }, [
    currentCustomer,
    dispatch,
    environmentVariables.REACT_APP_BASE_URL_API_CORE,
    postQuerySuccess.jobId,
    token,
  ]);
  const handleOpenResults = () => {
    setIsOpenResults(!isOpenResults);
  };
  const [open, setOpen] = useState(false);
  const [scriptName, setScriptName] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
    setScriptName(selectedSQLTab.saved ? selectedSQLTab.name : "");
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleScriptNameChange = (e) => {
    setScriptName(e.target.value);
  };
  const handleScheduleModal = () => {
    setIsScheduleModalOpen((prev) => !prev);
  };

  const proceedButtonDisabled = () => {
    if (newJob === "") {
      return true;
    }

    if (!newJob && existingJob === "") {
      return true;
    }

    return false;
  };

  const proceed = proceedButtonDisabled();

  const proceedOnScheduleModal = () => {
    const queryValue = SQLTab[0].query;
    if (newJob) {
      navigate(
        `/create-job?taskName=${scriptName}&taskType=transformation&script=${queryValue}&sourceType=${"sql"}`,
      );
    }

    if (!newJob && existingJob !== "") {
      navigate(
        `/edit-job/${existingJob}?taskName=${scriptName}&taskType=transformation&script=${queryValue}`,
      );
    }
  };
  return (
    <>
      <TransformationPresenter
        dbSchemaRepresentation={dbSchemaRepresentation}
        selectedSQLTab={selectedSQLTab}
        SQLTab={SQLTab}
        setSQLTab={setSQLTab}
        handleQueryTabSelect={handleQueryTabSelect}
        handleAddNewQueryTab={handleAddNewQueryTab}
        handleUpdateQueryValue={handleUpdateQueryValue}
        isOpenResults={isOpenResults}
        handleOpenResults={handleOpenResults}
        scriptName={scriptName}
        handleScriptNameChange={handleScriptNameChange}
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        handleRunSQL={handleRunSQL}
        handleScheduleModal={handleScheduleModal}
        isRunEnabled={isRunEnabled}
      />
      {isScheduleModalOpen && (
        <StyledModal>
          <Dialog
            open={isScheduleModalOpen}
            onClose={() => setIsScheduleModalOpen((prev) => !prev)}
            fullWidth
            PaperProps={{ sx: { borderRadius: "28px", overflow: "visible" } }}
          >
            <ModalHeader>
              <p>Scheduler</p>
              <div
                onClick={() => setIsScheduleModalOpen((prev) => !prev)}
                style={{ cursor: "pointer" }}
              >
                <CloseSvg />
              </div>
            </ModalHeader>
            <StyledDashboardContentFlex column style={{ padding: "2em" }}>
              <p>Do you want to add this script to an existing job?</p>
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Create new job?
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={newJob}
                  label="Create new job?"
                  onChange={(event) => setNewJob(event.target.value)}
                  defaultValue={newJob}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
              {newJob === false && getJobLoader && (
                <FormControl>
                  <Autocomplete
                    disablePortal
                    value={existingJob}
                    id="select-job"
                    options={getJobs.map((job) => ({ label: job.job_name }))}
                    onChange={(_event, newValue) => {
                      setExistingJob(newValue ? newValue.label : "");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Job" />
                    )}
                  />
                </FormControl>
              )}
              <ButtonOutlineIcon
                label="Proceed"
                disabled={proceed}
                handleOnClick={proceedOnScheduleModal}
              ></ButtonOutlineIcon>
            </StyledDashboardContentFlex>
          </Dialog>
        </StyledModal>
      )}
    </>
  );
};

export default TransformationContainer;
