import React from "react";
import {
  StyledDashboardContentFlex,
  Flex,
  StyledP,
  StyledFileImports,
  Theme,
} from "../index";
import { AiOutlineCloudUpload } from "react-icons/ai";

const DragandDrop = ({
  fileRejections,
  getRootProps,
  isDragActive,
  getInputProps,
  errMsg,
  errRef,
}) => {
  return (
    <StyledDashboardContentFlex column>
      <p
        style={{
          color: Theme.colors.blueColor,
          font: Theme.fonts.fontPrimary,
          letterspacing: Theme.fonts.primaryletterSpacing,
        }}
      >
        Add files
      </p>
      <StyledFileImports {...getRootProps()}>
        <Flex column gap center centerAlign>
          <AiOutlineCloudUpload size={"4em"} />
          <div>
            <label htmlFor="file-input">
              <StyledDashboardContentFlex
                gap
                justifyContent
                style={{ padding: "1em" }}
              >
                {isDragActive ? (
                  <StyledP>Drop the files here ...</StyledP>
                ) : (
                  <Flex column gap>
                    <StyledP>Only accepts CSV files.</StyledP>
                    <StyledP className="browse">
                      Datalake table name will be based on file name
                    </StyledP>
                    <StyledP>
                      Drop one or more files here, or{" "}
                      <span {...getRootProps()} className="browse">
                        browse
                      </span>
                    </StyledP>
                    <StyledP>
                      Maximum of 3 files and total upload size 1GB
                    </StyledP>
                  </Flex>
                )}
              </StyledDashboardContentFlex>
            </label>
            <input
              {...getInputProps()}
              id="file-input"
              type="file"
              className="upload_input"
              name="csv"
              accept="application/json, application/vnd.ms-excel, text/csv"
            />
          </div>
        </Flex>
      </StyledFileImports>

      <div>
        {fileRejections.map(({ file }) => (
          <StyledDashboardContentFlex justifyContent>
            {file ? (
              <StyledP>
                Invalid File Format, Please make sure you're uploading a CSV
                file.
              </StyledP>
            ) : null}
          </StyledDashboardContentFlex>
        ))}
        <div>
          {errMsg && (
            <section className="error">
              <p ref={errRef} aria-live="assertive">
                {errMsg}
              </p>
            </section>
          )}
        </div>
      </div>
    </StyledDashboardContentFlex>
  );
};

export default DragandDrop;
