import { React, useState } from "react";

import {
  DataBaseSideBar,
  HeaderTabButton,
  StyledScriptNameLink,
} from "../Styles/TransformationStyle";
import {
  PiDatabaseLight,
  PiFilmScriptThin,
  PiCheckBold,
  PiArrowUpBold,
  PiArrowDownBold,
  PiCaretRightLight,
  PiCaretLeftLight,
} from "react-icons/pi";
import ChipInputLeadingIconLabel from "../../../reusableComponents/UIComponents/ChipInputLeadingIconLabel";
import ChipInputLabelTrailingIcon from "../../../reusableComponents/UIComponents/ChipInputLabelTrailingIcon";
import DatabaseList from "./DatabaseList";
import IconTonalButton from "../../../reusableComponents/UIComponents/IconTonalButton";
import IconStandardButton from "../../../reusableComponents/UIComponents/IconStandardButton";
import Search from "../../../reusableComponents/Search";
import { Flex } from "../../..";
import useTransformationScript from "../../../jobs/allJobs/customHook/useTransformationScript";

const DbSideBar = ({ dbSchemaRepresentation, SQLTab }) => {
  const { transformationScript } = useTransformationScript();
  const [isDbSideBarOpen, setIsDbSideBarOpen] = useState(true);
  const [tabSelect, setTabSelect] = useState("Catalog");
  const [isAllDbSelected, setIsAllDbSelected] = useState(false);
  const [isAllScriptsSelected, setIsAllScriptsSelected] = useState(false);

  const [isNameSortSelected, setIsNameSortSelected] = useState(false);
  const [searchScripts, setSearchScripts] = useState("");

  const handleSearchScripts = (e) => {
    setSearchScripts(e.target.value);
  };

  return (
    <DataBaseSideBar isOpen={isDbSideBarOpen}>
      {isDbSideBarOpen ? (
        <>
          <aside className="dataBaseOpenSideBarHeader">
            <HeaderTabButton
              onClick={() => {
                setTabSelect("Catalog");
              }}
              selected={tabSelect === "Catalog"}
            >
              <PiDatabaseLight size={24} />
              Catalog
            </HeaderTabButton>
            <HeaderTabButton
              onClick={() => {
                setTabSelect("Script");
              }}
              selected={tabSelect === "Script"}
            >
              <PiFilmScriptThin size={24} />
              Script
            </HeaderTabButton>
            <HeaderTabButton selected={false}>
              <PiCaretLeftLight
                size={24}
                onClick={() => {
                  setIsDbSideBarOpen(!isDbSideBarOpen);
                }}
              />
            </HeaderTabButton>
          </aside>
          <div
            style={{
              width: "100%",
              height: "1px",
              background: "#C2C7CF",
            }}
          ></div>
          {tabSelect === "Catalog" ? (
            <div className="dataBaseListContainer">
              <div className="dataBaseOptionsFilter">
                <ChipInputLeadingIconLabel
                  label="All"
                  handleOnClick={() => {
                    setIsAllDbSelected(!isAllDbSelected);
                  }}
                  selected={isAllDbSelected}
                  leadingIcon={<PiCheckBold />}
                />
                <ChipInputLabelTrailingIcon
                  label="Name"
                  handleOnClick={() => {
                    setIsNameSortSelected(!isNameSortSelected);
                  }}
                  selected={isNameSortSelected}
                  leadingIcon={
                    isNameSortSelected ? <PiArrowUpBold /> : <PiArrowDownBold />
                  }
                />
              </div>
              <DatabaseList dbSchemaRepresentation={dbSchemaRepresentation} />
            </div>
          ) : (
            <div className="dataBaseListContainer">
              <div className="dataBaseOptionsFilter">
                <ChipInputLeadingIconLabel
                  label="All (3)"
                  handleOnClick={() => {
                    setIsAllScriptsSelected(!isAllScriptsSelected);
                  }}
                  selected={isAllScriptsSelected}
                  leadingIcon={<PiCheckBold />}
                />
                <ChipInputLabelTrailingIcon
                  label="Mine (3)"
                  handleOnClick={() => {
                    setIsNameSortSelected(!isNameSortSelected);
                  }}
                  selected={isNameSortSelected}
                />
                <ChipInputLabelTrailingIcon
                  label="Date"
                  handleOnClick={() => {
                    setIsNameSortSelected(!isNameSortSelected);
                  }}
                  selected={isNameSortSelected}
                  leadingIcon={
                    isNameSortSelected ? <PiArrowUpBold /> : <PiArrowDownBold />
                  }
                />
              </div>
              <Search
                placeholder="Search Scripts"
                value={searchScripts}
                onChange={handleSearchScripts}
                width="100%"
              />
              <div
                style={{
                  width: "100%",
                  height: 400,
                  maxWidth: 360,
                  overflow: "auto",
                }}
              >
                <Flex
                  column
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {transformationScript.map((script, index) => (
                    <StyledScriptNameLink key={index}>
                      {script.scriptName}
                    </StyledScriptNameLink>
                  ))}
                  {SQLTab.map((script, index) =>
                    script.name.includes("Query") ? (
                      ""
                    ) : (
                      <StyledScriptNameLink key={index}>
                        {script.name}
                      </StyledScriptNameLink>
                    ),
                  )}
                </Flex>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="dataBaseCloseSideBarContainer">
            <HeaderTabButton selected={false}>
              <PiCaretRightLight
                size={24}
                onClick={() => {
                  setIsDbSideBarOpen(!isDbSideBarOpen);
                }}
              />
            </HeaderTabButton>
            <div className="dataBaseCloseSideBarOptions">
              {tabSelect === "Catalog" ? (
                <>
                  <IconTonalButton
                    icon={<PiDatabaseLight size={24} />}
                    handleOnClick={() => {
                      setIsDbSideBarOpen(true);
                      setTabSelect("Catalog");
                    }}
                  />
                  Catalog
                  <IconStandardButton
                    icon={<PiFilmScriptThin size={24} />}
                    handleOnClick={() => {
                      setIsDbSideBarOpen(true);
                      setTabSelect("Script");
                    }}
                  />
                  Script
                </>
              ) : (
                <>
                  <IconStandardButton
                    icon={<PiDatabaseLight size={24} />}
                    handleOnClick={() => {
                      setIsDbSideBarOpen(true);
                      setTabSelect("Catalog");
                    }}
                  />
                  Catalog
                  <IconTonalButton
                    icon={<PiFilmScriptThin size={24} />}
                    handleOnClick={() => {
                      setIsDbSideBarOpen(true);
                      setTabSelect("Script");
                    }}
                  />
                  Script
                </>
              )}
            </div>
          </div>
        </>
      )}
    </DataBaseSideBar>
  );
};

export default DbSideBar;
