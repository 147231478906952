import React from "react";
import {
  TableDataCell,
  ScrollableTableOverview,
  FieldSet,
} from "../../../index";

const EditDataDictionaryTable = ({ rows, handleEditingData }) => {
  return (
    <ScrollableTableOverview
      headers={["#", "Column Name", "Data Type", "Description"]}
      rows={rows}
      renderRow={(item, index) => (
        <tr key={index}>
          <TableDataCell>{item.id}</TableDataCell>
          <TableDataCell>{item.ColumnName}</TableDataCell>
          <TableDataCell>{item.DataType}</TableDataCell>
          <TableDataCell style={{ display: "flex", gap: "0.75em" }}>
            <FieldSet
              legendTitle={"Description"}
              type={"text"}
              name={"Description"}
              id={`description-${index}`}
              value={item.Description}
              onChange={(e) => {
                handleEditingData(e, index);
              }}
              placeholder={"description"}
            />
          </TableDataCell>
        </tr>
      )}
    />
  );
};
export default EditDataDictionaryTable;
