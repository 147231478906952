import React from "react";
import { StyledModal, StyledDashboardContentFlex, Theme } from "../../index";
import styled from "styled-components";
import { Dialog } from "@mui/material";

const DeleteModal = ({ isModalOpen, closeModal, handleDeleteActionClick }) => {
  if (!isModalOpen) return null;
  const flexContainer = {
    display: "flex",
    justifyContent: "flex-end",
    gap: "1em",
    padding: "1.5em",
  };
  const title = {
    color: Theme.colors.sysSurface2Color,
    fonts: Theme.fonts.fontMediumBold,
  };
  const confirmTest = {
    color: Theme.colors.sysSurfaceVariant,
    font: Theme.fonts.smallFont,
    letterSpacing: "0.25px",
  };

  const Paragraph = styled.p`
    color: ${({ theme }) => theme.colors.blueColor};
    &:hover {
      cursor: pointer;
    }
  `;
  return (
    <StyledModal>
      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        PaperProps={{
          sx: {
            padding: "1.5em",
            borderRadius: Theme.radius.primaryBorderRadius,
            background: Theme.colors.inverseOnSurface,
          },
        }}
      >
        <StyledDashboardContentFlex column>
          <p style={title}>Delete</p>
          <p style={confirmTest}>Are you sure you want to delete this job?</p>
          <div style={flexContainer}>
            <Paragraph onClick={closeModal}>No</Paragraph>
            <Paragraph onClick={handleDeleteActionClick}>Yes</Paragraph>
          </div>
        </StyledDashboardContentFlex>
      </Dialog>
    </StyledModal>
  );
};

export default DeleteModal;
