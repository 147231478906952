import React from "react";
import { S3Connector } from "../../../index";
import APIConnector from "./APIConnector";
import CSVConnector from "./CSVConnector";
import GSC_CONNECTOR from "./GSC_CONNECTOR";
import MySQLConnector from "./MySQLConnector";
import PostgressSQLConnector from "./PostgresSQL";
import SQLServerConnector from "./SQLServerConnector";

const SelectedComponent = ({
  selectedText,
  inputData,
  S3Data,
  handleChange,
  handleS3SourceBucketChange,
  handleS3SourceBucketBlur,
  s3fileNameError,
  s3taskPartitionColumn,
  handleS3TaskPartitionColumn,
  dbDataValues,
  handleSQLServerValuesChange,
  sqlError,
  onClose,
  setQueryDBName,
  handlequeryDataBaseName,
  ingestions,
  selectedOption,
  handleSelectChange,
  optionsArray,
  sqlServerdataQueries,
  handleQueriesTableNameChange,
  queries,
  taskId,
  handleQueriesChange,
  checkboxValues,
  handleCheckboxChange,
  partitonColValue,
  handleQueryPartitionChange,
  addMoreSQLServerQueries,
  tableHead,
  tableData,
  handleDatabaseNameChange,
  databaseNameLoader,
  databaseName,
  handleSchemaNameChange,
  schemas,
  handleTableNameChange,
  tables,
  handleTableIngestModeOption,
  tableOptions,
  handleDeltaColumnChange,
  deltaColumn,
  handlePartionChange,
  handlePartitioningChange,
  partitionColumn,
  addRow,
  mySQLDataValues,
  mySQLError,
  handlemySQLServerValuesChange,
  handlemySQLqueryDataBaseName,
  mySQLselectedOption,
  handlemySQLSelectChange,
  mySQLoptionsArray,
  mySQLServerdataQueries,
  handlemySQLQueriesTableNameChange,
  mySQLqueries,
  handlemySQLQueriesChange,
  mySQLcheckboxValues,
  handlemySQLCheckboxChange,
  mySQLpartitonColValue,
  handlemySQLQueryPartitionChange,
  addMoremySQLServerQueries,
  mySQLtableHead,
  mySQLtableData,
  handlemySQLDatabaseNameChange,
  mySQLdatabaseNameLoader,
  mySQLdatabaseName,
  handlemySQLSchemaNameChange,
  mySQLschemas,
  handlemySQLTableNameChange,
  mySQLtables,
  handlemySQLTableIngestModeOption,
  mySQLtableOptions,
  handlemySQLDeltaColumnChange,
  mySQLdeltaColumn,
  handlemySQLPartionChange,
  handlemySQLPartitioningChange,
  mySQLpartitionColumn,
  mySQLaddRow,
  //
  postgresDataValues,
  handlepostgresServerValuesChange,
  postgresError,
  handlepostgresqueryDataBaseName,
  postgresselectedOption,
  handlepostgresSelectChange,
  postgresoptionsArray,
  postgresServerdataQueries,
  handlepostgresQueriesTableNameChange,
  postgresqueries,
  handlepostgresQueriesChange,
  postgrescheckboxValues,
  handlepostgresCheckboxChange,
  postgrespartitonColValue,
  handlepostgresQueryPartitionChange,
  addMorepostgresServerQueries,
  postgrestableHead,
  postgrestableData,
  handlepostgresDatabaseNameChange,
  postgresdatabaseNameLoader,
  postgresdatabaseName,
  handlepostgresSchemaNameChange,
  postgresschemas,
  handlepostgresTableNameChange,
  postgrestables,
  handlepostgresTableIngestModeOption,
  postgrestableOptions,
  handlepostgresDeltaColumnChange,
  postgresdeltaColumn,
  handlepostgresPartionChange,
  handlepostgresPartitioningChange,
  postgrespartitionColumn,
  postgresaddRow,
  //
  ApiData,
  handleAPIChange,
  apiHttp,
  httpData,
  httpvalue,
  handleHttpChange,
  renderKeyValue,
  apiparamKey,
  apihandleKeyChange,
  apiparamValue,
  apihandleValueChange,
  GCSData,
  handleGCSChange,
  handleGCSSourceBucketChange,
  handleGCSSourceBucketBlur,
  gcsfileNameError,
  gcstaskPartitionColumn,
  handleGCSTaskPartitionColumn,
}) => {
  return (
    <div>
      {selectedText === "S3" ? (
        <S3Connector
          inputData={inputData}
          S3Data={S3Data}
          handleChange={handleChange}
          handleS3SourceBucketChange={handleS3SourceBucketChange}
          handleS3SourceBucketBlur={handleS3SourceBucketBlur}
          s3fileNameError={s3fileNameError}
          s3taskPartitionColumn={s3taskPartitionColumn}
          handleS3TaskPartitionColumn={handleS3TaskPartitionColumn}
          onClose={onClose}
        />
      ) : selectedText === "MySQL" ? (
        <MySQLConnector
          inputData={inputData}
          mySQLDataValues={mySQLDataValues}
          mySQLError={mySQLError}
          handlemySQLServerValuesChange={handlemySQLServerValuesChange}
          setQueryDBName={setQueryDBName}
          handlemySQLqueryDataBaseName={handlemySQLqueryDataBaseName}
          mySQLselectedOption={mySQLselectedOption}
          handlemySQLSelectChange={handlemySQLSelectChange}
          mySQLoptionsArray={mySQLoptionsArray}
          ingestions={ingestions}
          taskId={taskId}
          mySQLServerdataQueries={mySQLServerdataQueries}
          handlemySQLQueriesTableNameChange={handlemySQLQueriesTableNameChange}
          mySQLqueries={mySQLqueries}
          handlemySQLQueriesChange={handlemySQLQueriesChange}
          mySQLcheckboxValues={mySQLcheckboxValues}
          handlemySQLCheckboxChange={handlemySQLCheckboxChange}
          mySQLpartitonColValue={mySQLpartitonColValue}
          handlemySQLQueryPartitionChange={handlemySQLQueryPartitionChange}
          addMoremySQLServerQueries={addMoremySQLServerQueries}
          mySQLtableHead={mySQLtableHead}
          mySQLtableData={mySQLtableData}
          handlemySQLDatabaseNameChange={handlemySQLDatabaseNameChange}
          mySQLdatabaseNameLoader={mySQLdatabaseNameLoader}
          mySQLdatabaseName={mySQLdatabaseName}
          handlemySQLSchemaNameChange={handlemySQLSchemaNameChange}
          mySQLschemas={mySQLschemas}
          handlemySQLTableNameChange={handlemySQLTableNameChange}
          mySQLtables={mySQLtables}
          handlemySQLTableIngestModeOption={handlemySQLTableIngestModeOption}
          mySQLtableOptions={mySQLtableOptions}
          handlemySQLDeltaColumnChange={handlemySQLDeltaColumnChange}
          mySQLdeltaColumn={mySQLdeltaColumn}
          handlemySQLPartionChange={handlemySQLPartionChange}
          handlemySQLPartitioningChange={handlemySQLPartitioningChange}
          mySQLpartitionColumn={mySQLpartitionColumn}
          mySQLaddRow={mySQLaddRow}
          onClose={onClose}
        />
      ) : selectedText === "SQL Server" ? (
        <SQLServerConnector
          inputData={inputData}
          dbDataValues={dbDataValues}
          handleSQLServerValuesChange={handleSQLServerValuesChange}
          sqlError={sqlError}
          setQueryDBName={setQueryDBName}
          handlequeryDataBaseName={handlequeryDataBaseName}
          ingestions={ingestions}
          selectedOption={selectedOption}
          handleSelectChange={handleSelectChange}
          optionsArray={optionsArray}
          sqlServerdataQueries={sqlServerdataQueries}
          handleQueriesTableNameChange={handleQueriesTableNameChange}
          queries={queries}
          taskId={taskId}
          handleQueriesChange={handleQueriesChange}
          checkboxValues={checkboxValues}
          handleCheckboxChange={handleCheckboxChange}
          partitonColValue={partitonColValue}
          handleQueryPartitionChange={handleQueryPartitionChange}
          addMoreSQLServerQueries={addMoreSQLServerQueries}
          tableHead={tableHead}
          tableData={tableData}
          handleDatabaseNameChange={handleDatabaseNameChange}
          databaseNameLoader={databaseNameLoader}
          databaseName={databaseName}
          handleSchemaNameChange={handleSchemaNameChange}
          schemas={schemas}
          handleTableNameChange={handleTableNameChange}
          tables={tables}
          handleTableIngestModeOption={handleTableIngestModeOption}
          tableOptions={tableOptions}
          handleDeltaColumnChange={handleDeltaColumnChange}
          deltaColumn={deltaColumn}
          handlePartionChange={handlePartionChange}
          handlePartitioningChange={handlePartitioningChange}
          partitionColumn={partitionColumn}
          addRow={addRow}
          onClose={onClose}
        />
      ) : selectedText === "Postgres SQL" ? (
        <PostgressSQLConnector
          inputData={inputData}
          postgresDataValues={postgresDataValues}
          handlepostgresServerValuesChange={handlepostgresServerValuesChange}
          postgresError={postgresError}
          setQueryDBName={setQueryDBName}
          handlepostgresqueryDataBaseName={handlepostgresqueryDataBaseName}
          postgresselectedOption={postgresselectedOption}
          handlepostgresSelectChange={handlepostgresSelectChange}
          postgresoptionsArray={postgresoptionsArray}
          ingestions={ingestions}
          taskId={taskId}
          postgresServerdataQueries={postgresServerdataQueries}
          handlepostgresQueriesTableNameChange={
            handlepostgresQueriesTableNameChange
          }
          postgresqueries={postgresqueries}
          handlepostgresQueriesChange={handlepostgresQueriesChange}
          postgrescheckboxValues={postgrescheckboxValues}
          handlepostgresCheckboxChange={handlepostgresCheckboxChange}
          postgrespartitonColValue={postgrespartitonColValue}
          handlepostgresQueryPartitionChange={
            handlepostgresQueryPartitionChange
          }
          addMorepostgresServerQueries={addMorepostgresServerQueries}
          postgrestableHead={postgrestableHead}
          postgrestableData={postgrestableData}
          handlepostgresDatabaseNameChange={handlepostgresDatabaseNameChange}
          postgresdatabaseNameLoader={postgresdatabaseNameLoader}
          postgresdatabaseName={postgresdatabaseName}
          handlepostgresSchemaNameChange={handlepostgresSchemaNameChange}
          postgresschemas={postgresschemas}
          handlepostgresTableNameChange={handlepostgresTableNameChange}
          postgrestables={postgrestables}
          handlepostgresTableIngestModeOption={
            handlepostgresTableIngestModeOption
          }
          postgrestableOptions={postgrestableOptions}
          handlepostgresDeltaColumnChange={handlepostgresDeltaColumnChange}
          postgresdeltaColumn={postgresdeltaColumn}
          handlepostgresPartionChange={handlepostgresPartionChange}
          handlepostgresPartitioningChange={handlepostgresPartitioningChange}
          postgrespartitionColumn={postgrespartitionColumn}
          postgresaddRow={postgresaddRow}
          onClose={onClose}
        />
      ) : selectedText === "CSV" ? (
        <CSVConnector />
      ) : selectedText === "API" ? (
        <APIConnector
          inputData={inputData}
          ApiData={ApiData}
          handleAPIChange={handleAPIChange}
          apiHttp={apiHttp}
          httpData={httpData}
          httpvalue={httpvalue}
          handleHttpChange={handleHttpChange}
          renderKeyValue={renderKeyValue}
          apiparamKey={apiparamKey}
          apihandleKeyChange={apihandleKeyChange}
          apiparamValue={apiparamValue}
          apihandleValueChange={apihandleValueChange}
          onClose={onClose}
        />
      ) : selectedText === "GCS" ? (
        <GSC_CONNECTOR
          inputData={inputData}
          GCSData={GCSData}
          handleGCSChange={handleGCSChange}
          handleGCSSourceBucketChange={handleGCSSourceBucketChange}
          handleGCSSourceBucketBlur={handleGCSSourceBucketBlur}
          gcsfileNameError={gcsfileNameError}
          gcstaskPartitionColumn={gcstaskPartitionColumn}
          handleGCSTaskPartitionColumn={handleGCSTaskPartitionColumn}
          onClose={onClose}
        />
      ) : null}
    </div>
  );
};

export default SelectedComponent;
