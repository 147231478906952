import { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import ProgressBar from "../Upload/ProgressBar";
import { toast, ToastContainer } from "react-toastify";
import {
  PiCaretDownLight,
  PiCaretRightLight,
  PiEyeLight,
  PiPlayLight,
  PiPlusBold,
  PiTrashLight,
} from "react-icons/pi";
import {
  HomeSvg,
  StyledDashboardContentFlex,
  StyledPortalContent,
  Tabs,
  Theme,
  TransformationSvg,
} from "../../index";
import "react-toastify/dist/ReactToastify.css";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-sqlserver";
import "ace-builds/src-noconflict/mode-sql";
import { StyledButton } from "../../reusableComponents/UIComponents/ButtonTonalIcon";
import SaveModal from "./SaveModal";
import ButtonFilledIcon from "../../reusableComponents/UIComponents/ButtonFilledIcon";
import ButtonOutlineIcon from "../../reusableComponents/UIComponents/ButtonOutlineIcon";
import DbSideBar from "./Components/DbSideBar";
import SQLResultsTable from "./Components/SQLResultsTable";
import {
  clearManualUploadToastError,
  clearManualUploadToastSuccess,
} from "../../redux/actions/usersActions";
import {
  HeaderTabButton,
  QueryOptionsContainer,
  QueryTab,
  SQLEditorContainer,
  SQLTransformationContent,
  TransformationResultContainer,
} from "./Styles/TransformationStyle";
import { useDispatch, useSelector } from "react-redux";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

const ace = require("ace-builds/src-noconflict/ace");

const TransformationPresenter = ({
  dbSchemaRepresentation,
  selectedSQLTab,
  SQLTab,
  setSQLTab,
  handleQueryTabSelect,
  handleUpdateQueryValue,
  handleAddNewQueryTab,
  isOpenResults,
  handleOpenResults,
  handleRunSQL,
  scriptName,
  handleScriptNameChange,
  open,
  handleClickOpen,
  handleClose,
  handleScheduleModal,
  isRunEnabled,
}) => {
  const { postManualUploadError, postManualUploadResponse } = useSelector(
    (state) => state.users,
  );
  const dispatch = useDispatch();
  const fill = Theme.colors.blueColor;
  const size = 18;
  const tabData = [
    {
      to: "/dataManagement",
      icon: <HomeSvg fill={fill} width={size} height={size} />,
      label: "Home",
    },
    {
      to: "/transformation",
      icon: <TransformationSvg fill={fill} width={size} height={size} />,
      label: "Transformation",
    },
  ];

  const [topDivHeight, setTopDivHeight] = useState(26);
  const [bottomDivHeight, setBottomDivHeight] = useState(44);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const mouseUpHandler = () => {
      setIsDragging(false);
    };

    const mouseMoveHandler = (event) => {
      if (isDragging) {
        setDivPosition(event);
      }
    };

    document.body.addEventListener("mouseup", mouseUpHandler);
    document.body.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      document.body.removeEventListener("mouseup", mouseUpHandler);
      document.body.removeEventListener("mousemove", mouseMoveHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const getDivPosition = (event) => {
    setIsDragging(true);
  };
  const setDivPosition = (event) => {
    if (isDragging) {
      const containerTop = document
        .querySelector(".container")
        .getBoundingClientRect().top;
      const mousePosition = event.clientY;

      const topDivNewHeight =
        ((mousePosition - containerTop) / window.innerHeight) * 100;
      const bottomDivNewHeight = 100 - topDivNewHeight;

      if (topDivNewHeight >= 10 && bottomDivNewHeight >= 10) {
        setTopDivHeight(topDivNewHeight);
        setBottomDivHeight(bottomDivNewHeight);
      }
    }
  };
  useEffect(() => {
    if (postManualUploadResponse.message) {
      toast.success(<ProgressBar />, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 8200,
        pauseOnFocusLoss: false,
      });
      dispatch(clearManualUploadToastSuccess());
    }
    if (postManualUploadError) {
      toast.warning("Upload failed, try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnFocusLoss: false,
      });
      dispatch(clearManualUploadToastError());
    }
  }, [dispatch, postManualUploadError, postManualUploadResponse.message]);

  const [editorKey, setEditorKey] = useState(1);
  useEffect(() => {
    setEditorKey((prev) => prev + 1);
  }, [dbSchemaRepresentation]);

  const onLoad = (editor) => {
    editor.setOptions({
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
    });

    const languageTools = ace.require("ace/ext/language_tools");

    languageTools.addCompleter({
      getCompletions: function (editor, session, pos, prefix, callback) {
        const line = editor.session.getLine(pos.row);
        const lastWord = line.substring(0, pos.column).split(" ").pop();

        Object.keys(dbSchemaRepresentation).forEach((db) => {
          const databaseMatch = lastWord.match(new RegExp(db + "\\."));
          if (databaseMatch) {
            callback(
              null,
              Object.keys(dbSchemaRepresentation[db]).map((table) => ({
                name: `${db}.${table}`,
                value: table,
                score: 1,
                meta: "table",
              })),
            );
            Object.keys(dbSchemaRepresentation[db]).forEach((table) => {
              const tableMatch = lastWord.match(
                new RegExp(db + "\\." + table + "\\."),
              );
              if (tableMatch) {
                console.log("entrou attributes\n".repeat(10));
                console.log(dbSchemaRepresentation[db][table].attributes);
                callback(
                  null,
                  dbSchemaRepresentation[db][table]["attributes"].map(
                    (attribute) => ({
                      name: `${db}.${table}.${attribute.column}`,
                      value: attribute.column,
                      score: 1,
                      meta: "attribute",
                    }),
                  ),
                );
              }
            });
          }
        });
        callback(
          null,
          Object.keys(dbSchemaRepresentation).map((db) => ({
            name: db,
            value: db,
            score: 1,
            meta: "database",
          })),
        );
      },
    });
  };
  return (
    <StyledPortalContent>
      <div
        style={{
          padding: "1em",
          width: "100%",
          height: "90vh",
          overflow: "auto",
        }}
      >
        <StyledDashboardContentFlex column>
          <StyledDashboardContentFlex justifyContent>
            <Tabs tabs={tabData} />
            <ToastContainer />
          </StyledDashboardContentFlex>
          <SQLEditorContainer>
            <DbSideBar
              dbSchemaRepresentation={dbSchemaRepresentation}
              SQLTab={SQLTab}
            />
            <SQLTransformationContent>
              <QueryOptionsContainer>
                <div className="queryOptionsDivider">
                  <ButtonFilledIcon
                    label="Run"
                    disabled={!isRunEnabled}
                    handleOnClick={() => {
                      handleRunSQL();
                    }}
                    icon={
                      <PiPlayLight
                        size={"18px"}
                        color={`${Theme.colors.whiteColor}`}
                      />
                    }
                  />
                  <ButtonOutlineIcon
                    label="Preview"
                    handleOnClick={() => {}}
                    icon={
                      <PiEyeLight
                        size={"18px"}
                        color={`${Theme.colors.blueColor}`}
                      />
                    }
                  />
                  <ButtonOutlineIcon
                    label="Discard"
                    handleOnClick={() => {}}
                    icon={
                      <PiTrashLight
                        size={"18px"}
                        color={`${Theme.colors.blueColor}`}
                      />
                    }
                  />
                </div>
                <div className="queryOptionsDivider">
                  <StyledButton onClick={handleClickOpen}>Save</StyledButton>
                  {open && (
                    <SaveModal
                      open={open}
                      handleClose={handleClose}
                      SQLTab={SQLTab}
                      setSQLTab={setSQLTab}
                      scriptName={scriptName}
                      handleScriptNameChange={handleScriptNameChange}
                      selectedSQLTab={selectedSQLTab}
                    />
                  )}
                  <ButtonOutlineIcon
                    label="Schedule"
                    disabled={!selectedSQLTab.saved}
                    handleOnClick={handleScheduleModal}
                  />
                </div>
              </QueryOptionsContainer>

              <div className="topNavigationButtons">
                <div className="queryTabsContainer">
                  {SQLTab.map((item) => {
                    return (
                      <QueryTab
                        value={item.id}
                        key={`tab_id_${item.id}`}
                        background={item === selectedSQLTab}
                        onClick={() => {
                          handleQueryTabSelect(item.id);
                        }}
                      >
                        {item.name}
                      </QueryTab>
                    );
                  })}
                  <button
                    onClick={() => {
                      handleAddNewQueryTab();
                    }}
                  >
                    <PiPlusBold
                      size={"16px"}
                      color={`${Theme.colors.blueColor}`}
                    />
                  </button>
                </div>
              </div>
              <div
                className="container"
                style={{ padding: "0", width: "100%" }}
              >
                <AceEditor
                  key={editorKey}
                  mode="sql"
                  onLoad={onLoad}
                  theme="sqlserver"
                  style={{
                    height: `${topDivHeight}vh`,
                    width: "100%",
                    border: "1px solid #ccc",
                    position: "relative",
                  }}
                  onMouseDown={(event) => getDivPosition(event)}
                  onChange={(value) => {
                    handleUpdateQueryValue(selectedSQLTab.id, value);
                  }}
                  id={`sql_input_search`}
                  name={`sql_editor_${selectedSQLTab.id}`}
                  fontSize={14}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  value={selectedSQLTab.query}
                ></AceEditor>

                <div
                  id="divider"
                  className={isDragging ? "dragging" : ""}
                  onMouseDown={(event) => getDivPosition(event)}
                ></div>
                <TransformationResultContainer
                  id="bottomDiv"
                  style={{ height: `${bottomDivHeight}vh` }}
                  onMouseDown={(event) => getDivPosition(event)}
                >
                  <div className="plusResultContainer">
                    <HeaderTabButton
                      onClick={() => {
                        handleOpenResults();
                      }}
                      selected={isOpenResults}
                    >
                      {isOpenResults ? (
                        <PiCaretDownLight size={24} />
                      ) : (
                        <PiCaretRightLight size={24} />
                      )}
                      Results
                    </HeaderTabButton>
                  </div>
                  {isOpenResults ? (
                    <>
                      <SQLResultsTable />
                    </>
                  ) : null}
                </TransformationResultContainer>
              </div>
            </SQLTransformationContent>
          </SQLEditorContainer>
        </StyledDashboardContentFlex>
      </div>
    </StyledPortalContent>
  );
};

export default TransformationPresenter;
