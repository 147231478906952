import { useState } from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";

const TreeNode = (props) => {
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (index) => {
    const newUsers = props.usersData.filter(
      (item) => item !== props.draggedItem,
    );
    const user = props.usersData.find(
      (item) => item.id === props.draggedItem.id,
    );
    const newGroups = props.groupsData;
    user.parentGroupId = newGroups[index].id;
    newGroups[index].children.push({
      ...props.draggedItem,
      parentGroupId: newGroups[index].id,
    });
    props.setUsersData(newUsers);
    props.setGroupsData(newGroups);
    props.setDraggedItem(null);

    // const payload = [...props.usersData, ...props.groupsData];
  };

  const [showChildren, setShowChildren] = useState(false);

  const handleClickParent = () => {
    setShowChildren(!showChildren);
  };

  return (
    <>
      <ListItem
        onClick={handleClickParent}
        onDrop={() => handleDrop(props.index)}
        onDragOver={handleDragOver}
      >
        <ListItemIcon style={{ minWidth: "25px" }}>
          {props.node?.children?.length > 0 && showChildren && (
            <MdOutlineExpandLess />
          )}
          {props.node?.children?.length > 0 && !showChildren && (
            <MdOutlineExpandMore />
          )}
        </ListItemIcon>
        <ListItemText>{props.node.name}</ListItemText>
      </ListItem>
      {showChildren && (
        <ShowChildren childrenTreeData={props.node?.children} {...props} />
      )}
    </>
  );
};

const ShowChildren = (props) => {
  const handleDragStart = (node) => {
    props.setDraggedItem(node);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {props.childrenTreeData?.map((node, index) => (
        <ListItem
          key={index}
          draggable
          onDragStart={() => handleDragStart(node)}
          onDragOver={handleDragOver}
          style={{ marginLeft: "30px" }}
        >
          <ListItemText>{node.name}</ListItemText>
        </ListItem>
      ))}
    </>
  );
};

const UserGroupTreeView = (props) => {
  return (
    <List>
      {props.treeData?.map((node, index) => (
        <TreeNode node={node} index={index} {...props} />
      ))}
    </List>
  );
};

export default UserGroupTreeView;
