import { useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { useGetPoolData } from "../../../clientRequest/env-variable";
import { useNavigate } from "react-router-dom";
import { postManualUploadData } from "../../redux/actions/usersActions";

const useUpload = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getPoolData = useGetPoolData();
  const [errMsg, setErrMsg] = useState("");
  const currentCustomer = useRef(null);
  const { getCustomers, getCustomersLoading, environmentVariables } =
    useSelector((state) => state.users);

  const group = localStorage.getItem("group");
  useEffect(() => {
    try {
      if (getCustomersLoading) {
        const currentUser = getCustomers.find((e) => e.customerName === group);
        currentCustomer.current = currentUser;
      }
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCustomersLoading]);
  const [files, setFiles] = useState([]);
  const [csvData, setcsvData] = useState([]);
  const errRef = useRef();

  // dropZone
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      accept: {
        "text/csv": [".csv"],
        "text/xlsx": [".xlsx"],
      }, //accept both csv and xls
      maxFilesize: 2,
      maxFiles: 3,
      maxSize: 1000 * 1024 * 1024, // 1GB
      onDrop: async (acceptedFiles) => {
        setFiles(acceptedFiles.map((file) => Object.assign(file)));
        for (const file of acceptedFiles) {
          if (file.type === "text/csv") {
            const parsedData = await new Promise((resolve) => {
              Papa.parse(file, {
                complete: (result) => {
                  resolve(result.data);
                },
                header: true,
              });
            });
            setcsvData(parsedData);
          }
        }
      },
    });

  // delete from the ui
  const handleRemove = (name) => {
    setFiles(files.filter((file) => file.name !== name));
  };
  const handlePostCSVData = (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("csv", file);
      });
      formData.append(
        "cloudProvider",
        currentCustomer.current.cloudCredential[0].cloudProvider.toLowerCase(),
      );
      formData.append(
        "ssmParameterName",
        currentCustomer.current.cloudCredential[0].ssmPathParam,
      );
      formData.append("group", group);

      const UserPool = new CognitoUserPool(getPoolData);
      const cognitoUser = UserPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            throw err;
          } else {
            if (session.isValid()) {
              const token = session.getIdToken().jwtToken;
              dispatch(
                postManualUploadData(
                  formData,
                  token,
                  environmentVariables.REACT_APP_BASE_URL_API_CORE,
                ),
              );
              navigate("/transformation");
              setErrMsg("");
            } else {
              cognitoUser.signOut();
              navigate("/");
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [
    files,
    errMsg,
    csvData,
    handleRemove,
    fileRejections,
    getRootProps,
    isDragActive,
    getInputProps,
    errRef,
    handlePostCSVData,
  ];
};

export default useUpload;
