import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import NextInsightImage from "../../images/Next insights.jpg";
import QualityRulesImage from "../../images/Quality Rules.jpg";
import SchemaAndOwnershipImage from "../../images/Schema & Ownership.jpg";
import SteeringWheel from "../../images/boat-steering.png";
import IngestDataImage from "../../images/ingest Data.jpg";

import { Flex, StyledTitle, Theme, StyledDashboardContentFlex } from "../index";
import { CardWithoutButton, RoutableCard } from "./Card";

const DashboardHome = ({ greeting, currentUser }) => {
  const { getJobsAnalytics } = useSelector((state) => state.users);
  return (
    <StyledDashboardContentFlex column gap>
      <p style={{ font: Theme.fonts.fontMediumBold }}>
        {greeting}, {currentUser}!
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          padding: "20px",
          maxWidth: "100%",
          backgroundColor: "#fff",
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
        }}
      >
        <Flex
          gap
          style={{
            alignItems: "center",
          }}
        >
          <StyledTitle>Start Sailing the Boat</StyledTitle>
          <img
            src={SteeringWheel}
            alt="boat steering wheel"
            style={{ width: 40, height: 32 }}
          />
        </Flex>
        <Grid justifyContent={"space-between"} container spacing={2}>
          <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
            <RoutableCard
              header={"Ingest Data"}
              image={IngestDataImage}
              imageTitle={"Ingest Data"}
              body={
                "Easily integrate data from various platforms into one unified system."
              }
              url={"/create-job"}
              linkTitle={"Configure Source"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
            <RoutableCard
              header={"Define Schema & Ownership"}
              image={SchemaAndOwnershipImage}
              imageTitle={"Define Schema & Ownership"}
              body={"Blueprint for Organizing and Accessing Data in Databases"}
              url={"/catalog"}
              linkTitle={"Define Schema"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
            <RoutableCard
              header={"Define Quality Rules"}
              image={QualityRulesImage}
              imageTitle={"Define Quality Rules"}
              body={
                "Check out our Pillars of Reliable and Trustworthy Data Quality"
              }
              url={"/dataQuality"}
              linkTitle={"Define Quality"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
            <RoutableCard
              header={"Predict your Next Insight"}
              image={NextInsightImage}
              imageTitle={"Predict your Next Insight"}
              body={"Insights for sustainable progress in tomorrow's world."}
              url={"/mlOps"}
              linkTitle={"Predict Insight"}
            />
          </Grid>
        </Grid>
      </div>
      <Grid justifyContent={"space-between"} container spacing={2}>
        <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
          <CardWithoutButton
            header={"Total Datasets"}
            title={"Datasets"}
            amount={"120"}
            date={"02/24/2023, 12:38pm PST"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
          <CardWithoutButton
            header={"Total Jobs"}
            title={"Jobs"}
            amount={getJobsAnalytics.total_jobs}
            date={"02/24/2023, 12:38pm PST"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
          <CardWithoutButton
            header={"Storage Space"}
            title={"Space"}
            amount={"200"}
            date={"02/24/2023, 12:38pm PST"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ p: 0, borderColor: "#ddd" }}>
          <CardWithoutButton
            header={"Data Quality Rules"}
            title={"Rules"}
            amount={"60"}
            date={"02/24/2023, 12:38pm PST"}
          />
        </Grid>
      </Grid>
    </StyledDashboardContentFlex>
  );
};

export default DashboardHome;
