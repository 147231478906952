import React, { useState } from "react";
import { ReusableFieldSet } from "./FieldSet";

const JSONFormat = ({ formattedJson, handleJsonDataChange }) => {
  const [isJsonFocused, setIsJsonFocused] = useState(false);
  return (
    <ReusableFieldSet
      title={"Json format"}
      isFocus={isJsonFocused}
      children={
        <textarea
          value={formattedJson}
          onChange={handleJsonDataChange}
          rows={4}
          cols={40}
          onFocus={() => setIsJsonFocused(true)}
          onBlur={() => setIsJsonFocused(false)}
          className={"signin_input_focus paddingClass"}
          placeholder={"JSON FORMAT"}
        />
      }
    />
  );
};

export default JSONFormat;
