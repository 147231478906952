import React, { useState } from "react";
import useCreateJobHook from "../customHook/useCreateJobHook";
import {
  StyledJobContainer,
  StyledPortalContent,
  StyledDashboardContentFlex,
  FieldSet,
  Tabs,
  Frequency,
  JobsSvg,
  WorkFlow,
  DashBoardSvg,
  StyledScroll,
  Task,
} from "../../../index";

import useTransformationScript from "../customHook/useTransformationScript";

const FormComponent = () => {
  const { transformationScript } = useTransformationScript();
  const {
    jobName,
    handleJobNameChange,
    date,
    handleDateChange,
    time,
    handleTimeChange,
    frequencyOption,
    handleFrequencyOption,
    frequencydata,
    startHourMinutes,
    handleStartHourMinutesChange,
    dailyTime,
    handleDailyTimeChange,
    selectedDays,
    handleDayToggle,
    startWeeklyTime,
    handleWeeklyStartTimeChange,
    //task
    taskData,
    handleTaskNameChange,
    handleParametersTypeChange,
    handleKeyChange,
    handleValueChange,
    formattedJson,
    handleJsonDataChange,
    handleTaskTypeChange,
    handleCodePathChange,
    handleSourceFieldChange,
    handleScriptNameChange,

    //CONNECTORS
    isConnectorModalOpen,
    onClose,
    openModal,
    closeModal,
    modalOpen,
    selectedComponent,
    selectedText,
    inputData,
    //s3
    S3Data,
    handleChange,
    handleS3SourceBucketChange,
    handleS3SourceBucketBlur,
    s3fileNameError,
    handleS3TaskPartitionColumn,
    //----------SQL SERVER ----------------------------------------------------
    dbDataValues,
    handleSQLServerValuesChange,
    sqlError,
    setQueryDBName,
    handlequeryDataBaseName,
    ingestions,
    selectedOption,
    handleSelectChange,
    optionsArray,
    sqlServerdataQueries,
    handleQueriesTableNameChange,
    queries,
    handleQueriesChange,
    checkboxValues,
    handleCheckboxChange,
    partitonColValue,
    handleQueryPartitionChange,
    addMoreSQLServerQueries,
    tableHead,
    tableData,
    handleDatabaseNameChange,
    databaseNameLoader,
    databaseName,
    handleSchemaNameChange,
    schemas,
    handleTableNameChange,
    tables,
    handleTableIngestModeOption,
    tableOptions,
    handleDeltaColumnChange,
    deltaColumn,
    handlePartionChange,
    handlePartitioningChange,
    partitionColumn,
    addRow,
    //-----------------MYSQL
    mySQLDataValues,
    handlemySQLServerValuesChange,
    mySQLError,
    handlemySQLqueryDataBaseName,
    mySQLselectedOption,
    handlemySQLSelectChange,
    mySQLoptionsArray,
    mySQLServerdataQueries,
    handlemySQLQueriesTableNameChange,
    mySQLqueries,
    handlemySQLQueriesChange,
    mySQLcheckboxValues,
    handlemySQLCheckboxChange,
    mySQLpartitonColValue,
    handlemySQLQueryPartitionChange,
    addMoremySQLServerQueries,
    mySQLtableHead,
    mySQLtableData,
    handlemySQLDatabaseNameChange,
    mySQLdatabaseNameLoader,
    mySQLdatabaseName,
    handlemySQLSchemaNameChange,
    mySQLschemas,
    handlemySQLTableNameChange,
    mySQLtables,
    handlemySQLTableIngestModeOption,
    mySQLtableOptions,
    handlemySQLDeltaColumnChange,
    mySQLdeltaColumn,
    handlemySQLPartionChange,
    handlemySQLPartitioningChange,
    mySQLpartitionColumn,
    mySQLaddRow,
    //--------postgress
    //------------------POSTGRESS
    postgresDataValues,
    handlepostgresServerValuesChange,
    postgresError,
    handlepostgresqueryDataBaseName,
    postgresselectedOption,
    handlepostgresSelectChange,
    postgresoptionsArray,
    postgresServerdataQueries,
    handlepostgresQueriesTableNameChange,
    postgresqueries,
    handlepostgresQueriesChange,
    postgrescheckboxValues,
    handlepostgresCheckboxChange,
    postgrespartitonColValue,
    handlepostgresQueryPartitionChange,
    addMorepostgresServerQueries,
    postgrestableHead,
    postgrestableData,
    handlepostgresDatabaseNameChange,
    postgresdatabaseNameLoader,
    postgresdatabaseName,
    handlepostgresSchemaNameChange,
    postgresschemas,
    handlepostgresTableNameChange,
    postgrestables,
    handlepostgresTableIngestModeOption,
    postgrestableOptions,
    handlepostgresDeltaColumnChange,
    postgresdeltaColumn,
    handlepostgresPartionChange,
    handlepostgresPartitioningChange,
    postgrespartitionColumn,
    postgresaddRow,
    //csv
    //API
    ApiData,
    handleAPIChange,
    apiHttp,
    httpData,
    httpvalue,
    handleHttpChange,
    renderKeyValue,
    apihandleKeyChange,
    apihandleValueChange,
    //-------GCS
    GCSData,
    handleGCSChange,
    handleGCSSourceBucketChange,
    gcsfileNameError,
    handleGCSSourceBucketBlur,
    handleGCSTaskPartitionColumn,
    //----------------ENDS ---------
    // handleEngineType,
    handleAddMoreTask,
    handleRemoveFromUI,
    tags,
    handleTagsChange,
    alertValue,
    handleAlertValueChange,
    emailto,
    handleEmailToChange,
    emailcc,
    handleEmailccChange,
    emailsubject,
    handleEmailSubjectChange,
    emailmessage,
    handleEmailMessageChange,
    slackURL,
    handleSlackURLChange,
    teamsURL,
    handleTeamsURLChange,
    handlePostJobs,
  } = useCreateJobHook();
  const fill = "#006399";
  const size = "18";
  const tabData = [
    {
      to: "/yedaflow",
      icon: <WorkFlow fill={fill} width={size} height={size} />,
      label: "Workflow Management",
    },
    {
      to: "/dashboard",
      icon: <DashBoardSvg fill={fill} width={size} height={size} />,
      label: "Jobs Dashboard",
    },
    {
      to: "/create-job",
      icon: <JobsSvg fill={fill} width={size} height={size} />,
      label: "Jobs",
    },
  ];
  const [isJobNameFocused, setIsJobNameFocuse] = useState(false);
  const [isStartTimeFocused, setIsStartTimeFocuse] = useState(false);

  const [isStartDateFocused, setIsStartDateFocuse] = useState(false);

  return (
    <StyledPortalContent>
      <StyledScroll>
        <StyledJobContainer>
          <StyledDashboardContentFlex column>
            <Tabs tabs={tabData} />
            <div style={{ height: "90vh" }}>
              <StyledDashboardContentFlex column>
                <FieldSet
                  legendTitle={"Job Name"}
                  type={"text"}
                  name={"JobName"}
                  id={"JobName"}
                  value={jobName}
                  onChange={handleJobNameChange}
                  isInputFocused={isJobNameFocused}
                  placeholder={"Job name"}
                  onFocus={() => setIsJobNameFocuse(true)}
                  onBlur={() => setIsJobNameFocuse(false)}
                />
                <StyledDashboardContentFlex>
                  <div style={{ width: "100%" }}>
                    <FieldSet
                      legendTitle={"Start Date*"}
                      type={"date"}
                      name={"StartDate*"}
                      id={"StartDate*"}
                      value={date}
                      onChange={handleDateChange}
                      isInputFocused={isStartDateFocused}
                      onFocus={() => setIsStartDateFocuse(true)}
                      onBlur={() => setIsStartDateFocuse(false)}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <FieldSet
                      legendTitle={"Start Time*"}
                      type={"time"}
                      name={"StartTime*"}
                      id={"StartTime*"}
                      value={time}
                      onChange={handleTimeChange}
                      isInputFocused={isStartTimeFocused}
                      onFocus={() => setIsStartTimeFocuse(true)}
                      onBlur={() => setIsStartTimeFocuse(false)}
                    />
                  </div>
                </StyledDashboardContentFlex>
                <Frequency
                  frequencyOption={frequencyOption}
                  handleFrequencyOption={handleFrequencyOption}
                  frequencydata={frequencydata}
                  startHourMinutes={startHourMinutes}
                  handleStartHourMinutesChange={handleStartHourMinutesChange}
                  dailyTime={dailyTime}
                  handleDailyTimeChange={handleDailyTimeChange}
                  selectedDays={selectedDays}
                  handleDayToggle={handleDayToggle}
                  startWeeklyTime={startWeeklyTime}
                  handleWeeklyStartTimeChange={handleWeeklyStartTimeChange}
                />
                <Task
                  taskData={taskData}
                  handleTaskNameChange={handleTaskNameChange}
                  handleParametersTypeChange={handleParametersTypeChange}
                  handleKeyChange={handleKeyChange}
                  handleValueChange={handleValueChange}
                  formattedJson={formattedJson}
                  handleJsonDataChange={handleJsonDataChange}
                  handleTaskTypeChange={handleTaskTypeChange}
                  isConnectorModalOpen={isConnectorModalOpen}
                  handleCodePathChange={handleCodePathChange}
                  handleSourceFieldChange={handleSourceFieldChange}
                  onClose={onClose}
                  openModal={openModal}
                  closeModal={closeModal}
                  modalOpen={modalOpen}
                  selectedComponent={selectedComponent}
                  selectedText={selectedText}
                  //----------------------------
                  inputData={inputData}
                  S3Data={S3Data}
                  handleChange={handleChange}
                  handleS3SourceBucketChange={handleS3SourceBucketChange}
                  handleS3SourceBucketBlur={handleS3SourceBucketBlur}
                  s3fileNameError={s3fileNameError}
                  handleS3TaskPartitionColumn={handleS3TaskPartitionColumn}
                  dbDataValues={dbDataValues}
                  handleSQLServerValuesChange={handleSQLServerValuesChange}
                  sqlError={sqlError}
                  setQueryDBName={setQueryDBName}
                  handlequeryDataBaseName={handlequeryDataBaseName}
                  ingestions={ingestions}
                  selectedOption={selectedOption}
                  handleSelectChange={handleSelectChange}
                  optionsArray={optionsArray}
                  sqlServerdataQueries={sqlServerdataQueries}
                  handleQueriesTableNameChange={handleQueriesTableNameChange}
                  queries={queries}
                  handleQueriesChange={handleQueriesChange}
                  checkboxValues={checkboxValues}
                  handleCheckboxChange={handleCheckboxChange}
                  partitonColValue={partitonColValue}
                  handleQueryPartitionChange={handleQueryPartitionChange}
                  addMoreSQLServerQueries={addMoreSQLServerQueries}
                  tableHead={tableHead}
                  tableData={tableData}
                  handleDatabaseNameChange={handleDatabaseNameChange}
                  databaseNameLoader={databaseNameLoader}
                  databaseName={databaseName}
                  handleSchemaNameChange={handleSchemaNameChange}
                  schemas={schemas}
                  handleTableNameChange={handleTableNameChange}
                  tables={tables}
                  handleTableIngestModeOption={handleTableIngestModeOption}
                  tableOptions={tableOptions}
                  handleDeltaColumnChange={handleDeltaColumnChange}
                  deltaColumn={deltaColumn}
                  handlePartionChange={handlePartionChange}
                  handlePartitioningChange={handlePartitioningChange}
                  partitionColumn={partitionColumn}
                  addRow={addRow}
                  //--------------------------------------MY SQL
                  mySQLDataValues={mySQLDataValues}
                  handlemySQLServerValuesChange={handlemySQLServerValuesChange}
                  mySQLError={mySQLError}
                  handlemySQLqueryDataBaseName={handlemySQLqueryDataBaseName}
                  mySQLselectedOption={mySQLselectedOption}
                  handlemySQLSelectChange={handlemySQLSelectChange}
                  mySQLoptionsArray={mySQLoptionsArray}
                  mySQLServerdataQueries={mySQLServerdataQueries}
                  handlemySQLQueriesTableNameChange={
                    handlemySQLQueriesTableNameChange
                  }
                  mySQLqueries={mySQLqueries}
                  handlemySQLQueriesChange={handlemySQLQueriesChange}
                  mySQLcheckboxValues={mySQLcheckboxValues}
                  handlemySQLCheckboxChange={handlemySQLCheckboxChange}
                  mySQLpartitonColValue={mySQLpartitonColValue}
                  handlemySQLQueryPartitionChange={
                    handlemySQLQueryPartitionChange
                  }
                  addMoremySQLServerQueries={addMoremySQLServerQueries}
                  mySQLtableHead={mySQLtableHead}
                  mySQLtableData={mySQLtableData}
                  handlemySQLDatabaseNameChange={handlemySQLDatabaseNameChange}
                  mySQLdatabaseNameLoader={mySQLdatabaseNameLoader}
                  mySQLdatabaseName={mySQLdatabaseName}
                  handlemySQLSchemaNameChange={handlemySQLSchemaNameChange}
                  mySQLschemas={mySQLschemas}
                  handlemySQLTableNameChange={handlemySQLTableNameChange}
                  mySQLtables={mySQLtables}
                  handlemySQLTableIngestModeOption={
                    handlemySQLTableIngestModeOption
                  }
                  mySQLtableOptions={mySQLtableOptions}
                  handlemySQLDeltaColumnChange={handlemySQLDeltaColumnChange}
                  mySQLdeltaColumn={mySQLdeltaColumn}
                  handlemySQLPartionChange={handlemySQLPartionChange}
                  handlemySQLPartitioningChange={handlemySQLPartitioningChange}
                  mySQLpartitionColumn={mySQLpartitionColumn}
                  mySQLaddRow={mySQLaddRow}
                  //----------------------------------------
                  postgresDataValues={postgresDataValues}
                  handlepostgresServerValuesChange={
                    handlepostgresServerValuesChange
                  }
                  postgresError={postgresError}
                  handlepostgresqueryDataBaseName={
                    handlepostgresqueryDataBaseName
                  }
                  postgresselectedOption={postgresselectedOption}
                  handlepostgresSelectChange={handlepostgresSelectChange}
                  postgresoptionsArray={postgresoptionsArray}
                  postgresServerdataQueries={postgresServerdataQueries}
                  handlepostgresQueriesTableNameChange={
                    handlepostgresQueriesTableNameChange
                  }
                  postgresqueries={postgresqueries}
                  handlepostgresQueriesChange={handlepostgresQueriesChange}
                  postgrescheckboxValues={postgrescheckboxValues}
                  handlepostgresCheckboxChange={handlepostgresCheckboxChange}
                  postgrespartitonColValue={postgrespartitonColValue}
                  handlepostgresQueryPartitionChange={
                    handlepostgresQueryPartitionChange
                  }
                  addMorepostgresServerQueries={addMorepostgresServerQueries}
                  postgrestableHead={postgrestableHead}
                  postgrestableData={postgrestableData}
                  handlepostgresDatabaseNameChange={
                    handlepostgresDatabaseNameChange
                  }
                  postgresdatabaseNameLoader={postgresdatabaseNameLoader}
                  postgresdatabaseName={postgresdatabaseName}
                  handlepostgresSchemaNameChange={
                    handlepostgresSchemaNameChange
                  }
                  postgresschemas={postgresschemas}
                  handlepostgresTableNameChange={handlepostgresTableNameChange}
                  postgrestables={postgrestables}
                  handlepostgresTableIngestModeOption={
                    handlepostgresTableIngestModeOption
                  }
                  postgrestableOptions={postgrestableOptions}
                  handlepostgresDeltaColumnChange={
                    handlepostgresDeltaColumnChange
                  }
                  postgresdeltaColumn={postgresdeltaColumn}
                  handlepostgresPartionChange={handlepostgresPartionChange}
                  handlepostgresPartitioningChange={
                    handlepostgresPartitioningChange
                  }
                  postgrespartitionColumn={postgrespartitionColumn}
                  postgresaddRow={postgresaddRow}
                  //---------------------------------------
                  ApiData={ApiData}
                  handleAPIChange={handleAPIChange}
                  apiHttp={apiHttp}
                  httpData={httpData}
                  httpvalue={httpvalue}
                  handleHttpChange={handleHttpChange}
                  renderKeyValue={renderKeyValue}
                  apihandleKeyChange={apihandleKeyChange}
                  apihandleValueChange={apihandleValueChange}
                  //----------------------------------------
                  GCSData={GCSData}
                  handleGCSChange={handleGCSChange}
                  handleGCSSourceBucketChange={handleGCSSourceBucketChange}
                  handleGCSSourceBucketBlur={handleGCSSourceBucketBlur}
                  gcsfileNameError={gcsfileNameError}
                  handleGCSTaskPartitionColumn={handleGCSTaskPartitionColumn}
                  //---------------------------------
                  transformationScript={transformationScript}
                  handleScriptNameChange={handleScriptNameChange}
                  handleAddMoreTask={handleAddMoreTask}
                  handleRemoveFromUI={handleRemoveFromUI}
                  tags={tags}
                  handleTagsChange={handleTagsChange}
                  alertValue={alertValue}
                  handleAlertValueChange={handleAlertValueChange}
                  emailto={emailto}
                  handleEmailToChange={handleEmailToChange}
                  emailcc={emailcc}
                  handleEmailccChange={handleEmailccChange}
                  emailsubject={emailsubject}
                  handleEmailSubjectChange={handleEmailSubjectChange}
                  emailmessage={emailmessage}
                  handleEmailMessageChange={handleEmailMessageChange}
                  slackURL={slackURL}
                  handleSlackURLChange={handleSlackURLChange}
                  teamsURL={teamsURL}
                  handleTeamsURLChange={handleTeamsURLChange}
                  handlePostJobs={handlePostJobs}
                />
              </StyledDashboardContentFlex>
            </div>
          </StyledDashboardContentFlex>
        </StyledJobContainer>
      </StyledScroll>
    </StyledPortalContent>
  );
};

export default FormComponent;
