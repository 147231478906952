import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
  @media (max-width: ${({ theme }) => theme.responsive.ipad}) {
    grid-template-columns: 1fr;
  }
`;
export const MainContainer = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
`;
export const StyledSideBar = styled.div`
  background-color: ${({ theme }) => theme.colors.refPrimary30};
  @media (max-width: ${({ theme }) => theme.responsive.ipad}) {
    visibility: hidden;
  }
`;
export const StyledDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryColor};
`;
export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.25em;
  padding: 3.5em;
  margin-inline: auto;
  height: 100%;
  width: 64%;
  @media (max-width: ${({ theme }) => theme.responsive.ipad}) {
    width: 70%;
  }
  @media (max-width: ${({ theme }) => theme.responsive.desktop}) {
    width: 80%;
  }
`;
export const StyledHeader = styled.header`
  h4 {
    font:
      normal 500 14px/20px "Poppins",
      sans-serif;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.colors.borderColor};
  }
  h2 {
    color: ${({ theme }) => theme.colors.sysSurfaceTextColor};
    font:
      normal 400 24px/32px "Poppins",
      sans-serif;
    letter-spacing: 0.1px;
  }
`;
export const Flex = styled.div`
  display: flex;
  gap: ${(props) => (props.gap ? "0.25em" : "1em")};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  flex: ${(props) => (props.equalWidth ? "1" : null)};
  justify-content: ${(props) => (props.center ? "center" : null)};
  align-items: ${(props) => (props.centerAlign ? "center" : null)};
  background-color: ${(props) => (props.bg ? "#fff" : null)};
  flex-wrap: wrap;
`;
export const StyledDashboardContentFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  gap: ${(props) => (props.gap ? "3em" : "1.5em")};
  justify-content: ${(props) =>
    props.justifyContent ? "space-between" : null};
  h4 {
    font-weight: ${({ theme }) => theme.fonts.mediumFont};
    font-size: 3vh;
  }
`;
