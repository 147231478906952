import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    :root {
      --app-container-size: 100%;
    }

    *{
        padding: 0;
        box-sizing: border-box;
        margin: 0;
    }
    body{
      -ms-overflow-style: none;
        width: 100%;
        height: 100%;
        font-family: ${({ theme }) => theme.fonts.primaryFont};
        background-color: ${({ theme }) => theme.colors.secondaryColor};
    }
    .signin_input, .error_border_red_color {
      border: 1px solid ${({ theme }) => theme.colors.primaryColor};
      opacity: 0.6;
      padding: 1.25em;
      width: 100%;
      &::placeholder {
        color: ${({ theme }) => theme.colors.placeholderText};
        font: ${({ theme }) => theme.fonts.fontSecondary};
        letter-spacing:${({ theme }) => theme.fonts.secondaryLetterSpacing};
      }
  }

  .password_update_input{
      display: flex;
      justify-content: space-between;
      border: 1px solid ${({ theme }) => theme.colors.primaryColor};
      opacity: 0.6;
      padding: 0.5em;
      border-radius: 4px;
  }
  .update_input{
    width: 100%;
    border: none;
    outline: none;
    padding: 0.75em;
    &::placeholder {
      color: ${({ theme }) => theme.colors.placeholderText};
      font:${({ theme }) => theme.fonts.fontSecondary};;
      letter-spacing: 0.5px;
    }
  }
  .error_border_red_color{
    border: 1px solid ${({ theme }) => theme.colors.errorColor};;
  }
  .error {
    color:${({ theme }) => theme.colors.errorColor};;
    font-style: italic;
    p {
      margin-top: 10px;
    }
  }
  .text{
    color: ${({ theme }) => theme.colors.teritaryTextColor};
    font-weight: bold;
    font-size: 1em;
    font-family: ${({ theme }) => theme.fonts.primaryFont};
    text-align: center;
    }
  .next_btn {
    background-color: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.secondaryColor};
    font-family: ${({ theme }) => theme.fonts.primaryFont};
    font-weight:  ${({ theme }) => theme.fonts.boldFont};
    border-radius: 10px;
    padding: 1em 2em;
    text-transform: capitalize;
    border: 0;
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    };
    &:disabled {
      background-color: ${({ theme }) => theme.colors.disabledColor};
    };
  }
  .remeberMe {
    font-family: ${({ theme }) => theme.fonts.primaryFont};
    font-weight: 400;
  }
  .forget_password_btn {
    border: 2px solid ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.primaryColor};
    font-weight:  ${({ theme }) => theme.fonts.boldFont};
    background-color: transparent;
    border-radius: 10px;
    padding: 1.25em 2em;
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
  .query_btn{
    border-radius: 5px;
  }
  .save_btn {
text-align: center;
font: ${({ theme }) => theme.fonts.fontPrimary};
      letter-spacing:${({ theme }) => theme.fonts.primaryletterSpacing};
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
    border-radius:${({ theme }) => theme.radius.secondaryBorderRadius};
    background-color: ${({ theme }) => theme.colors.blueColor};
    color: ${({ theme }) => theme.colors.whiteColor};
  }
  .login_btn {
text-align: center;
opacity: 0.38;
    background-color: ${({ theme }) =>
      theme.colors.stateLayersOnSurfaceOpacity012};
    color: ${({ theme }) => theme.colors.sysSurfaceTextColor};
    border-radius:${({ theme }) => theme.radius.secondaryBorderRadius};
    padding: 1em;
    text-transform: capitalize;
    border: 0;
    font: ${({ theme }) => theme.fonts.fontPrimary};
      letter-spacing:${({ theme }) => theme.fonts.primaryLetterSpacing};
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
  .filled-background {
    text-align: center;
    background-color: ${({ theme }) => theme.colors.blueColor};
    color: #fff;
    border-radius: ${({ theme }) => theme.radius.secondaryBorderRadius};
    padding: 1em;
    text-transform: capitalize;
    border: 0;
    font: ${({ theme }) => theme.fonts.fontPrimary};
      letter-spacing:${({ theme }) => theme.fonts.primaryLetterSpacing};
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
  .back_btn {
    padding: 1.25em 3.5em;
    text-transform: uppercase;
  }
  .modal_btn {
    width: 100%;
    padding: 1em 3em;
    font-weight: ${({ theme }) => theme.fonts.boldFont};
    text-transform: uppercase;
    border-radius: 15px;
    border: 0;
    background-color:  ${({ theme }) => theme.colors.primaryColor};
    color:  ${({ theme }) => theme.colors.secondaryColor};
    &:hover {
        opacity: 0.6;
        cursor: pointer;
    }
  }
  .upload_btn_bg {
    background-color:  ${({ theme }) => theme.colors.primaryColor};
    width: 120px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    cursor: pointer;
    padding: 0.25em;
    gap: 0.25em;
  }
  .upload_btn{
    background-color: transparent;
    border: 0;
    font-size: 1em;
    font-weight: ${({ theme }) => theme.fonts.boldFont};
    cursor: pointer;
    color: ${({ theme }) => theme.colors.secondaryColor};
  }
  .upload_icon {
    font-size: 2em;
    color: ${({ theme }) => theme.colors.secondaryColor};
  }
.container {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-top: 5em;
}

.center {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.loading_text{
  font-size: 2vh;
    font-weight: 400;
    text-align: center;
}
table, td, th {
  border: 1px solid #ddd;
  text-align: left;
}
    .tableContainer{
      overflow-x: scroll;
    }
    table {
    border-collapse: collapse;
    border-spacing: 0;
    /* table-layout: fixed; */
    width: 100%;
    border: 1px solid #ddd;
}

th, td {
  text-align: left;
  padding: 8px;
  word-wrap: break-word;
}
.edit_input {
  outline: none;
  border: none;
  font-size: 1em;
  &:focus {
    outline: none;
  }
}
.storage_icon {
  color: #FFBB28;
  font-size: 2em;
}
.dataset_icon {
  font-size: 2em;
}
.total_storage_icon {
  color: #00C49F;
  font-size: 2em;
}
.upload_input {
  display: none;
}
.search_full_width {
  width:100%;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  @media (max-width: ${({ theme }) => theme.responsive.ipad}) {
    grid-template-columns: 1fr;
  }
}
.image{
  width: 15%;
  height: 15%;
  float: right;
}
.logo_img{
  max-width: 80%;
  height: 30%;
  padding-top: 20px;
}
.env_label{
  display: flex;
  gap: 10px;
}
.error {
    font-size: 1.5vh;
    color:${({ theme }) => theme.colors.errorColor};;
    font-style: italic;
  }
  .right_float{
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }
  .style_select{
    width: 100%;
    padding: 0.5em 1em;
  }
  .scroll {
    height: 600px;
    overflow-x: auto;
  }

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.selectClass{
  width: 45%;
  padding: 0.75em;
  border: 1px solid black;
  &::placeholder{
    font: ${({ theme }) => theme.fonts.smallFont}
  }
}
.margin{
  width:68%;
  margin-left: 4.5em;
}
.jobinput{
  padding: 0.75em 1em;
}
.table_input{
  width: 100%;
  border: none;
    &:focus {
      outline: none;
    }
}
.full_width {
    width: 100%;
    display: flex;
    gap: 1em;
    background-color: transparent;
    padding: 0.5em 1em;
    border: 1px solid black;
  }
  .input_schema {
    width: 100%;
    border-radius: 5px;
    border: none;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-style: italic;
    }
  }
  .modify_container {
    display: flex;
    justify-content: space-around;
    .modify_link{
      color: black;
      &:hover{
        opacity: 0.6;
        box-sizing: ${({ theme }) => theme.boxShadow.primary_boxShadow};
      }
    }
    .delete{
      color: red
    }
  }
  .info-icon, .icon_container{
      &:hover{
        cursor: pointer;
      }
  }
  .hover-info-container{
    background-color:${({ theme }) => theme.colors.primaryColor};
    border-radius: 8px;
    margin: auto;
    padding: 2em;
    height: fit-content;
  }
  .pagination {
    display: flex;
    gap: 0.75em;
    color:  ${({ theme }) => theme.colors.blueColor};
text-align: center;
cursor: pointer;
font:  ${({ theme }) => theme.fonts.fontPrimary};
letter-spacing: ${({ theme }) => theme.fonts.primaryLetterSpacing};
  }
  .page-item {
    list-style: none;
  }
  .page-link {
    padding: 8px 12px;
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    border-radius:${({ theme }) => theme.radius.secondaryBorderRadius};
    background-color: #fff;
    text-decoration: none;
    transition: background-color 0.2s ease;
  }
  .page-link:hover {
    opacity: 0.6;
  }
  .active .page-link {
    background: ${({ theme }) => theme.colors.blueColor};
    color: #fff;
  }
  .user_name {
    font-weight: ${({ theme }) => theme.fonts.boldFont};
    font-size: 2.5vh;
  }
  input, select{
    background-color: transparent;
  }
 .userEmail{
    color:${({ theme }) => theme.colors.sysSurfaceContainer};
    font: ${({ theme }) => theme.fonts.smallFont};
    line-height: 3em;
    letter-spacing: 0.25px;
 }
 .userEmail::first-letter{
    text-transform: uppercase;
 }
 .forget_password{
  color: ${({ theme }) => theme.colors.blueColor};
  text-align: center;
  font:${({ theme }) => theme.fonts.fontPrimary};
  letter-spacing: ${({ theme }) => theme.fonts.primaryLetterSpacing};
    &:hover{
      cursor: pointer;
  }
 }
 .input_fieldset {
    border:2.5px solid ${({ theme }) => theme.colors.blueColor};
    color:${({ theme }) => theme.colors.blueColor};
    border-radius: 4px;
 }
 .input_fieldset_legend {
    color:${({ theme }) => theme.colors.blueColor};
    padding: 0 6px;
    margin-left:1em;
  font:
      normal 400 12px/16px 'Poppins',
      sans-serif;
      letter-spacing: 0.4px;
 }
 .input_fieldset_not_focused {
    border: 1px solid ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.placeholderText};
    border-radius: 4px;
 }
 .input_fieldset_legend_not_focused {
    color: ${({ theme }) => theme.colors.placeholderText};
    padding: 0 6px;
    margin-left:1em;
  font:
      normal 400 12px/16px 'Poppins',
      sans-serif;
      letter-spacing: 0.4px;
 }
 .signin_input_focus{
    width: 100%;
    outline: none;
    border: none;
    padding: 0.75em;
    caret-color:${({ theme }) => theme.colors.blueColor};
    &:focus {
      outline: none;
    }
 }
.paddingClass{
    padding: 1.25em;
    width: 100%;
    border: none;
    outline: none;
    caret-color:${({ theme }) => theme.colors.blueColor};
    &:focus {
      outline: none;
    }
}
.password_recovery {
    color:${({ theme }) => theme.colors.sysSurfaceTextColor};
    font:
      normal 400 24px/32px 'Poppins',
      sans-serif;
}
.sourceStyle{
    width: 100%;
    border: none;
    outline: none;
    &:focus {
      outline: none;
    }
}
.ace_cursor {
    box-shadow: none !important;
}
.ace_gutter-layer  { //line-count
  background-color: #D6F6FF;
  font: "normal 400 16px/24px 'Poppins',sans-serif"; 
  padding: 10px 5px 0px 0px;
  text-align: right;
}
#sql_input_search {
	width: 100%;
	height: 50%;
}

#bottomDiv {
	width: 100%;
	height: 50%;
}
#divider {
	width: 100%;
	cursor: row-resize;
	height: 2px;
	background-color: #000;
}
`;
export default GlobalStyles;
