/* eslint-disable no-unused-vars */
import { useState } from "react";

import { axiosGetTable } from "../../../../clientRequest/axiosRequest";
import { fetchDatabaseName } from "../../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";

const useSQL = (token, taskData, setTaskData) => {
  const dispatch = useDispatch();
  const { environmentVariables } = useSelector((state) => state.users);
  const baseURL = `${environmentVariables.REACT_APP_TABLE_API}/yeda/database-metadata/mssql`;
  const [mySQLselectedOption, setmySQLSelectedOption] = useState("");
  const [mySQLqueries, setmySQLQueries] = useState([]);
  const [mySQLcheckboxValues, setmySQLCheckboxValues] = useState([false]);
  const [mySQLParams, setmySQLParamsValue] = useState([
    {
      host: "",
      port: "",
      user: "",
      password: "",
      id: 1,
    },
  ]);
  const mySQLDataValues = [
    {
      id: 1,
      name: "host",
      title: "Host",
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 2,
      name: "port",
      title: "Port",
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 3,
      name: "user",
      title: "User",
      CheckBox: false,
      defaultValue: "",
      required: true,
    },
    {
      id: 4,
      name: "password",
      title: "Password",
      CheckBox: false,
      password: true,
      defaultValue: "",
      required: true,
    },
  ];
  const group = localStorage.getItem("group");
  const mySQLoptionsArray = [
    {
      id: 1,
      options: "",
      title: "Select",
    },
    {
      id: 2,
      options: "query",
      title: "Query",
    },
    {
      id: 3,
      options: "tables",
      title: "Table(s)",
    },
  ];
  const handlemySQLServerValuesChange = (event, id) => {
    const { name, value } = event.target;
    mySQLParams.map((data) => {
      if (data.id === id) {
        switch (name) {
          case "host":
            data.host = value;
            break;
          case "port":
            data.port = value;
            break;
          case "user":
            data.user = value;
            break;
          case "password":
            data.password = value;
            break;
          default:
            console.log("error");
        }
      }
      return data;
    });
    const currentmySQLServerInput = mySQLParams.find(
      (input) => input.id === id,
    );
    taskData.map((data) => {
      if (data.id === id) {
        data.params.input_db_host = currentmySQLServerInput.host;
        data.params.input_db_port = currentmySQLServerInput.port;
        data.params.input_db_user = currentmySQLServerInput.user;
        data.params.input_db_pass = currentmySQLServerInput.password;
        data.params.destination_bucket = `databoat-raw-${group}`;
        data.source_type = "mysql";
      }
      return data;
    });
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const handlemySQLqueryDataBaseName = (event, id) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === id) {
        data.params.query_db_name = selected;
      }
      return data;
    });
    setTaskData(updatedData);
  };
  const [mySQLError, setmySQLError] = useState("");

  const handlemySQLSelectChange = (event, taskId) => {
    const selected = event.target.value;
    const updatedData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.selected = selected;
      }
      return data;
    });

    setTaskData(updatedData);
    setmySQLSelectedOption(selected);
    const currentDbParam = mySQLParams.find((data) => data.id === taskId);
    const validateForm = () => {
      const errors = [];
      if (!currentDbParam.host.trim()) {
        errors.host = "Host is required";
      }
      if (!currentDbParam.user.trim()) {
        errors.user = "User is required";
      }
      if (!currentDbParam.password.trim()) {
        errors.password = "Password is required";
      }

      setmySQLError(errors);
      return Object.keys(errors).length === 0;
    };
    if (validateForm()) {
      setmySQLError("");
      if (selected === "tables") {
        const formData = {
          user: currentDbParam.user,
          password: currentDbParam.password,
          server: currentDbParam.host,
        };
        dispatch(fetchDatabaseName(formData, baseURL, token));
      }
    } else {
      setmySQLError("Please fill all required fields");
    }
  };

  const [mySQLServerdataQueries, setmySQLServerDataQueries] = useState(() => [
    {
      id: 1,
      query: null,
      table_name: null,
      partitioning: false,
      partition_columns: null,
    },
  ]);

  // Function to handle adding more rows
  const addMoremySQLServerQueries = (id) => {
    setmySQLServerDataQueries((previousQueryData) => {
      return [
        ...previousQueryData,
        {
          id: previousQueryData.length + 1,
          query: null,
          table_name: null,
          partitioning: false,
          partition_columns: null,
        },
      ];
    });
  };
  const handlemySQLQueriesTableNameChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = mySQLServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          table_name: selected,
        };
      }
      return data;
    });

    setmySQLServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          item.table_name = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handlemySQLQueriesChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = mySQLServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          query: selected,
        };
      }
      return data;
    });

    setmySQLServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          item.query = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };

  const handlemySQLCheckboxChange = (event, id, taskId) => {
    let selected = event.target.value;
    if (selected === "on") {
      selected = true;
    } else {
      selected = false;
    }
    const updatedData = mySQLServerdataQueries.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          partitioning: selected,
        };
      }
      return data;
    });
    setmySQLServerDataQueries(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          item.partitioning = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handlemySQLQueryPartitionChange = (event, id, taskId) => {
    const selected = event.target.value.split(",");
    const updatedQuery = mySQLServerdataQueries.map((data) => {
      if (data.id === id) {
        data.partition_columns = selected;
      }
      return data;
    });
    setmySQLServerDataQueries(updatedQuery);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.queries.map((item) => {
          item.partition_columns = selected;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  //-----------------------------------------SQLSERVER TABLES
  const [mySQLisChecked, setmySQLIsChecked] = useState();
  const [mySQLloadType, setmySQLLoadType] = useState("");
  const mySQLdatabase = useSelector((state) => state.users.databaseName);
  const mySQLdatabaseNameLoader = useSelector(
    (state) => state.users.databaseNameLoader,
  );
  let mySQLdatabase_name = [];
  const mySQLtableHead = [
    { id: 1, title: "Database Name" },
    { id: 2, title: "Schema" },
    { id: 3, title: "Table" },
    { id: 4, title: "Ingestion Mode" },
    mySQLloadType === "delta" && { id: 5, title: "Delta_Column" },
    { id: 6, title: "Partition" },
    mySQLisChecked && { id: 7, title: "Partition_Column" },
  ].filter(Boolean);
  const [mySQLtableData, setmySQLTableData] = useState(() => [
    {
      id: 1,
      input_db_name: "",
      input_db_schema: "",
      input_table_name: "",
      load_type: "",
      partitioning: false,
      isDelta: false,
      delta_column: "",
      partition_columns: "",
      tableSchemasLoader: false,
      listTablesLoader: false,
      deltaColumnDataLoader: false,
      partitionColumnDataLoader: false,
    },
  ]);

  // Function to handle adding more rows
  const mySQLaddRow = () => {
    setmySQLTableData((previousTableData) => {
      return [
        ...previousTableData,
        {
          id: previousTableData.length + 1,
          input_db_name: "",
          input_db_schema: "",
          input_table_name: "",
          load_type: "",
          partitioning: false,
          isDelta: false,
          delta_column: "",
          partition_columns: "",
          tableSchemasLoader: false,
          listTablesLoader: false,
          deltaColumnDataLoader: false,
          partitionColumnDataLoader: false,
        },
      ];
    });
    setmySQLSchemas((previousSchemaData) => {
      return [
        ...previousSchemaData,
        {
          id: previousSchemaData.length + 1,
          schema_names: [],
        },
      ];
    });
    setmySQLTables((previousTableNameData) => {
      return [
        ...previousTableNameData,
        {
          id: previousTableNameData.length + 1,
          table_names: [],
        },
      ];
    });
    setmySQLDeltaColumn((previousdeltaData) => {
      return [
        ...previousdeltaData,
        {
          id: previousdeltaData.length + 1,
          COLUMN_NAMES: [],
        },
      ];
    });
    setmySQLPartitionColumn((previousPartitioneData) => {
      return [
        ...previousPartitioneData,
        {
          id: previousPartitioneData.length + 1,
          COLUMN_NAMES: [],
        },
      ];
    });
  };
  const mySQLtableOptions = [
    { id: 1, option: "", title: "Select" },
    { id: 2, option: "full", title: "Full" },
    { id: 3, option: "delta", title: "Delta" },
  ];
  const [mySQLschemas, setmySQLSchemas] = useState(() => [
    {
      id: 1,
      schema_names: [],
    },
  ]);
  const [mySQLtables, setmySQLTables] = useState(() => [
    {
      id: 1,
      table_names: [],
    },
  ]);
  const [mySQLdeltaColumn, setmySQLDeltaColumn] = useState(() => [
    {
      id: 1,
      COLUMN_NAMES: [],
    },
  ]);
  const [mySQLpartitionColumn, setmySQLPartitionColumn] = useState(() => [
    {
      id: 1,
      COLUMN_NAMES: [],
    },
  ]);

  if (mySQLdatabase.length > 0 && mySQLdatabase[0].length > 0) {
    const data = mySQLdatabase[0].map((item) => {
      return item;
    });
    mySQLdatabase_name = data;
  }
  const mySQLdatabaseName = [
    { value: "", label: "Select" },
    ...mySQLdatabase_name.map((item) => {
      return {
        value: item.database_name,
        label: item.database_name,
      };
    }),
  ];

  const handlemySQLDatabaseNameChange = async (event, id, taskId) => {
    const selected = event.target.value;
    if (selected) {
      const currentDbParam = mySQLParams.find((data) => data.id === taskId);
      const formData = {
        user: currentDbParam.user,
        password: currentDbParam.password,
        server: currentDbParam.host,
        database: selected,
      };
      await fetchmySQLSchemas(selected, id, formData);
    }
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.input_db_name = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handlemySQLSchemaNameChange = async (event, id, taskId) => {
    const selected = event.target.value;
    if (selected) {
      const currentDbParam = mySQLParams.find((data) => data.id === taskId);
      await fetchmySQLTableName(
        selected,
        id,
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
      );
    }
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.input_db_schema = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };

  const handlemySQLTableNameChange = (event, id, taskId) => {
    const selected = event.target.value;
    const updatedData = mySQLtableData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          input_table_name: selected,
        };
      }
      return data;
    });
    setmySQLTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.input_table_name = selected;
          return item;
        });
      }
      return data;
    });

    setTaskData(updatedTaskData);
  };
  const handlemySQLTableIngestModeOption = async (event, id, taskId) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      const currentDbParam = mySQLParams.find((data) => data.id === taskId);
      await fetchmySQLDeltaColumn(
        selectedValue,
        id,
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
      );
    }
    setmySQLLoadType(selectedValue);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.load_type = selectedValue;
          item.isDelta = selectedValue === "delta";
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handlemySQLDeltaColumnChange = (event, id, taskId) => {
    const updatedData = mySQLtableData.map((data) => {
      if (data.id === id) {
        return { ...data, delta_column: event.target.value };
      }
      return data;
    });
    setmySQLTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.delta_column = event.target.value;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };
  const handlemySQLPartionChange = async (event, id, taskId) => {
    let selected = event.target.value;
    if (selected === "on") {
      selected = true;
    }
    if (selected) {
      const currentDbParam = mySQLParams.find((data) => data.id === taskId);
      await fetchmySQLPartitionColumn(
        selected,
        id,
        currentDbParam.user,
        currentDbParam.password,
        currentDbParam.host,
      );
    }
    setmySQLIsChecked(selected);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.partitioning = selected;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };

  const handlemySQLPartitioningChange = (options, id, taskId) => {
    const partion_column = options.map((value) => value.value);
    const updatedData = mySQLtableData.map((data) => {
      if (data.id === id) {
        return { ...data, partition_columns: partion_column };
      }
      return data;
    });
    setmySQLTableData(updatedData);
    const updatedTaskData = taskData.map((data) => {
      if (data.id === taskId) {
        data.params.tables.map((item) => {
          item.partition_columns = partion_column;
          return item;
        });
      }
      return data;
    });
    setTaskData(updatedTaskData);
  };

  const fetchmySQLSchemas = async (name, id, formData) => {
    const response = await axiosGetTable(baseURL).post(
      "/list-schemas",
      formData,
      { headers },
    );

    const updatedData = mySQLtableData.map((data) => {
      if (data.id === id)
        return { ...data, input_db_name: name, tableSchemasLoader: true };
      return data;
    });
    setmySQLTableData(updatedData);

    const updatedSchema = mySQLschemas.map((schemas) => {
      if (schemas.id === id) return { ...schemas, schema_names: response.data };
      return schemas;
    });
    setmySQLSchemas(updatedSchema);
  };
  const fetchmySQLTableName = async (
    selectedSchemaName,
    id,
    user,
    password,
    host,
  ) => {
    let selectedDbName = "";

    mySQLtableData.map((data) => {
      if (data.id === id) {
        selectedDbName = data.input_db_name;
      }
      return data;
    });
    const formData = {
      user: user,
      password: password,
      server: host,
      database: selectedDbName,
      schema: selectedSchemaName,
    };
    const response = await axiosGetTable(baseURL).post(
      "/list-tables",
      formData,
      { headers },
    );
    const updatedData = mySQLtableData.map((data) => {
      if (data.id === id)
        return {
          ...data,
          input_db_schema: selectedSchemaName,
          listTablesLoader: true,
        };
      return data;
    });

    setmySQLTableData(updatedData);
    const updatedTable = mySQLtables.map((tables) => {
      if (tables.id === id) return { ...tables, table_names: response.data };
      return tables;
    });

    setmySQLTables(updatedTable);
  };
  const fetchmySQLDeltaColumn = async (
    selectedValue,
    id,
    user,
    password,
    host,
  ) => {
    let selectedDbName = "";
    let selectedSchemaName = "";
    let selectedTableName = "";

    mySQLtableData.map((data) => {
      if (data.id === id) {
        selectedDbName = data.input_db_name;
        selectedSchemaName = data.input_db_schema;
        selectedTableName = data.input_table_name;
      }
      return data;
    });
    const formData = {
      user: user,
      password: password,
      server: host,
      database: selectedDbName,
      schema: selectedSchemaName,
      table: selectedTableName,
    };
    const response = await axiosGetTable(baseURL).post(
      "/list-columns-date",
      formData,
      { headers },
    );
    const updatedData = mySQLtableData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          load_type: selectedValue,
          isDelta: selectedValue === "delta",
          deltaColumnDataLoader: true,
        };
      }
      return data;
    });
    setmySQLTableData(updatedData);
    const updatedDeltaColumn = mySQLdeltaColumn.map((columns) => {
      if (columns.id === id) return { ...columns, COLUMN_NAMES: response.data };
      return columns;
    });
    setmySQLDeltaColumn(updatedDeltaColumn);
  };
  const fetchmySQLPartitionColumn = async (
    selected,
    id,
    user,
    password,
    host,
  ) => {
    let selectedDbName = "";
    let selectedSchemaName = "";
    let selectedTableName = "";

    mySQLtableData.map((data) => {
      if (data.id === id) {
        selectedDbName = data.input_db_name;
        selectedSchemaName = data.input_db_schema;
        selectedTableName = data.input_table_name;
      }
      return data;
    });
    const formData = {
      user: user,
      password: password,
      server: host,
      database: selectedDbName,
      schema: selectedSchemaName,
      table: selectedTableName,
    };
    const response = await axiosGetTable(baseURL).post(
      "/list-columns",
      formData,
      { headers },
    );
    const updatedData = mySQLtableData.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          partitioning: selected,
          partitionColumnDataLoader: true,
        };
      }
      return data;
    });
    setmySQLTableData(updatedData);

    const updatedPartitonColumn = mySQLpartitionColumn.map((columns) => {
      if (columns.id === id) return { ...columns, COLUMN_NAMES: response.data };
      return columns;
    });
    setmySQLPartitionColumn(updatedPartitonColumn);
  };

  const [mySQLpartitonColValue, setmySQLpartitonColValue] = useState("");
  //------------------------ends

  return [
    mySQLDataValues,
    setmySQLParamsValue,
    handlemySQLServerValuesChange,
    mySQLError,
    handlemySQLqueryDataBaseName,
    mySQLselectedOption,
    handlemySQLSelectChange,
    mySQLoptionsArray,
    mySQLServerdataQueries,
    handlemySQLQueriesTableNameChange,
    mySQLqueries,
    handlemySQLQueriesChange,
    mySQLcheckboxValues,
    handlemySQLCheckboxChange,
    mySQLpartitonColValue,
    handlemySQLQueryPartitionChange,
    addMoremySQLServerQueries,
    mySQLtableHead,
    mySQLtableData,
    handlemySQLDatabaseNameChange,
    mySQLdatabaseNameLoader,
    mySQLdatabaseName,
    handlemySQLSchemaNameChange,
    mySQLschemas,
    handlemySQLTableNameChange,
    mySQLtables,
    handlemySQLTableIngestModeOption,
    mySQLtableOptions,
    handlemySQLDeltaColumnChange,
    mySQLdeltaColumn,
    handlemySQLPartionChange,
    handlemySQLPartitioningChange,
    mySQLpartitionColumn,
    mySQLaddRow,
  ];
};

export default useSQL;
