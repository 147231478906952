import { Link } from "react-router-dom";
import styled from "styled-components";
import Theme from "../../../Theme";

export const SQLEditorContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* width: 79vw; */
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  box-sizing: border-box;
  background: ${Theme.colors.secondaryColor};
  gap: 24px;
`;

export const QueryOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: ${Theme.colors.secondaryColor};
  .queryOptionsDivider {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`;

//containers
const defaultHeight = "700px";
export const DataBaseSideBar = styled.section`
  width: 360px;
  width: ${(props) => (props.isOpen ? "360px" : "72px")};
  height: 700px;
  gap: 24px;
  height: ${defaultHeight};
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  background: ${Theme.colors.secondaryColor};
  border-radius: 28px;
  .dataBaseOpenSideBarHeader {
    display: flex;
    padding: 8px 8px 0px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 28px 28px 0px 0px;
    border-bottom: 1px solid ${Theme.colors.transparentBlack};
    background: ${Theme.colors.secondaryColor};
  }
  .dataBaseCloseSideBarContainer {
    display: flex;
    padding: 44px 0px 56px 0px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  .dataBaseCloseSideBarOptions {
    display: flex;
    padding: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
  .dataBaseListContainer {
    display: flex;
    padding: 24px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    overflow-y: scroll;
  }
  .dataBaseOptionsFilter {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }
`;

export const HeaderTabButton = styled.button`
  display: flex;
  height: 48px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  color: ${Theme.colors.sysSurfaceTextColor};
  text-align: center;
  font: ${Theme.fonts.fontPrimary};
  background: ${Theme.colors.secondaryColor};
  border: none;
  border-bottom: 3px solid
    ${({ selected }) =>
      selected ? Theme.colors.blueColor : Theme.colors.secondaryColor};
  cursor: pointer;
`;

export const DataBaseListContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  max-height: 528px;
  overflow: auto;
`;

export const DataBaseListButton = styled.button`
  display: flex;
  height: 48px;
  padding: 8px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  color: ${Theme.colors.sysSurfaceTextColor};
  border: none;
  background: ${Theme.colors.secondaryColor};
  font: ${Theme.fonts.fontPrimary};
`;

export const RoundAddButton = styled.button`
  display: flex;
  width: 48px;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: none;
  background: ${Theme.colors.blueColor};
  cursor: pointer;
`;

export const SQLTransformationContent = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 360px);
  justify-content: flex-start;
  align-items: flex-start;
  .buttonsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1rem;
    gap: 15px;
  }
  .tableSearchMechanism {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    text-align: left;
    height: 35px;
    input {
      height: 25px;
    }
    .inputIconSearch {
      position: relative;
      width: 100%;
      .tableSearchIcon {
        position: absolute;
        right: -5px;
        height: 100%;
        width: 30px;
        color: ${Theme.colors.teritaryTextColor};
      }
      .tableSearchIcon:hover {
        color: ${Theme.colors.secondaryElementColor};
        transition: 0.2s;
      }
    }
  }
  .topNavigationButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  .queryTabsContainer {
    display: block flex;
    overflow: auto;
    scrollbar-width: thin;
    height: 48px;
    padding: 8px 0px 0px 0px;
  }
`;

export const TransformationResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 8px;
  gap: 16px;
  .plusResultContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .resultsInfoActionsContainer {
    display: flex;
    flex-direction: row;
    height: 48px;
    padding-left: 0px;
    padding-right: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .resultsInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    color: ${Theme.colors.sysSurfaceTextColor};
    font: ${Theme.fonts.fontSecondary};
    letter-spacing: ${Theme.colors.secondaryLetterSpacing};
  }
  .resultsAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
`;

export const QueryTab = styled.div`
  padding: 14px 16px;
  box-shadow: ${Theme.boxShadow.secondary_boxShadow};
  background: ${(props) =>
    !props.background
      ? Theme.colors.secondaryColor
      : Theme.colors.stateLayersOnSurfaceOpacity008};
  color: ${Theme.colors.sysSurfaceTextColor};
  font: ${Theme.fonts.fontPrimary};
  cursor: pointer;
  letter-spacing: ${Theme.colors.primaryLetterSpacing};
`;

export const QueryBox = styled.textarea`
  box-sizing: border-box;
  color: ${Theme.colors.primaryColor};
  font: ${Theme.fonts.fontSecondary};
  letter-spacing: ${Theme.colors.secondaryLetterSpacing};
  resize: none;
  width: 100%;
  height: 350px;
  padding: 10px 10px 10px 60px;
  border: none;
  border-radius: 0px 0px 0px 28px;
  box-shadow: ${Theme.boxShadow.primary_boxShadow};
  &:focus {
    outline: none;
  }
  height: 608px;
`;

export const LineCount = styled.div`
  background: ${Theme.colors.lightBlue};
  color: ${Theme.colors.sysSurfaceTextColor};
  height: 350px;
  overflow: hidden;
  font: ${Theme.fonts.fontSecondary};
  letter-spacing: ${Theme.colors.secondaryLetterSpacing};
  padding: 8px;
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 8px;
  white-space: pre-line;
  border-radius: 0px 0px 0px 28px;
  border-right: 1px solid ${Theme.colors.transparentBlack};
  position: absolute;
  height: 608px;
  width: 60px;
`;

export const ResultsTableContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  .resultsInfoActionsContainer {
    display: flex;
    flex-direction: row;
    padding-left: 0px;
    padding-right: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .resultsInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    color: ${Theme.colors.sysSurfaceTextColor};
    font: ${Theme.fonts.fontSecondary};
    letter-spacing: ${Theme.colors.secondaryLetterSpacing};
  }
  .resultsAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
  .jobText {
    color: ${Theme.colors.blueColor};
    font: ${Theme.fonts.fontPrimary};
    letter-spacing: ${Theme.colors.primaryLetterSpacing};
  }
`;

export const ScheduleModalJobsContainer = styled.div`
  max-height: 300px;
  overflow: auto;
`;
export const StyledScriptNameLink = styled(Link)`
  color: rgb(0, 99, 153);
  padding: 0 1em;
  font: ${Theme.fonts.fontPrimary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
