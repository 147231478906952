import React from "react";
import { StyledFilteredBoxContainer } from "../styles/FilteredBox.styled";
import { DownPaddedArrow } from "./svg/UseSvg";

const FilteredBox = ({ text, handleSorting }) => {
  return (
    <StyledFilteredBoxContainer>
      <div className="text">{text}</div>
      <DownPaddedArrow onClick={handleSorting} />
    </StyledFilteredBoxContainer>
  );
};

export default FilteredBox;
