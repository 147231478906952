import React, { useState } from "react";
import { ConnectorScreens } from "../../../index";

const S3Connector = ({
  inputData,
  S3Data,
  handleChange,
  handleS3SourceBucketChange,
  handleS3SourceBucketBlur,
  s3fileNameError,
  s3taskPartitionColumn,
  handleS3TaskPartitionColumn,
  onClose,
}) => {
  const useTaskPartition = useState(true);
  const useSourceBucket = useState(true);
  return (
    <ConnectorScreens
      HeaderText={"Fill attributes required for S3 connection"}
      inputData={inputData}
      data={S3Data}
      handleChange={handleChange}
      useSourceBucket={useSourceBucket}
      formatType={"Required Format: bucket-name/file"}
      sourceBucketFormat={"s3://"}
      handleSourceBucketChange={handleS3SourceBucketChange}
      handleSourceBucketBlur={handleS3SourceBucketBlur}
      sourceBucketError={s3fileNameError}
      sourceBucketPlaceholder={"e.g: bucket-name/file"}
      useTaskPartition={useTaskPartition}
      taskPartitionColumn={s3taskPartitionColumn}
      handleTaskPartitionColumn={handleS3TaskPartitionColumn}
      btnOnClick={onClose}
      buttonText={"Save"}
    />
  );
};

export default S3Connector;
