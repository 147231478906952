import React, { useState } from "react";
import { BsFillPersonDashFill, BsPersonFillAdd } from "react-icons/bs";
import { Grid, ListItemText, ListItem } from "@mui/material";
import styled from "styled-components";

import { Container, List, Box } from "../../styles/DragDrop.Styled";
import UserGroupForm from "./UserGroupForm";
import UserGroupDeleteForm from "./UserGroupDeleteForm";
import UserGroupTreeView from "./UserGroupTreeView";

const IconButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 3px;
  cursor: pointer;
`;

const UserGroupManagement = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [callUserGroup, setCallUserGroup] = useState(false);

  const handleDragStart = (index) => {
    setDraggedItem(props.usersData[index]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = () => {
    let group = props.userGroupData.find(
      (e) => e.id === draggedItem.parentGroupId,
    );
    let newUsers = props.usersData.filter((e) => e.id !== draggedItem.id);
    let newGroups = props.groupsData.filter((e) => e.id !== group?.id);
    if (group && draggedItem) {
      group.children = group.children.filter((c) => c.id !== draggedItem.id);
      draggedItem.parentGroupId = null;
      newGroups.push(group);
      newUsers.push(draggedItem);
    }
    props.setUsersData(newUsers);
    props.setGroupsData(newGroups);
    setDraggedItem(null);

    // const payload = [...props.usersData, ...props.groupsData];
  };

  const handleAddUser = () => {
    setOpenModal(true);
  };

  const handleDeleteUser = () => {
    setOpenDeleteModal(true);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item>
                    <IconButton onClick={handleAddUser}>
                      <BsPersonFillAdd size={"3em"} />
                    </IconButton>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <IconButton onClick={handleDeleteUser}>
                      <BsFillPersonDashFill size={"3em"} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Container>
                  <Box
                    onDrop={() => handleDrop()}
                    onDragOver={handleDragOver}
                    draggable
                  >
                    <h3>User Management</h3>
                    <List>
                      {props.usersData.map(
                        (item, index) =>
                          !item.parentGroupId && (
                            <ListItem
                              key={index}
                              draggable
                              onDragStart={() => handleDragStart(index)}
                              onDragOver={handleDragOver}
                            >
                              <ListItemText>{item.name}</ListItemText>
                            </ListItem>
                          ),
                      )}
                    </List>
                  </Box>
                </Container>
              </Grid>
              <Grid item xs={6} style={{ marginTop: "3em" }}>
                <Container>
                  <Box>
                    <h3>Group Management</h3>
                    <UserGroupTreeView
                      treeData={props.groupsData}
                      draggedItem={draggedItem}
                      setDraggedItem={setDraggedItem}
                      {...props}
                    />
                  </Box>
                </Container>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openModal && (
        <UserGroupForm
          setOpenModal={setOpenModal}
          setCallUserGroup={setCallUserGroup}
        />
      )}
      {openDeleteModal && (
        <UserGroupDeleteForm
          users={props.usersData}
          setOpenDeleteModal={setOpenDeleteModal}
          setCallUserGroup={setCallUserGroup}
          userGroupData={props.userGroupData}
        />
      )}
    </Grid>
  );
};

export default UserGroupManagement;
