import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { AiOutlineClose } from "react-icons/ai";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import Button from "../../reusableComponents/Button";
import { StyledDashboardContentFlex } from "../../styles/Login.Styled";
import { StyledGridLabel } from "../../styles/Styled";

const AddCustomerForm = (props) => {
  const [dataForm, setDataForm] = useState({
    accountId: null,
    awsRegion: "",
    env: "",
    roleArn: "",
    cloudProvider: "",
  });

  const awsRegion = useSelector((state) => state.users.getawsRegion);
  const loader = useSelector((state) => state.users.awsLoader);

  return (
    <Dialog open={true}>
      <DialogTitle>
        <StyledDashboardContentFlex justifyContent>
          <h3>Add new customer</h3>
          <AiOutlineClose
            onClick={() => props.setOpenModal(false)}
            style={{ float: "right", cursor: "pointer" }}
          />
        </StyledDashboardContentFlex>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {!loader ? (
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ReactLoading
                  type={"spin"}
                  color={"#222"}
                  height={20}
                  width={30}
                />
              </Grid>
            ) : (
              <StyledDashboardContentFlex column>
                <StyledGridLabel>
                  Region
                  <select
                    value={dataForm.awsRegion}
                    onChange={(e) =>
                      setDataForm({ ...dataForm, awsRegion: e.target.value })
                    }
                    className="selectClass margin"
                  >
                    {[
                      <option key="default" value="">
                        ---select
                      </option>,
                      ...awsRegion?.map((option) => (
                        <option key={option.regionName} value={option.region}>
                          {option.region}
                        </option>
                      )),
                    ]}
                  </select>
                </StyledGridLabel>
                <StyledGridLabel>
                  Environment
                  <select
                    value={dataForm.env}
                    onChange={(e) =>
                      setDataForm({ ...dataForm, env: e.target.value })
                    }
                    className={"selectClass margin"}
                  >
                    <option>---select</option>
                    <option value="dev">Dev</option>
                    <option value="prod">Prod</option>
                  </select>
                </StyledGridLabel>
                <StyledGridLabel>
                  Role
                  <input
                    id="name"
                    type={"text"}
                    value={dataForm.roleArn}
                    onChange={(e) =>
                      setDataForm({ ...dataForm, roleArn: e.target.value })
                    }
                    className={"selectClass margin"}
                  />
                </StyledGridLabel>
                <StyledGridLabel>
                  Cloud Provider
                  <select
                    value={dataForm.cloudProvider}
                    onChange={(e) =>
                      setDataForm({
                        ...dataForm,
                        cloudProvider: e.target.value,
                      })
                    }
                    className={"selectClass margin"}
                  >
                    <option>---select</option>
                    <option value="AWS">AWS Cloud Service</option>
                    <option value="AZURE">Azure Cloud Platform</option>
                    <option value="GCP">Google Cloud Platform</option>
                  </select>
                </StyledGridLabel>
              </StyledDashboardContentFlex>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          type={"submit"}
          text="Save"
          className={"next_btn"}
          disabled={
            !dataForm.awsRegion ||
            !dataForm.env ||
            !dataForm.roleArn ||
            !dataForm.cloudProvider
          }
          onClick={() => props.handleClick(dataForm)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddCustomerForm;
