import {
  axiosCatalogApi,
  axiosGlueMetadata,
} from "../../../clientRequest/axiosRequest";
import {
  updateDataBases,
  setIsDataBasesLoading,
  setDataBasesError,
  updateTables,
  setTablesError,
  setIsTablesLoading,
  setIsTableDetailsLoading,
  updateTableDetails,
  setTableDetailsError,
  setIsTableFiltersLoading,
  updateTableFilters,
  setTableFiltersError,
  setIsTableInfosLoading,
  updateTableInfos,
  setTableInfosError,
  setIsCatalogDataBasesLoading,
  setCatalogDataBasesError,
  updateCatalogDataBases,
  updateCatalogTablesAttributes,
  setCatalogTablesAttributesError,
  setIsCatalogTablesAttributesLoading,
} from "../reducers/glueMetadataReducer";
import {
  mockTableDetailsDataWithPaginationAndOrder,
  mockTableFilters,
  mockTableInfos,
} from "./mocks/mockTableDetails";

export const getDataBases = (baseURL, appEnvironment) => async (dispatch) => {
  try {
    dispatch(setIsDataBasesLoading(true));
    dispatch(setDataBasesError(null));
    const res = await axiosGlueMetadata(baseURL).post(
      `/${appEnvironment}/list-databases`,
    );
    dispatch(updateDataBases(res.data));
  } catch (error) {
    dispatch(setDataBasesError(error.message));
  } finally {
    dispatch(setIsDataBasesLoading(false));
  }
};

export const getDataBaseTables =
  (databaseName, baseURL, appEnvironment) => async (dispatch) => {
    try {
      dispatch(setIsTablesLoading(true));
      dispatch(setTablesError(null));
      const res = await axiosGlueMetadata(baseURL).post(
        `/${appEnvironment}/list-tables/${databaseName}`,
      );
      dispatch(updateTables(res.data));
    } catch (error) {
      dispatch(setTablesError(error.message));
    } finally {
      dispatch(setIsTablesLoading(false));
    }
  };

export const getTableDetails =
  (
    databaseName,
    tableName,
    baseURL,
    appEnvironment,
    page = 0,
    pageSize = 10,
    orderBy = null,
    orderDirection = null,
    filters = [],
  ) =>
  async (dispatch) => {
    try {
      dispatch(setIsTableDetailsLoading(true));
      dispatch(setTableDetailsError(null));
      dispatch(
        updateTableDetails(mockTableDetailsDataWithPaginationAndOrder.res),
      );
    } catch (error) {
      dispatch(updateTableDetails({}));
      dispatch(setTableDetailsError(error.message));
    } finally {
      dispatch(setIsTableDetailsLoading(false));
    }
  };

export const getTableFilters =
  (databaseName, tableName, baseURL, appEnvironment) => async (dispatch) => {
    try {
      dispatch(setIsTableFiltersLoading(true));
      dispatch(setTableFiltersError(null));

      //let url = `/${appEnvironment}/list-table-filters/${databaseName}/${tableName}/`;
      //const res = await axiosGlueMetadata(baseURL).post(url);
      dispatch(updateTableFilters(mockTableFilters.res));
    } catch (error) {
      dispatch(updateTableFilters([]));
      dispatch(setTableFiltersError(error.message));
    } finally {
      dispatch(setIsTableFiltersLoading(false));
    }
  };

export const getTableInfos =
  (databaseName, tableName, baseURL, appEnvironment) => async (dispatch) => {
    try {
      dispatch(setIsTableInfosLoading(true));
      dispatch(setTableInfosError(null));

      //let url = `/${appEnvironment}/list-table-filters/${databaseName}/${tableName}/`;
      //const res = await axiosGlueMetadata(baseURL).post(url);
      dispatch(updateTableInfos(mockTableInfos.res));
    } catch (error) {
      dispatch(updateTableInfos({}));
      dispatch(setTableInfosError(error.message));
    } finally {
      dispatch(setIsTableInfosLoading(false));
    }
  };

export const getCatalogDataBases =
  (baseURL, token, cloudCredential, login) => async (dispatch) => {
    try {
      dispatch(setIsCatalogDataBasesLoading(true));
      dispatch(setCatalogDataBasesError(null));

      cloudCredential.cloudCredential[0].path = "list-databases";

      //change to correct baseURL ssm param
      const res = await axiosCatalogApi(
        "https://api-core.databoat.nl",
        token,
      ).post("/catalog/list-databases", cloudCredential);
      dispatch(updateCatalogDataBases(res.data));
      if (login) {
        dispatch(setIsCatalogTablesAttributesLoading(true));
        async function fetchTables() {
          let updatedCatalogDatabases = {};

          res.data.forEach((database) => {
            updatedCatalogDatabases[database] = {};
          });

          const cloudCredentialsArray = Object.keys(
            updatedCatalogDatabases,
          ).map((databaseName) => {
            return {
              ...cloudCredential.cloudCredential[0],
              path: `list-tables/${databaseName}`,
            };
          });
          Promise.allSettled([
            ...cloudCredentialsArray.map((cloudCredential) =>
              axiosCatalogApi("https://api-core.databoat.nl", token).post(
                "/catalog/list-tables",
                { cloudCredential: [cloudCredential] },
              ),
            ),
          ])
            .then((results) => {
              results.forEach((response, index) => {
                const databaseName = JSON.parse(
                  results[index].value.config.data,
                ).cloudCredential[0].path.split("/")[1];
                response.value.data.forEach((table) => {
                  updatedCatalogDatabases[databaseName] = {
                    ...updatedCatalogDatabases[databaseName],
                    [table]: {
                      attributes: [],
                    },
                  };
                });
              });

              Promise.allSettled([
                ...Object.keys(updatedCatalogDatabases).flatMap(
                  (databaseName) => {
                    return Object.keys(
                      updatedCatalogDatabases[databaseName],
                    ).map((tableName) => {
                      return axiosCatalogApi(
                        "https://api-core.databoat.nl",
                        token,
                      ).post("/catalog/list-table-attributes", {
                        cloudCredential: [
                          {
                            ...cloudCredential.cloudCredential[0],
                            path: `list-table-attributes/${databaseName}/${tableName}`,
                          },
                        ],
                      });
                    });
                  },
                ),
              ]).then((results) => {
                results.forEach((response) => {
                  const [, databaseName, tableName] = JSON.parse(
                    response.value.config.data,
                  ).cloudCredential[0].path.split("/");
                  updatedCatalogDatabases[databaseName][tableName].attributes =
                    response.value.data;
                });

                dispatch(
                  updateCatalogTablesAttributes(updatedCatalogDatabases),
                );

                dispatch(setIsCatalogTablesAttributesLoading(false));
              });
            })
            .catch((error) => setCatalogTablesAttributesError(error));
        }
        fetchTables();
      }
    } catch (error) {
      dispatch(setCatalogDataBasesError(error.message));
    } finally {
      dispatch(setIsCatalogDataBasesLoading(false));
    }
  };
