import {
  axiosClientCustomerApi,
  axiosClientAWSRegionApi,
  axiosClientBundlesApi,
  axiosClientLacasApi,
  axiosGetTable,
  axiosGetJobs,
  axiosTransformData,
} from "../../../clientRequest/axiosRequest";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { getCatalogDataBases } from "./glueMetadataActions";

const AWS = require("aws-sdk");

AWS.config.update({
  region: "us-east-1",
  accessKeyId: "AKIA5B5UAVV3DDVMJNRA",
  secretAccessKey: "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",
});
const ssm = new AWS.SSM();

async function getSSMParameters() {
  const params = {
    Names: [
      "/dpac-front-end/REACT_APP_CLIENT_ID",
      "/dpac-front-end/REACT_APP_USERPOOL_ID",
      "/dpac-front-end/REACT_APP_ATHENA_DATA_TRANSFORMATION",
      "/dpac-front-end/REACT_APP_ATHENA_QUERY_EXECUTE",
      "/dpac-front-end/REACT_APP_BASE_URL_API_BACKEND",
      "/dpac-front-end/REACT_APP_BASE_URL_API_CORE",
      "/dpac-front-end/REACT_APP_ENVIRONMENT",
      "/dpac-front-end/REACT_APP_LACAS_API",
      "/dpac-front-end/REACT_APP_TABLE_API",
    ],
    WithDecryption: true,
  };

  const request = await ssm.getParameters(params).promise();

  const parameters = request.Parameters;

  const environmentVariables = {};

  parameters.forEach((parameter) => {
    const name = parameter.Name.replace("/dpac-front-end/", "");
    const value = parameter.Value;
    environmentVariables[name] = value;
  });
  return environmentVariables;
}
export const getAWSSSMParameters = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SSM_PARAMETERS_LOADING",
    });
    const result = await getSSMParameters();
    dispatch({
      type: "GET_SSM_PARAMETERS_SUCCESS",
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: "GET_SSM_PARAMETERS_ERROR",
      payload: error.message,
    });
  }
};
export const fetchCustomerData = (token, url, group) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_CUSTOMER_DATA_REQUEST_LOADING",
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosClientCustomerApi(url).get("/customer", {
      headers,
    });
    dispatch({
      type: "FETCH_CUSTOMER_DATA_REQUEST_SUCCESS",
      payload: response.data,
    });
    if (group) {
      const currentCustomer = response.data.find(
        (e) => e.customerName === group,
      );
      dispatch(
        getCatalogDataBases(
          "",
          token,
          {
            cloudCredential: [
              {
                cloudProvider: currentCustomer.cloudCredential[0].cloudProvider,
                ssmPathParam: currentCustomer.cloudCredential[0].ssmPathParam,
                region: currentCustomer.cloudCredential[0].region,
              },
            ],
          },
          !!group,
        ),
      );
    }
  } catch (error) {
    dispatch({
      type: "FETCH_CUSTOMER_DATA_REQUEST_ERROR",
      payload: error.message,
    });
  }
};

export const patchCustomerData =
  (token, customerName, formData, url) => async (dispatch) => {
    try {
      dispatch({
        type: "PATCH_CUSTOMER_DATA_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const res = await axiosClientCustomerApi(url).patch(
        `/customer/${customerName}`,
        { headers },
        formData,
      );
      dispatch({
        type: "PATCH_CUSTOMER_DATA_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "PATCH_CUSTOMER_DATA_ERROR",
        payload: error.message,
      });
    }
  };

export const setJWTToken = (token) => {
  return {
    type: "SET_TOKEN",
    payload: token,
  };
};
export const setIsJobEditing = (Boolean) => {
  return {
    type: "SET_IS_JOB_EDITING",
    payload: Boolean,
  };
};
export const setUserGroup = (group) => {
  return {
    type: "SET_GROUP",
    payload: group,
  };
};
export const setUserEmail = (email) => {
  return {
    type: "SET_EMAIL",
    payload: email,
  };
};
export const getTokenFromLocalStorage = (token) => {
  return {
    type: "GET_TOKEN",
    payload: token,
  };
};
export const setRefreshToken = (token) => {
  return {
    type: "SET_REFRESH_TOKEN",
    payload: token,
  };
};
export const setNewToken = (token) => {
  return {
    type: "NEW_TOKEN",
    payload: token,
  };
};
export const setSession = (session) => {
  return {
    type: "SET_USER_SESSION",
    payload: session,
  };
};
export const setLogin = (isLoggedIn) => {
  return {
    type: "SET_LOGIN",
    payload: isLoggedIn,
  };
};
export const setNewIDToken = (token) => {
  return {
    type: "NEW_ID_TOKEN",
    payload: token,
  };
};
export const setDropDownOpen = (isactive) => {
  return {
    type: "SET_NEW_DROPDOWN_LOADER",
    payload: isactive,
  };
};

export const fetchAWSRegionData = (token, url) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_AWS_REGION_LOADING",
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axiosClientAWSRegionApi(url).get("/aws-region", {
      headers,
    });
    dispatch({
      type: "GET_AWS_REGION_SUCCESS",
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_AWS_REGION_ERROR",
      payload: error.message,
    });
  }
};

export const fetchBundlesData = (token, url) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_BUNDLES_LOADING",
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axiosClientBundlesApi(url).get("/module", { headers });
    dispatch({
      type: "GET_BUNDLES_SUCCESS",
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_BUNDLES_ERROR",
      payload: error.message,
    });
  }
};

export const postLacasData =
  (customerName, bundle, environment, formData, url) => async (dispatch) => {
    try {
      dispatch({
        type: "POST_LACAS_DATA_LOADING",
      });

      const res = await axiosClientLacasApi(url).post(
        `/customer/${customerName}/bundle/${bundle}/environment/${environment}`,
        formData,
      );
      dispatch({
        type: "POST_LACAS_DATA_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "POST_LACAS_DATA_ERROR",
        payload: error.message,
      });
    }
  };

export const postCustomerData = (token, formData, url) => async (dispatch) => {
  try {
    dispatch({
      type: "POST_CUSTOMER_DATA_LOADING",
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axiosClientCustomerApi(url).post(
      "/customer",
      { headers },
      formData,
    );
    dispatch({
      type: "POST_CUSTOMER_DATA_SUCCESS",
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: "POST_CUSTOMER_DATA_ERROR",
      payload: error.message,
    });
  }
};

export const postAllJobs =
  (group, formData, token, url) => async (dispatch) => {
    try {
      dispatch({
        type: "POST_JOBS_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).post(
        `/yeda/jobs?authorizedGroups=${group}`,
        formData,
        {
          headers,
        },
      );
      dispatch({
        type: "POST_JOBS_SUCCESS",
        payload: {
          formData: formData,
          responseData: res.data,
        },
      });
    } catch (error) {
      dispatch({
        type: "POST_JOBS_ERROR",
        payload: error,
      });
    }
  };
export const postTransformationQuery =
  (formData, token, url) => async (dispatch) => {
    try {
      dispatch({
        type: "POST_QUERY_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const res = await axiosGetJobs(url).post(
        "transformation/query",
        formData,
        { headers },
      );
      dispatch({
        type: "POST_QUERY_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "POST_QUERY_ERROR",
        payload: error,
      });
    }
  };
export const getTransformationResult =
  (token, url, jobId, formData) => async (dispatch) => {
    const fetchResult = async () => {
      try {
        dispatch({
          type: "GET_RESULT_LOADING",
        });

        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };

        const res = await axiosGetJobs(url).post(
          `/transformation/result?jobId=${jobId}`,
          formData,
          {
            headers,
          },
        );
        if (res.data.length > 0) {
          dispatch({
            type: "GET_RESULT_SUCCESS",
            payload: res.data,
          });
        } else {
          // If result is empty, make another request
          fetchResult();
        }
      } catch (error) {
        dispatch({
          type: "GET_RESULT_ERROR",
          payload: error,
        });
      }
    };

    fetchResult(); // Initial call
  };

export const clearToastSuccess = () => ({
  type: "CLEAR_TOAST_SUCCESS",
});
export const clearManualUploadToastSuccess = () => ({
  type: "CLEAR_MANUAL_UPLOAD_TOAST_SUCCESS",
});

export const clearToastError = () => ({
  type: "CLEAR_TOAST_ERROR",
});
export const clearManualUploadToastError = () => ({
  type: "CLEAR_MANUAL_UPLOAD_TOAST_ERROR",
});
export const fetchJobs = (token, url) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_JOBS_LOADING",
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axiosGetJobs(url).get("/yeda/jobs", { headers });
    dispatch({
      type: "GET_JOBS_SUCCESS",
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_JOBS_ERROR",
      payload: error,
    });
  }
};
export const fetchSingleJob = (token, url, jobName) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SINGLE_JOB_LOADING",
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axiosGetJobs(url).get(`/yeda/jobs?jobName=${jobName}`, {
      headers,
    });
    dispatch({
      type: "GET_SINGLE_JOB_SUCCESS",
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_SINGLE_JOB_ERROR",
      payload: error,
    });
  }
};
export const fetchJobsAnalytics =
  (token, group, baseURL) => async (dispatch) => {
    try {
      dispatch({
        type: "GET_JOBS_ANALYTICS_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const res = await axiosGetJobs(baseURL).get(
        `/yeda/analytics?grpName=${group}`,
        { headers },
      );
      dispatch({
        type: "GET_JOBS_ANALYTICS_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_JOBS_ANALYTICS_ERROR",
        payload: error,
      });
    }
  };
export const deleteJobs = (jobName, token, url) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_JOBS_LOADING",
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    await axiosGetJobs(url).delete(`/yeda/jobs?jobName=${jobName}`, {
      headers,
    });
    dispatch({
      type: "DELETE_JOBS_SUCCESS",
      payload: jobName,
    });
  } catch (error) {
    dispatch({
      type: "DELETE_JOBS_ERROR",
      payload: error,
    });
  }
};

export const fetchJobExecutions = (token, jobName, url) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_EXECUTIONS_LOADING",
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axiosGetJobs(url).get(`/execution?grpName=${jobName}`, {
      headers,
    });
    dispatch({
      type: "GET_EXECUTIONS_SUCCESS",
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_EXECUTIONS_ERROR",
      payload: error,
    });
  }
};

export const warmUpFetchDatabaseName = (url, token) => async (dispatch) => {
  try {
    dispatch({
      type: "WARM_DATABASE_LOADING",
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axiosGetTable(url).post(
      "yeda/database-metadata/mssql/list-databases",
      {},
      { headers },
    );
    dispatch({
      type: "WARM_DATABASE_SUCCESS",
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: "WARM_DATABASE_ERROR",
      payload: error.message,
    });
  }
};
export const fetchDatabaseName = (formData, url, token) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_DATABASE_LOADING",
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axiosGetTable(url).post("/list-databases", formData, {
      headers,
    });
    if (res.data === null) {
      dispatch({
        type: "GET_NULL_SUCCESS",
        payload: "No data found. Please try again.",
      });
    } else {
      dispatch({
        type: "GET_DATABASE_SUCCESS",
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: "GET_DATABASE_ERROR",
      payload: error.message,
    });
  }
};

export const postManualUploadData =
  (formData, token, url) => async (dispatch) => {
    try {
      dispatch({
        type: "POST_MANUAL_UPLOAD_DATA_LOADING",
      });
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      const res = await axiosGetJobs(url).post("/manual-upload", formData, {
        headers,
      });
      dispatch({
        type: "POST_MANUAL_UPLOAD_DATA_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "POST_MANUAL_UPLOAD_DATA_ERROR",
        payload: error.message,
      });
    }
  };
export const patchJob = (jobName, formData, token, url) => async (dispatch) => {
  try {
    dispatch({
      type: "PATCH_LOADING",
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await axiosGetJobs(url).patch(
      `/yeda/jobs/edit?jobName=${jobName}`,
      formData,
      {
        headers,
      },
    );
    dispatch({
      type: "PATCH_SUCCESS",
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: "PATCH_ERROR",
      payload: error,
    });
  }
};
export const fetchTransformationScript =
  (token, url, groupName) => async (dispatch) => {
    try {
      dispatch({
        type: "TRANSFORMATION_SCRIPT_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const res = await axiosTransformData(url).get(
        `/customer/users?customerName=${groupName}`,
        {
          headers,
        },
      );
      dispatch({
        type: "TRANSFORMATION_SCRIPT_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "TRANSFORMATION_SCRIPT_ERROR",
        payload: error,
      });
    }
  };
export const postTransformationScript =
  (token, url, user, group, formData) => async (dispatch) => {
    try {
      dispatch({
        type: "POST_SCRIPT_LOADING",
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const res = await axiosTransformData(url).post(
        `/customer/users/script-folder?customerName=${group}&userName=${user}`,
        formData,
        {
          headers,
        },
      );
      dispatch({
        type: "POST_SCRIPT_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "POST_SCRIPT_ERROR",
        payload: error,
      });
    }
  };
export const validateToken =
  (poolData, clientId, token) => async (dispatch) => {
    dispatch({ type: "TOKEN_VALIDATION_START" });
    const verifier = CognitoJwtVerifier.create({
      userPoolId: poolData,
      tokenUse: "id",
      clientId: clientId,
    });

    try {
      const payload = await verifier.verify(token);
      dispatch({
        type: "TOKEN_VALIDATION_SUCCESS",
        payload: payload,
      });
    } catch (error) {
      dispatch({
        type: "TOKEN_VALIDATION_FAILURE",
        payload: error,
      });
    }
  };
