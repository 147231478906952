import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  StyledDashboardContentFlex,
  ModalHeader,
  Flex,
  Search,
  CreateJobButton,
  FilteredBox,
} from "../../../index";
import { Dialog } from "@mui/material";
import { EditDatabaseData } from "../Mocks/EditDatabaseData";
import EditDataDictionaryTable from "./EditDataDictionaryTable";
import CloseSvg from "../../../reusableComponents/svg/CloseSvg";

const EditDataDictionaryModal = ({ open, handleClose, datasetNameRef }) => {
  const navigate = useNavigate();
  const [editableItems, setEditableItems] = useState(EditDatabaseData);
  const [searchValue, setSearchValue] = useState("");

  const handleEditingData = (e, index) => {
    const newValue = e.target.value;
    setEditableItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = { ...updatedItems[index], Description: newValue };
      return updatedItems;
    });
  };

  const handleSortByColumnName = () => {
    //sort logic
    console.log("handleSortByColumnName");
  };

  const handleSortByDataType = () => {
    //sort logic
    console.log("handleSortByDataType");
  };

  const handleSubmit = async (e) => {
    navigate(-1);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      PaperProps={{ sx: { borderRadius: "28px", maxWidth: "1000px" } }}
    >
      <ModalHeader>
        <p>{datasetNameRef.current} schemas</p>
        <div onClick={handleClose}>
          <CloseSvg />
        </div>
      </ModalHeader>
      <StyledDashboardContentFlex column style={{ padding: "1.5em" }}>
        <Flex>
          <Search
            placeholder="filter schema"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <FilteredBox
            text={"Column Name"}
            handleSorting={handleSortByColumnName}
          />
          <FilteredBox
            text={"Data Type"}
            handleSorting={handleSortByDataType}
          />
        </Flex>
        <EditDataDictionaryTable
          rows={editableItems}
          handleEditingData={handleEditingData}
        />
        <CreateJobButton
          text={"Save"}
          type={"button"}
          onClick={() => handleSubmit()}
        />
      </StyledDashboardContentFlex>
    </Dialog>
  );
};

export default EditDataDictionaryModal;
