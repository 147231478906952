import styled from "styled-components";
import Theme from "../../../Theme";

//containers
const defaultHeight = "89vh";
export const DataSelectorSideBar = styled.section`
  width: ${(props) => (props.isOpen ? "350px" : "55px")};
  box-sizing: border-box;
  height: ${defaultHeight};
  margin-left: 10px;
  overflow: hidden;
  box-shadow: ${Theme.boxShadow.primary_boxShadow};
  background: white;
  margin-top: 15px;
  .sqlQuerySideBarHeader {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: ${(props) => (props.isOpen ? "10px" : "5px")};
    background: ${Theme.colors.bg_color};
    color: white;
  }

  .sqlQuerySideBarChildren {
    display: ${(props) => (props.isOpen ? "block" : "none")};
    height: 50px;
    padding: 15px;
  }

  .tableSearchDiv {
    position: relative;
    .tableSearchIcon {
      position: absolute;
      right: 5px;
      height: 100%;
      width: 30px;
      color: ${Theme.colors.teritaryTextColor};
    }
    .tableSearchIcon:hover {
      color: ${Theme.colors.secondaryElementColor};
      transition: 0.2s;
    }
  }

  .sqlQuerySideBarDataTable {
    display: ${(props) => (props.isOpen ? "block" : "none")};
    width: 100%;
    height: calc(${defaultHeight} - 275px);
    overflow-y: scroll;
    box-sizing: border-box;
    margin-top: 115px;
    padding-right: 5px;
    padding-left: 20px;
    button {
      width: 30px;
      height: 30px;
      color: white;
    }
    scrollbar-color: ${Theme.colors.primaryColor} transparent;
  }
`;

export const SQLTransformationContent = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isSideBarOpen ? "calc(100% - 350px)" : "100%")};
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
  .buttonsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1rem;
    gap: 15px;
  }
  .tableSearchMechanism {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    text-align: left;
    height: 35px;
    input {
      height: 25px;
    }
    .inputIconSearch {
      position: relative;
      width: 100%;
      .tableSearchIcon {
        position: absolute;
        right: -5px;
        height: 100%;
        width: 30px;
        color: ${Theme.colors.teritaryTextColor};
      }
      .tableSearchIcon:hover {
        color: ${Theme.colors.secondaryElementColor};
        transition: 0.2s;
      }
    }
  }
  .topNavigationButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  .queryTabsContainer {
    display: block flex;
    overflow: auto;
    scrollbar-width: thin;
    max-height: 60px;
    width: 70vw;
  }
`;

export const SQLEditorContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* width: 79vw; */
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  box-sizing: border-box;
  background: ${Theme.colors.secondaryColor};
`;

//end containers

//content
export const LineCount = styled.div`
  background: #2f2f2f;
  color: ${Theme.colors.tertiaryColor};
  height: 350px;
  overflow: hidden;
  font-weight: ${Theme.fonts.semiBoldFont};
  padding: 10px 5px 0px 0px;
  text-align: right;
  display: flex;
  flex-direction: column;
  width: 15px;
  white-space: pre-line;
  border-left: solid 1px black;
  border-bottom: solid 1px black;
  border-top: solid 1px black;
  width: 50px;
  position: absolute;
`;

export const QueryBox = styled.textarea`
  box-sizing: border-box;
  color: ${Theme.colors.primaryColor};
  resize: none;
  width: 100%;
  height: 350px;
  padding: 10px 10px 10px 55px;
  border: none;
  box-shadow: ${Theme.boxShadow.primary_boxShadow};
  &:focus {
    outline: none;
  }
`;

export const GhostButton = styled.button`
  border: none;
  background: none;
  padding: ${(props) => (props.size === "small" ? "0px" : "10px")};
  width: ${(props) => (props.width ? props.width : "fit-content")};
  font-size: 1.2rem;
  color: black;
  &:focus {
    outline-color: ${Theme.colors.border_color};
    outline-style: dotted;
    outline-width: 1px;
  }
  &:hover {
    color: ${Theme.colors.secondaryColor};
    transition: 0.2s;
  }
  &:active {
    outline: none;
  }
  cursor: pointer;
`;

export const SemiBoldParagraph = styled.p`
  font-weight: ${Theme.fonts.semiBoldFont};
  word-wrap: break-word;
  white-space: break-spaces;
`;

export const TableColumnList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 1.5rem;
  font-size: 0.9rem;
  .transformationTableListName {
    font-weight: ${Theme.fonts.semiBoldFont};
  }
`;

export const WrappedParagraph = styled.p`
  word-wrap: break-word;
  white-space: break-spaces;
  padding: 0 15px;
`;
export const TableListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  p {
    width: 80%;
    text-align: right;
  }
  background: #2f2f2f;
  border-radius: 5px 5px 0px 0px;
  color: ${Theme.colors.secondaryColor};
  padding: 10px;
`;
export const TableAttrContainer = styled.div`
  padding: 10px 0px;
  border: groove 1px ${Theme.colors.border_color};
  border-radius: 0px 0px 5px 5px;
`;
export const DataTable = styled.table`
  table-layout: auto;
  .resultRowIndex {
    font-weight: ${Theme.fonts.semiBoldFont};
    width: fit-content;
  }
  th,
  tr {
    line-height: 0.5rem;
    font-size: 0.5rem;
  }
`;

export const DataTableContainer = styled.section`
  width: inherit;
  box-sizing: border-box;
  height: calc(${defaultHeight} - 495px);
  padding-left: 10px;
  overflow-y: scroll;
  overflow-x: scroll;
  box-sizing: border-box;
  box-shadow: ${Theme.boxShadow.primary_boxShadow};
  input {
    margin-left: 15px;
  }
`;

export const TextGhostButton = styled(GhostButton)`
  font-size: 1rem;
  border: solid 3px ${Theme.colors.tertiaryColor};
  &:hover {
    box-shadow: ${Theme.boxShadow.primary_boxShadow};
    transition: 0.2s;
  }
  margin: 5px;
  border-radius: 5px;
`;

export const SearchInput = styled.input`
  height: 38px;
  border-radius: 3px;
  outline: none;
  border: solid 1px ${Theme.colors.border_color};
  font-size: 1rem;
  width: ${(props) => props.width};
  padding: 0 10px;
`;

export const DarkButton = styled(GhostButton)`
  color: ${Theme.colors.secondaryColor};
  font-size: 1rem;
  background: ${Theme.colors.bg_color};
  &:hover {
    color: ${Theme.colors.teritaryTextColor};
    transition: 0.2s;
  }
  &:focus {
    outline: solid 2px rgb(66, 71, 78);
  }
`;

export const DarkRadiusButton = styled(DarkButton)`
  border-radius: 5px;
  &:disabled {
    background: ${Theme.colors.tertiaryBackgroundColor};
    color: ${Theme.colors.teritaryTextColor};
    cursor: not-allowed;
  }
`;

export const QueryTab = styled.div`
  border-top-right-radius: 15px;
  padding: 1rem;
  box-shadow: ${Theme.boxShadow.secondary_boxShadow};
  background: ${(props) =>
    !props.background ? Theme.colors.secondaryColor : Theme.colors.bg_color};
  color: ${(props) =>
    props.background ? Theme.colors.secondaryColor : Theme.colors.bg_color};
  cursor: pointer;
`;

export const SuggestionOptionSelector = styled.select`
  border-radius: 5px;
  line-height: 2rem;
  padding: 5px;
  width: inherit;
  background: transparent;
  &:focus {
    outline: solid 1px lightgray;
  }
`;
