import {
  HomeSvg,
  JobsSvg,
  WorkFlow,
  DashBoardSvg,
  DataManagementSvg,
  SchemaRegistrySvg,
  ALSvg,
  SandBoxSvg,
  MlopsSvg,
  TransformationSvg,
  CatalogSvg,
  ObservabilitySvg,
  MetricsSvg,
  LogsSvg,
  LineageSvg,
  DataQualitySvg,
  DataGovernanceSvg,
  DataClassificationSvg,
  ControlPlaneSvg,
  UploadSvg,
} from "../reusableComponents/svg/menuListSvg";
export const GetMenuList = () => {
  const menuList = [
    {
      id: 1,
      title: "Home",
      path: "/dataManagement",
      class: "dataManagement",
      icon: <HomeSvg />,
      newFeature: false,
    },
    {
      id: 2,
      title: "Jobs",
      icon: <JobsSvg />,
      options: [
        {
          id: 1,
          subMenu: "Workflow Management",
          optionLink: "/yedaflow",
          optionClass: "yedaflow",
          class: "yedaflow",
          optionIcon: <WorkFlow />,
        },
        // TODO Route from Jobs
        {
          id: 2,
          subMenu: "Job(s)",
          optionLink: "/create-job",
          optionClass: "create-job",
          optionIcon: <JobsSvg />,
        },
        {
          id: 3,
          subMenu: "Dashboard",
          optionLink: "/dashboard",
          optionClass: "dashboard",
          optionIcon: <DashBoardSvg />,
          newFeature: true,
        },
      ],
    },
    {
      id: 3,
      title: "Data Management",
      icon: <DataManagementSvg />,
      options: [
        {
          id: 1,
          subMenu: "Transformation",
          optionLink: "/transformation",
          optionClass: "transformation",
          optionIcon: <TransformationSvg />,
        },
        {
          id: 2,
          subMenu: "Upload",
          optionLink: "/upload",
          optionClass: "upload",
          optionIcon: <UploadSvg />,
        },
        {
          id: 3,
          subMenu: "Catalog",
          optionLink: "/catalog",
          optionClass: "catalog",
          optionIcon: <CatalogSvg />,
          newFeature: true,
        },
      ],
    },
    {
      id: 4,
      title: "Observability",
      icon: <ObservabilitySvg />,
      newFeature: true,
      options: [
        {
          id: 1,
          subMenu: "Metric(s)",
          optionLink: "/metrics",
          optionClass: "metrics",
          optionIcon: <MetricsSvg />,
          newFeature: true,
        },
        {
          id: 2,
          subMenu: "Logs",
          optionLink: "/logs",
          optionClass: "logs",
          optionIcon: <LogsSvg />,
          newFeature: true,
        },
        {
          id: 3,
          subMenu: "Lineage",
          optionLink: "/lineage",
          optionClass: "lineage",
          optionIcon: <LineageSvg />,
          newFeature: true,
        },
        {
          id: 4,
          subMenu: "Data Quality",
          optionLink: "/dataQuality",
          optionClass: "dataQuality",
          optionIcon: <DataQualitySvg />,
        },
      ],
    },
    {
      id: 5,
      title: "Data Governance",
      icon: <DataGovernanceSvg />,
      newFeature: true,
      options: [
        {
          id: 1,
          subMenu: "Data Classification",
          optionLink: "/dataClassification",
          optionClass: "dataClassification",
          optionIcon: <DataClassificationSvg />,
        },
        {
          id: 2,
          subMenu: "Schema",
          optionLink: "/schemaRegistry",
          optionClass: "schemaRegistry",
          optionIcon: <SchemaRegistrySvg />,
          newFeature: true,
        },
      ],
    },
    {
      id: 6,
      title: "AI/ML Sandbox",
      icon: <ALSvg />,
      newFeature: true,
      options: [
        {
          id: 1,
          subMenu: "Sandbox",
          optionLink: "/notebook",
          optionClass: "notebook",
          optionIcon: <SandBoxSvg />,
          newFeature: true,
        },
        {
          id: 2,
          subMenu: "MLOps Flow",
          optionLink: "/mlOps",
          optionClass: "mlOps",
          optionIcon: <MlopsSvg />,
          newFeature: true,
        },
      ],
    },
    {
      id: 7,
      title: "Control Plane",
      path: "/control",
      class: "control",
      icon: <ControlPlaneSvg />,
    },
  ];
  return menuList;
};
