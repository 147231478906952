export const CatalogData = [
  {
    id: "1",
    databaseName: "Mongodb",
    datasetName: "Csv",
    description: "Some Csv Data",
    loadedAt: "20230625",
    loadedBy: "Hema",
  },
  {
    id: "2",
    databaseName: "Mongodb",
    datasetName: "Csv",
    description: "Some Csv Data",
    loadedAt: "20230620",
    loadedBy: "DataOpsHouse",
  },
  {
    id: "3",
    databaseName: "Mongodb",
    datasetName: "Csv",
    description: "Some Csv Data",
    loadedAt: "20230620",
    loadedBy: "DataBot",
  },
];
