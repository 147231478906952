import { useEffect, useState } from "react";
import { fetchTransformationScript } from "../../../redux/actions/usersActions";
import { useNavigate } from "react-router-dom";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { useGetPoolData } from "../../../../clientRequest/env-variable";
import { useDispatch, useSelector } from "react-redux";

const useTransformationScript = () => {
  const getPoolData = useGetPoolData();
  const [transformationScript, setTransformationScript] = useState([]);
  const { getTransformationScript, scriptloading, environmentVariables } =
    useSelector((state) => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const checkSessionExpiration = () => {
      const UserPool = new CognitoUserPool(getPoolData);
      const cognitoUser = UserPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            throw err;
          } else {
            if (session.isValid()) {
              const token = session.getIdToken().jwtToken;
              const groupName = session.getIdToken().payload["cognito:groups"];
              dispatch(
                fetchTransformationScript(
                  token,
                  environmentVariables.REACT_APP_BASE_URL_API_BACKEND,
                  groupName,
                ),
              );
            } else {
              cognitoUser.signOut();
              navigate("/");
            }
          }
        });
      }
    };

    if (getPoolData.ClientId) {
      checkSessionExpiration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getPoolData.ClientId]);
  useEffect(() => {
    if (scriptloading) {
      const userEmail = localStorage.getItem("created");
      const getName = getTransformationScript.filter(
        (item) => item.user === userEmail,
      );

      if (getName.length > 0) {
        setTransformationScript(getName.flatMap((item) => item.scriptFolder));
      }
    }
  }, [getTransformationScript, scriptloading]);

  return { transformationScript };
};

export default useTransformationScript;
