import React from "react";
import ReactPaginate from "react-paginate";
import { StyledDashboardContentFlex } from "../styles/Login.Styled";
import { StyledJobPaginationResult } from "../styles/Yedaflow.Styled";

const UsePaginations = ({
  pageCount,
  endOffset,
  databaseLength,
  handlePageChange,
}) => {
  return (
    <StyledDashboardContentFlex justifyContent>
      <div style={{ marginBottom: "2em" }}>
        <StyledJobPaginationResult>
          Showing {pageCount} to {endOffset} of {databaseLength} records
        </StyledJobPaginationResult>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={30}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        renderOnZeroPageCount={null}
      />
    </StyledDashboardContentFlex>
  );
};

export default UsePaginations;
