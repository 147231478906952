import { useState } from "react";
import {
  DataBaseListButton,
  DataBaseListContainer,
} from "../Styles/TransformationStyle";
import {
  PiCaretRightLight,
  PiCaretDownLight,
  PiDatabaseLight,
  PiTableLight,
} from "react-icons/pi";

const DatabaseList = ({ dbSchemaRepresentation }) => {
  const [openDatabases, setOpenDatabases] = useState({});
  const [openTables, setOpenTables] = useState({});

  const toggleDatabase = (databaseName) => {
    setOpenDatabases((prevOpenDatabases) => ({
      ...prevOpenDatabases,
      [databaseName]: !prevOpenDatabases[databaseName],
    }));
  };

  const toggleTable = (databaseName, tableName) => {
    setOpenTables((prevOpenTables) => ({
      ...prevOpenTables,
      [`${databaseName}-${tableName}`]:
        !prevOpenTables[`${databaseName}-${tableName}`],
    }));
  };

  return (
    <DataBaseListContainer>
      {Object.entries(dbSchemaRepresentation).map(([databaseName, tables]) => (
        <div key={databaseName}>
          <DataBaseListButton onClick={() => toggleDatabase(databaseName)}>
            {openDatabases[databaseName] ? (
              <PiCaretDownLight size={"24px"} />
            ) : (
              <PiCaretRightLight size={"24px"} />
            )}
            <PiDatabaseLight size={"24px"} />
            {databaseName}
          </DataBaseListButton>
          {openDatabases[databaseName] && (
            <div>
              {Object.entries(tables).map(([tableName, { attributes }]) => (
                <div key={tableName}>
                  <DataBaseListButton
                    onClick={() => toggleTable(databaseName, tableName)}
                    style={{ padding: "0 2.5em" }}
                  >
                    {openTables[`${databaseName}-${tableName}`] ? (
                      <PiCaretDownLight size={"24px"} />
                    ) : (
                      <PiCaretRightLight size={"24px"} />
                    )}
                    <PiTableLight size={"24px"} />
                    {tableName}
                  </DataBaseListButton>
                  {openTables[`${databaseName}-${tableName}`] && (
                    <div style={{ padding: "0 16px 0 4.5em" }}>
                      {attributes.map((attribute) => (
                        <DataBaseListButton key={attribute.column}>
                          {attribute.column}
                        </DataBaseListButton>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </DataBaseListContainer>
  );
};

export default DatabaseList;
