import React from "react";
import { Outlet } from "react-router-dom";
import {
  StyledDashboardContentFlex,
  Flex,
  Search,
  Tabs,
  HomeSvg,
  CatalogSvg,
  TransformationSvg,
  StyledPortalContent,
  Dropdown,
} from "../../index";

import DatabaseTable from "./Components/DatabaseTable";

const CatalogPresenter = ({
  databaseSearchValue,
  handleDatabaseSearch,
  databaseSelected,
  handleSortByDatabase,
  loadAtSelected,
  handleSortByLoadAt,
  loadBySelected,
  handleSortByLoadBy,
}) => {
  const fill = "#006399";
  const size = "18";
  const tabData = [
    {
      to: "/dataManagement",
      icon: <HomeSvg fill={fill} width={size} height={size} />,
      label: "Home",
    },
    {
      to: "/transformation",
      icon: <TransformationSvg fill={fill} width={size} height={size} />,
      label: "Transformation",
    },
    {
      to: "/catalog",
      icon: <CatalogSvg fill={fill} width={size} height={size} />,
      label: "Data Catalog",
    },
  ];
  const databaseOptions = [
    "Database 1",
    "Database 2",
    "Database 3",
    "Database 4",
  ];

  return (
    <StyledPortalContent>
      <div style={{ padding: "1em" }}>
        <StyledDashboardContentFlex column>
          <Tabs tabs={tabData} />
          <StyledDashboardContentFlex>
            <Search
              placeholder="search by Tables / Attributes / Terms"
              value={databaseSearchValue}
              onChange={handleDatabaseSearch}
            />
            <Dropdown
              label={"Database"}
              options={databaseOptions}
              selected={databaseSelected}
              handleChange={handleSortByDatabase}
            />
          </StyledDashboardContentFlex>
          <Flex column style={{ gap: "2em" }}>
            {/* tables  */}
            <DatabaseTable />
          </Flex>
          <Outlet />
        </StyledDashboardContentFlex>
      </div>
    </StyledPortalContent>
  );
};

export default CatalogPresenter;
