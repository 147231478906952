import React from "react";
import { ConnectorScreens } from "../../../index";

const APIConnector = ({
  inputData,
  ApiData,
  handleAPIChange,
  apiHttp,
  httpData,
  httpvalue,
  handleHttpChange,
  renderKeyValue,
  apiparamKey,
  apihandleKeyChange,
  apiparamValue,
  apihandleValueChange,
  onClose,
}) => {
  return (
    <ConnectorScreens
      HeaderText={
        "Inform the api connection details to be loaded into Databoat"
      }
      inputData={inputData}
      data={ApiData}
      error={"sss"}
      handleChange={handleAPIChange}
      http={"API HTTP Method"}
      apiPartitionText={"API Partition Columns"}
      apiHttp={apiHttp}
      handleHttpChange={handleHttpChange}
      httpData={httpData}
      httpvalue={httpvalue}
      renderKeyValue={renderKeyValue}
      paramKey={apiparamKey}
      keyPlaceholder={"Enter the API header params key"}
      handleKeyChange={apihandleKeyChange}
      paramValue={apiparamValue}
      valuePlaceholder={"Enter the API header params value"}
      handleValueChange={apihandleValueChange}
      buttonText={"Save"}
      btnOnClick={onClose}
    />
  );
};

export default APIConnector;
