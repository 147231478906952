import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  MdEdit,
  MdOutlineDeleteOutline,
  MdRemoveRedEye,
  TableDataCell,
  UsePaginations,
  TableOverview,
  Theme,
} from "../../../index";
import EditDataDictionaryModal from "./EditDataDictionaryModal";
import { CatalogData } from "../Mocks/CatalogData";

const DatabaseTable = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const datasetNameRef = useRef()
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);
  const endOffset = currentPage + pageSize;
  const pageCount = Math.ceil(CatalogData.length / pageSize);

  function handlePageChange(event) {
    const newOffset = (event.selected * pageSize) % CatalogData.length;
    setCurrentPage(newOffset);
  }
  const [open, setOpen] = useState(false);

  const handleClickOpen = (datasetName) => {
    setOpen(true);
    datasetNameRef.current = datasetName;
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <TableOverview
        headers={["Database Name", "Dataset Name", "Description", "Modify"]}
        rows={CatalogData}
        width={"120px"}
        renderRow={(item, index) => (
          <tr key={index}>
            <TableDataCell>{item.databaseName}</TableDataCell>
            <TableDataCell>{item.datasetName}</TableDataCell>
            <TableDataCell>{item.description}</TableDataCell>
            <TableDataCell style={{ display: "flex", gap: "0.75em" }}>
              <Link className="modify_link">
                <MdRemoveRedEye
                  fontSize={"1.25em"}
                  style={{ color: Theme.colors.blueColor }}
                />
              </Link>
              <Link
                key={item.datasetName}
                onClick={() => handleClickOpen(item.datasetName)}
                className="modify_link"
              >
                <MdEdit
                  fontSize={"1.25em"}
                  style={{ color: Theme.colors.blueColor }}
                />
              </Link>
              <Link className="modify_link delete">
                <MdOutlineDeleteOutline
                  fontSize={"1.25em"}
                  style={{ color: "red" }}
                />
              </Link>
            </TableDataCell>
          </tr>
        )}
      />
      {open && (
        <EditDataDictionaryModal open={open} handleClose={handleClose} datasetNameRef={datasetNameRef}/>
      )}
      <UsePaginations
        pageCount={pageCount}
        endOffset={endOffset}
        databaseLength={CatalogData.length}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default DatabaseTable;
