import React, { useState } from "react";
import { StyledDashboardContentFlex, ConnectorScreens } from "../../../index";

const GSC_CONNECTOR = ({
  inputData,
  GCSData,
  handleGCSChange,
  handleGCSSourceBucketChange,
  handleGCSSourceBucketBlur,
  gcsfileNameError,
  gcstaskPartitionColumn,
  handleGCSTaskPartitionColumn,
  onClose,
}) => {
  const useTaskPartition = useState(true);
  const useSourceBucket = useState(true);
  return (
    <StyledDashboardContentFlex column>
      <ConnectorScreens
        HeaderText={"Fill attributes required for GCS connection"}
        inputData={inputData}
        data={GCSData}
        handleChange={handleGCSChange}
        useSourceBucket={useSourceBucket}
        formatType={"Required Format: bucket-name/file"}
        sourceBucketFormat={"gs://"}
        handleSourceBucketChange={handleGCSSourceBucketChange}
        sourceBucketPlaceholder={"e.g: bucket-name/file"}
        handleSourceBucketBlur={handleGCSSourceBucketBlur}
        sourceBucketError={gcsfileNameError}
        useTaskPartition={useTaskPartition}
        taskPartitionColumn={gcstaskPartitionColumn}
        handleTaskPartitionColumn={handleGCSTaskPartitionColumn}
        btnOnClick={onClose}
        buttonText={"Save"}
      />
    </StyledDashboardContentFlex>
  );
};

export default GSC_CONNECTOR;
