import React from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import {
  StyledDashboardContentFlex,
  Flex,
  MultiSelect,
  AiOutlineInfoCircle,
} from "../index";
const RenderTable = ({
  tableHead,
  tableData,
  taskId,
  databaseNameLoader,
  handleDatabaseNameChange,
  databaseName,
  handleSchemaNameChange,
  schemas,
  handleTableNameChange,
  tables,
  handleTableIngestModeOption,
  tableOptions,
  handleDeltaColumnChange,
  deltaColumn,
  handlePartionChange,
  handlePartitioningChange,
  partitionColumn,
  addRow,
}) => {
  const tooltips = [
    () => <></>,
    () => (
      <>
        <AiOutlineInfoCircle
          className="ingestion-mode info-icon"
          size={"1.5em"}
          style={{ float: "right" }}
        />
        <Tooltip
          anchorSelect=".schema"
          place="top"
          multiline={true}
          className="hover-info-container"
        >
          <Flex column>
            <span>Database schema,</span>
            <span> where tbe table is stored.</span>
          </Flex>
        </Tooltip>
      </>
    ),
  ];
  const { nullDatabaseNameReturn } = useSelector((state) => state.users);
  return (
    <StyledDashboardContentFlex column>
      {nullDatabaseNameReturn !== null ? (
        <p>{nullDatabaseNameReturn}</p>
      ) : (
        <div className="tableContainer">
          <table>
            <thead>
              <tr>
                {tableHead.map((column, index) => (
                  <th key={index} style={{ fontSize: "0.75em" }}>
                    <span>{column.title}</span>
                    {tooltips[index] && tooltips[index]()}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((data) => (
                <tr key={data.id}>
                  <td style={{ width: "200px" }}>
                    {databaseNameLoader ? (
                      <select
                        defaultValue={""}
                        onChange={(event) =>
                          handleDatabaseNameChange(event, data.id, taskId)
                        }
                        style={{ width: "100%", padding: "0.75em" }}
                        name="select ingestion Mode"
                      >
                        {databaseName.map((name, index) => (
                          <option key={index} value={name.value}>
                            {name.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <ReactLoading
                        type={"spin"}
                        color={"#222"}
                        height={20}
                        width={30}
                      />
                    )}
                  </td>
                  <td
                    style={{
                      width: "200px",
                    }}
                  >
                    {data.tableSchemasLoader ? (
                      <select
                        defaultValue={""}
                        onChange={(event) =>
                          handleSchemaNameChange(event, data.id, taskId)
                        }
                        style={{ width: "100%", padding: "0.75em" }}
                        name="select ingestion Mode"
                      >
                        <option value="">Select</option>
                        {schemas
                          .filter((schema) => {
                            return schema.id === data.id;
                          })?.[0]
                          .schema_names?.[0].map((schema_object, index) => (
                            <option
                              key={index}
                              value={schema_object.schema_name}
                            >
                              {schema_object.schema_name}
                            </option>
                          ))}
                      </select>
                    ) : (
                      <ReactLoading
                        type={"spin"}
                        color={"#222"}
                        height={20}
                        width={30}
                      />
                    )}
                  </td>
                  <td
                    style={{
                      width: "200px",
                    }}
                  >
                    {data.listTablesLoader ? (
                      <select
                        defaultValue={""}
                        onChange={(event) =>
                          handleTableNameChange(event, data.id, taskId)
                        }
                        style={{ width: "100%", padding: "0.75em" }}
                        name="select ingestion Mode"
                      >
                        <option value="">Select</option>
                        {tables
                          .filter((table) => {
                            return table.id === data.id;
                          })?.[0]
                          .table_names?.[0].map((table_object, index) => (
                            <option key={index} value={table_object.table_name}>
                              {table_object.table_name}
                            </option>
                          ))}
                      </select>
                    ) : (
                      <ReactLoading
                        type={"spin"}
                        color={"#222"}
                        height={20}
                        width={30}
                      />
                    )}
                  </td>
                  <td
                    style={{
                      width: "200px",
                    }}
                  >
                    <select
                      defaultValue={""}
                      onChange={(event) =>
                        handleTableIngestModeOption(event, data.id, taskId)
                      }
                      style={{ width: "100%", padding: "0.75em" }}
                      name="select ingestion Mode"
                    >
                      {tableOptions.map((item) => (
                        <option key={item.id} value={item.option}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </td>
                  {data.load_type === "delta" && (
                    <td
                      style={{
                        width: "200px",
                      }}
                    >
                      {data.deltaColumnDataLoader ? (
                        <select
                          defaultValue={""}
                          onChange={(event) =>
                            handleDeltaColumnChange(event, data.id, taskId)
                          }
                          style={{ width: "100%", padding: "0.75em" }}
                          name="select ingestion Mode"
                        >
                          <option value="">Select</option>
                          {deltaColumn
                            .filter((column) => {
                              return column.id === data.id;
                            })?.[0]
                            .COLUMN_NAMES?.[0].map((delta_object, index) => (
                              <option
                                key={index}
                                value={delta_object.COLUMN_NAME}
                              >
                                {delta_object.COLUMN_NAME}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <ReactLoading
                          type={"spin"}
                          color={"#222"}
                          height={20}
                          width={30}
                        />
                      )}
                    </td>
                  )}
                  <td
                    style={{
                      width: "200px",
                    }}
                  >
                    <input
                      type="checkbox"
                      defaultChecked={""}
                      onChange={(event) =>
                        handlePartionChange(event, data.id, taskId)
                      }
                    />
                  </td>
                  {data.partitioning && (
                    <td
                      style={{
                        width: "200px",
                      }}
                    >
                      {data.partitionColumnDataLoader ? (
                        <MultiSelect
                          onChange={(event) =>
                            handlePartitioningChange(event, data.id, taskId)
                          }
                          options={partitionColumn
                            .filter((column) => {
                              return column.id === data.id;
                            })?.[0]
                            .COLUMN_NAMES?.[0].map((partition_object) => ({
                              value: partition_object.COLUMN_NAME,
                              label: partition_object.COLUMN_NAME,
                            }))}
                        />
                      ) : (
                        <ReactLoading
                          type={"spin"}
                          color={"#222"}
                          height={20}
                          width={30}
                        />
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <button onClick={addRow} className={"next_btn save_btn"}>
              Add more Tables
            </button>
          </div>
        </div>
      )}
    </StyledDashboardContentFlex>
  );
};

export default RenderTable;
