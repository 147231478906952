import React, { useState } from "react";
import { StyledPortalContainer, SideNavigation, PortalHeader } from "../index";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const DashBoardScreen = ({ children }) => {
  const [isActive, setIsActive] = useState(true);

  const handleCollapseMenu = () => {
    setIsActive(!isActive);
  };
  return (
    <StyledPortalContainer>
      <PortalHeader />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={isActive ? 2 : 0.5}>
            <SideNavigation
              isActive={isActive}
              handleCollapseMenu={handleCollapseMenu}
            />
          </Grid>
          <Grid item xs={6} md={isActive ? 10 : 11.5}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </StyledPortalContainer>
  );
};

export default DashBoardScreen;
