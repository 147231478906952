import React, { useState } from "react";
import {
  StyledDashboardContentFlex,
  Flex,
  BsFillEyeFill,
  BsFillEyeSlashFill,
  StyledConnectorInput,
  OptionalText,
} from "../index";
const RenderDataItems = ({ data, handleChange, errors }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const styles = {
    container: {
      display: "flex",
      width: "100%",
      padding: "1em",
      border: "1px solid rgb(0, 0, 0)",
      color: "rgb(66, 71, 78)",
      font: "400 16px / 24px Poppins, sans-serif",
      transition: "all 0.5s ease 0s",
      outline: "none",
      opacity: "0.6",
      borderRadius: "4px",
      "&:focus": {
        border: "2.5px solid #006399",
        color: "#006399",
      },
    },
    input: {
      border: "none",
      outline: "none",
      width: "100%",
      color: "#42474E",
      font: "normal 400 16px/24px 'Poppins',sans-serif",
      WebkitTransition: "0.5s",
      "&:focus": {
        border: "2.5px solid #006399",
        color: "#006399",
      },
    },
  };
  return (
    <StyledDashboardContentFlex column>
      {data.map((item) => (
        <Flex key={item.id} column>
          {item.password ? (
            <div style={styles.container}>
              <input
                style={styles.input}
                type={isPasswordVisible ? "text" : "password"}
                name={item.name}
                value={item.value}
                onChange={handleChange}
                placeholder={item.title}
              />
              <div onClick={handleTogglePasswordVisibility}>
                {isPasswordVisible ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
              </div>
            </div>
          ) : item.checkbox ? (
            <Flex>
              <p>{item.title}</p>
              <input
                type="checkbox"
                name={item.name}
                value={item.value}
                checked={item.value}
                onChange={handleChange}
                className="checkBox"
              />
            </Flex>
          ) : (
            <StyledConnectorInput
              type="text"
              name={item.name}
              value={item.value}
              onChange={handleChange}
              placeholder={item.title}
            />
          )}
          {item.required ? "" : <OptionalText>Optional</OptionalText>}
        </Flex>
      ))}
      <p className="error">{errors}</p>
    </StyledDashboardContentFlex>
  );
};

export default RenderDataItems;
