import styled from "styled-components";

export const StyledFilteredBoxContainer = styled.div`
  margin-top: 0.25em;
  display: flex;
  max-width: fit-content;
  height: 40px;
  padding: 0.25em 0.75em;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  .text {
    color: ${({ theme }) => theme.colors.sysSurfaceVariant};
    text-align: center;
    font: ${({ theme }) => theme.fonts.fontPrimary};
    letter-spacing: ${({ theme }) => theme.fonts.primaryLetterSpacing};
    white-space: nowrap;
  }
`;
