import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const Box = styled.div`
  flex: 1;
  width: 300px;
  height: 300px;
  padding: 10px;
  border: 1px dashed #ccc;
  border-radius: 5px;
  margin-right: 10px;
  overflow-y: scroll;
  overflow-x: hidden;

  &:last-child {
    margin-right: 0;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ListItem = styled.li`
  cursor: pointer;
  margin-bottom: 3px;
`;
